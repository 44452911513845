import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import AppRouter from './AppRouter';
import AuthProvider from './AuthProvider';
import 'antd/dist/antd.css';
import './assets/styles.css';
import ErrorBoundary from './ErrorBoundary';
import moment from 'moment';
// import HistoryLog from 'containers/HistoryLog/index';

moment.suppressDeprecationWarnings = true;

const App = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const { pathname } = window.location;
  // const isFromMobileApp = pathname.includes('/appreports') || pathname.includes('/app-rep');

  return (
    <Provider store={props.store}>
      <AuthProvider>
        <ErrorBoundary>
          <AppRouter />
          {/* {!isFromMobileApp && <HistoryLog />} */}
        </ErrorBoundary>
      </AuthProvider>
    </Provider>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
