import { call, takeLatest } from 'redux-saga/effects';
import { fetchCoverLetter, addCoverLetter, updateCoverLetter } from 'actions/cover-letters';
import {
  fetchCoverLetterApi,
  addCoverLetterApi,
  updateCoverLetterApi,
} from 'services/cover-letters';
import fetchEntity from './fetch-entity';

const fetchCoverLetterData = fetchEntity.bind(null, fetchCoverLetter.actions, fetchCoverLetterApi);
const fetchAddCoverLetter = fetchEntity.bind(null, addCoverLetter.actions, addCoverLetterApi);
const fetchUpdateCoverLetter = fetchEntity.bind(
  null,
  updateCoverLetter.actions,
  updateCoverLetterApi
);

export function* loadFetchCoverLetter({ params }) {
  yield call(fetchCoverLetterData, { ...params });
}
export function* loadAddCoverLetter({ params }) {
  yield call(fetchAddCoverLetter, params);
}
export function* loadUpdateCoverLetter({ params }) {
  yield call(fetchUpdateCoverLetter, { ...params });
}
function* watchFetchCoverLetter() {
  yield takeLatest(fetchCoverLetter.actionName, loadFetchCoverLetter);
}
function* watchAddCoverLetter() {
  yield takeLatest(addCoverLetter.actionName, loadAddCoverLetter);
}
function* watchUpdateCoverLetter() {
  yield takeLatest(updateCoverLetter.actionName, loadUpdateCoverLetter);
}

export default {
  watchFetchCoverLetter,
  watchAddCoverLetter,
  watchUpdateCoverLetter,
};
