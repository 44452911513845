import {
  fetchProfiles,
  fetchOneProfile,
  addProfile,
  updateProfile,
  deleteProfile,
} from 'actions/profiles';

const profiles = () => {
  const initialState = {
    profiles: null,
    selectedProfile: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchProfiles.requestTypes.SUCCESS:
        return {
          selectedProfile: null,
          profiles: response,
        };
      case addProfile.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addProfile.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addProfile.requestTypes.SUCCESS:
        return {
          ...state,
          selectedProfile: response,
          isSuccessful: true,
        };
      case updateProfile.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateProfile.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateProfile.requestTypes.SUCCESS:
        return {
          ...state,
          selectedProfile: response,
          isSuccessful: true,
        };
      case fetchOneProfile.requestTypes.SUCCESS:
        return {
          ...state,
          selectedProfile: response,
        };
      case deleteProfile.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default profiles();
