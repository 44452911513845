import React from 'react';
import { DataSection } from 'components/Partials';
import { Button, FormItem, Title, RichTextEditor } from 'components/index';
import { Col, Form, Row, Spin, Select } from 'antd';

const SummaryForm = ({ handleSubmit, form, loading, user = null, actionType = null }) => {
  const { getFieldDecorator } = form;
  const { Option } = Select;
  const dataMargin = actionType && { top: 0, bottom: 0 };
  return (
    <Spin spinning={!!loading}>
      <Form layout={'vertical'} labelAlign={'left'} hideRequiredMark onSubmit={handleSubmit}>
        <Title level={3}>PDF Summary </Title>
        <DataSection {...dataMargin}>
          <Row gutter={25}>
            <Col span={12}>
              <FormItem label='Header text'>
                {getFieldDecorator('summaryPageHeader', {
                  initialValue: user?.profile?.summaryPageHeader || 'summary Page Header',
                })(<RichTextEditor />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label='Footer text'>
                {getFieldDecorator('summaryPageFooter', {
                  initialValue: user?.profile?.summaryPageFooter || 'summary Page Footer',
                })(<RichTextEditor />)}
              </FormItem>
            </Col>
          </Row>
        </DataSection>

        <DataSection {...dataMargin}>
          <Title level={3}>Condition(s) for PDF </Title>

          <FormItem>
            {getFieldDecorator('pdfConditions', {
              initialValue: user?.profile?.pdfConditions,
            })(
              <Select
                mode='multiple'
                placeholder='Condition(s) for PDF'
                allowClear={true}
                style={{ width: '100%' }}
              >
                <Option value='Minor repair'>Minor repair</Option>
                <Option value='Major repair'>Major repair</Option>
                <Option value='Maintenance '>Maintenance</Option>
                <Option value='Upgrade '>Upgrade</Option>
              </Select>
            )}
          </FormItem>
        </DataSection>
        <Row>
          <Col span={12}>
            <FormItem>
              <Button block loading={loading} type={'primary'} size={'large'} htmlType={'submit'}>
                Update
              </Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default SummaryForm;
