import styled, { css } from 'styled-components';
import { Card as card } from 'antd';

const Card = styled(card)`
    border-radius: 5px;
    .ant-card-head
    {
        border-bottom: 0;
        min-height: auto;
    }
    .ant-card-head-title
    {
        color: #000000;	
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 0;
    }
    .ant-card-body
    {
        padding-top: 0;
        .actions{
            text-align: right;
            margin-top: 8px;
            width: 100%;
        }
    }
    .ant-card-body em
    {
        font-size: 12px;
    }
    .ant-card-body .ant-typography
    {
    }
    .reports
    {
        position: relative;
        width: 100%;
        margin: 50px 0 0 0;
        font-size: 12px;
    }
    .reports button
    {
        position: absolute;
        top: -3px;
        right: 0;
        color: #AAAAAA;
        font-size: 18px;
    }
    ${props =>
      props.height &&
      css`
        height: ${props.height};
      `}
}
`;

/** @component */
export default Card;
