import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchConditions,
  fetchOneCondition,
  addCondition,
  updateCondition,
  deleteCondition,
} from 'actions/conditions';
import {
  fetchAllConditionsApi,
  fetchOneConditionApi,
  addConditionApi,
  updateConditionApi,
  deleteConditionApi,
} from 'services/conditions';
import fetchEntity from './fetch-entity';

const fetchConditionsData = fetchEntity.bind(null, fetchConditions.actions, fetchAllConditionsApi);

const fetchConditionData = fetchEntity.bind(null, fetchOneCondition.actions, fetchOneConditionApi);

const fetchAddCondition = fetchEntity.bind(null, addCondition.actions, addConditionApi);

const fetchUpdateCondition = fetchEntity.bind(null, updateCondition.actions, updateConditionApi);

const fetchDeleteCondition = fetchEntity.bind(null, deleteCondition.actions, deleteConditionApi);

export function* loadFetchConditions({ params }) {
  yield call(fetchConditionsData, { ...params });
}

export function* loadGetCondition({ params }) {
  yield put(fetchOneCondition.actions.failure({}, undefined));
  yield call(fetchConditionData, params);
}

export function* loadAddCondition({ params }) {
  yield call(fetchAddCondition, params);
}

export function* loadUpdateCondition({ params }) {
  yield call(fetchUpdateCondition, params);
}

export function* loadDeleteCondition({ params }) {
  yield call(fetchDeleteCondition, { ...params });
}

function* watchFetchConditions() {
  yield takeLatest(fetchConditions.actionName, loadFetchConditions);
}

function* watchAddCondition() {
  yield takeLatest(addCondition.actionName, loadAddCondition);
}

function* watchUpdateCondition() {
  yield takeLatest(updateCondition.actionName, loadUpdateCondition);
}

function* watchDeleteCondition() {
  yield takeLatest(deleteCondition.actionName, loadDeleteCondition);
}

function* watchGetCondition() {
  yield takeLatest(fetchOneCondition.actionName, loadGetCondition);
}

export function* loadConditionsOnChange() {
  const conditions = yield select(state => state.conditions);
  const { total, skip, limit } = conditions;
  if (skip && skip >= total) {
    yield call(fetchConditionsData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchConditionsData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchConditionsOnChange() {
  yield takeLatest(
    [
      deleteCondition.requestTypes.SUCCESS,
      addCondition.requestTypes.SUCCESS,
      updateCondition.requestTypes.SUCCESS,
    ],
    loadConditionsOnChange
  );
}

export default {
  watchFetchConditions,
  watchAddCondition,
  watchGetCondition,
  watchUpdateCondition,
  watchConditionsOnChange,
  watchDeleteCondition,
};
