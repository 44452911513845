import styled from 'styled-components';

const SearchFilterWrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  .buttons-wrapper {
    padding-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export default SearchFilterWrapper;
