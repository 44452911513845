import { makeRequestAction } from './index';

/**
 * Fetch inspection types
 */
export const fetchInspectionTypes = makeRequestAction('FETCH_INSPECTION_TYPES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addInspectionType = makeRequestAction('ADD_INSPECTION_TYPE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateInspectionType = makeRequestAction('UPDATE_INSPECTION_TYPE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneInspectionType = makeRequestAction('GET_INSPECTION_TYPE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteInspectionType = makeRequestAction('DELETE_INSPECTION_TYPE', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
