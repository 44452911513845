import styled from 'styled-components';

const ReportsFormWrapper = styled('div')`
  .clientsPhoneNumber {
    margin: 0;
    color: rgba(0, 0, 0, 0.35);
  }
  .cover-resent-row-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .phoneNumberText {
    color: rgba(0, 0, 0, 0.5);
    font-weight: normal;
  }
  .phoneNumberText:hover {
    color: rgba(0, 0, 0, 0.35);
  }
  .noClientsPhoneNumber {
    margin-bottom: 12px;
  }
  .number-inputs .has-error {
    @media (min-width: 576px) {
      height: 72px;
    }
    @media (min-width: 992px) {
      height: 112px;
    }
    @media (min-width: 1160px) {
      height: 80px;
    }
  }
`;

export default ReportsFormWrapper;
