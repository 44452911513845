import styled, { css } from 'styled-components';
import { Typography } from 'antd';

const { Title: title } = Typography;
const Title = styled(title)`
  margin: 0 !important;
  ${props =>
    props.level === 1 &&
    css`
      font-size: 24px !important;
      line-height: 35px !important;
    `}
  ${props =>
    props.level === 2 &&
    css`
      font-size: 18px !important;
      line-height: 16px !important;
    `}
  ${props =>
    props.level === 3 &&
    css`
      font-size: 16px !important;
      line-height: 24px !important;
    `}
    ${props =>
      props.level === 4 &&
      css`
        font-size: 12px !important;
        line-height: 18px !important;
      `}
  ${props =>
    props.mb &&
    css`
      margin-bottom: ${props.mb}px !important;
    `}
    ${props =>
      props.padding &&
      css`
        padding: ${props.padding} !important;
      `}
    ${props =>
      props.mt &&
      css`
        margin-top: ${props.mt}px !important;
      `}
    ${props =>
      props.color &&
      css`
        color: ${props.color} !important;
      `}
      font-weight: bold !important;
`;

/** @component */
export default Title;
