import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchBrokers,
  fetchOneBroker,
  addBroker,
  updateBroker,
  deleteBroker,
} from 'actions/brokers';
import {
  fetchAllBrokersApi,
  fetchOneBrokerApi,
  addBrokerApi,
  updateBrokerApi,
  deleteBrokerApi,
} from 'services/brokers';
import fetchEntity from './fetch-entity';

const fetchBrokersData = fetchEntity.bind(null, fetchBrokers.actions, fetchAllBrokersApi);

const fetchBrokerData = fetchEntity.bind(null, fetchOneBroker.actions, fetchOneBrokerApi);

const fetchAddBroker = fetchEntity.bind(null, addBroker.actions, addBrokerApi);

const fetchUpdateBroker = fetchEntity.bind(null, updateBroker.actions, updateBrokerApi);

const fetchDeleteBroker = fetchEntity.bind(null, deleteBroker.actions, deleteBrokerApi);

export function* loadFetchBrokers({ params }) {
  yield call(fetchBrokersData, { ...params });
}

export function* loadGetBroker({ params }) {
  yield put(fetchOneBroker.actions.failure({}, undefined));
  yield call(fetchBrokerData, params);
}

export function* loadAddBroker({ params }) {
  yield call(fetchAddBroker, params);
}

export function* loadUpdateBroker({ params }) {
  yield call(fetchUpdateBroker, params);
}

export function* loadDeleteBroker({ params }) {
  yield call(fetchDeleteBroker, { ...params });
}

function* watchFetchBrokers() {
  yield takeLatest(fetchBrokers.actionName, loadFetchBrokers);
}

function* watchAddBroker() {
  yield takeLatest(addBroker.actionName, loadAddBroker);
}

function* watchUpdateBroker() {
  yield takeLatest(updateBroker.actionName, loadUpdateBroker);
}

function* watchDeleteBroker() {
  yield takeLatest(deleteBroker.actionName, loadDeleteBroker);
}

function* watchGetBroker() {
  yield takeLatest(fetchOneBroker.actionName, loadGetBroker);
}

export function* loadBrokersOnChange() {
  const brokers = yield select(state => state.brokers);
  const { total, skip, limit } = brokers;
  if (skip && skip >= total) {
    yield call(fetchBrokersData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchBrokersData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchBrokersOnChange() {
  yield takeLatest(
    [deleteBroker.requestTypes.SUCCESS, addBroker.requestTypes.SUCCESS],
    loadBrokersOnChange
  );
}

export default {
  watchFetchBrokers,
  watchAddBroker,
  watchGetBroker,
  watchUpdateBroker,
  watchBrokersOnChange,
  watchDeleteBroker,
};
