import {
  fetchBuildingTypesToUsers,
  fetchOneBuildingTypesToUser,
  addBuildingTypesToUser,
  updateBuildingTypesToUser,
} from 'actions/building-types-to-users';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getBuildingTypesToUsers = state => state.buildingTypesToUsers;
export const getBuildingTypesToUsersState = createSelector(getBuildingTypesToUsers, data => {
  if (!data.buildingTypesToUsers) {
    return [];
  }
  if (data.buildingTypesToUsers.data) {
    return data.buildingTypesToUsers;
  }
  const buildingTypesToUsersData = Object.entries(data.buildingTypesToUsers).map(b => b[1]);
  return { total: buildingTypesToUsersData.length, data: buildingTypesToUsersData };
});

export const getSuccessState = createSelector(getBuildingTypesToUsers, data => data.isSuccessful);
export const getSelectedBuildingTypesToUser = createSelector(
  getBuildingTypesToUsers,
  data => data.selectedBuildingTypesToUser
);

export const buildingTypesToUsersValuesLoading = createLoadingSelector(
  fetchBuildingTypesToUsers.actionName
)();
export const buildingTypesToUsersValuesError = createErrorSelector(
  fetchBuildingTypesToUsers.actionName
)();

export const getAddBuildingTypesToUserLoading = createLoadingSelector(
  addBuildingTypesToUser.actionName
)();
export const getAddBuildingTypesToUserError = createErrorSelector(
  addBuildingTypesToUser.actionName
)();

export const getUpdateBuildingTypesToUserLoading = createLoadingSelector(
  updateBuildingTypesToUser.actionName
)();
export const getUpdateBuildingTypesToUserError = createErrorSelector(
  updateBuildingTypesToUser.actionName
)();

export const getSelectedBuildingTypesToUserLoading = createLoadingSelector(
  fetchOneBuildingTypesToUser.actionName
)();
export const getSelectedBuildingTypesToUserError = createErrorSelector(
  fetchOneBuildingTypesToUser.actionName
)();
