import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { userIsAuth, getUserRole, getIsPayed } from 'selectors/users';
import { routePermissions, roles } from 'constants/users';
import { protectedRouts } from 'constants/routes';

const locationHelper = locationHelperBuilder({});

export const isAuth = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/sign-in',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: userIsAuth,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const isNotAuth = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one,
  // or to the landing page if none is specified and the user is already logged in
  redirectPath: (state, ownProps) => {
    const currentRole = getUserRole(state);
    if (currentRole === roles.ROLE_INSPECTOR || currentRole === roles.ROLE_SINGLE_INSPECTOR) {
      return protectedRouts.calendar.pathname;
    }
    if (currentRole === roles.ROLE_SUPER_ADMIN) {
      return protectedRouts.dashboard.pathname;
    }

    return locationHelper.getRedirectQueryParam(ownProps) || '/';
  },
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // So if there is no user data, then we show the page
  authenticatedSelector: state => !userIsAuth(state),
  // A nice display name for this check
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

export const isPermitted = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one,
  // or to the landing page if none is specified and the user is already logged in
  redirectPath: '/not-permitted',
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // Check if user has permission for current route
  authenticatedSelector: (state, { match }) => {
    const pathName = match.path;
    const currentRole = getUserRole(state);
    const permissions = routePermissions[currentRole];
    const currentUri = window.location.pathname;

    if (currentUri === '/' && !permissions.some(paths => paths === currentUri)) {
      return false;
    }
    return permissions.some(paths => paths === pathName);
  },
  // A nice display name for this check
  wrapperDisplayName: 'routeIsNotPermitted',
});

export const isPayed = connectedRouterRedirect({
  redirectPath: '/checkout',
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // Check if user has permission for current route
  authenticatedSelector: state => getIsPayed(state),
  // A nice display name for this check
  wrapperDisplayName: 'routeIsNotPermitted',
});
