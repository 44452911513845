import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormItem, Input, Button, Select, TextArea, GoogleMapAutocomplete } from 'components';
import { DataSection } from 'components/Partials';
import { Col, Form, Row, Icon } from 'antd';
import { getBrokersState } from 'selectors/brokers';
import { fetchBrokers } from 'actions/brokers';
import { getShowBrokers } from 'selectors/booking-form';
import { addBrokerInfo, showBrokersList, showBrokersForm } from 'actions/booking-form';
import postalCodeFormatter from 'utils/postalCodeFormatter';
import getPostalCode from 'utils/getPostalCode';
import formItemConfigs from './formItemConfigs';

const { Option } = Select;

const {
  nameInput,
  emailInput,
  addressInput,
  countryInput,
  cityInput,
  provinceInput,
  postalInput,
  notesInput,
  searchBrokerInput,
  phoneInput,
  companyName,
} = formItemConfigs;

const BrokersForm = ({
  form,
  handleSubmit,
  broker,
  brokerList,
  setDisabled,
  isBrokersPage = false,
  modalVisible,
}) => {
  const dispatch = useDispatch();
  const showBrokers = useSelector(getShowBrokers);
  const { getFieldDecorator } = form;
  const brokers = useSelector(getBrokersState);

  const [email, setEmail] = useState('');
  const brokersEmail = brokers?.data;

  const crmEmails = brokersEmail?.find(el => el.email === email);

  useEffect(() => {
    if (crmEmails) {
      crmEmails ? setDisabled(true) : setDisabled(false);
    } else {
      crmEmails ? setDisabled(true) : setDisabled(false);
    }
  }, [crmEmails, setDisabled]);

  useEffect(() => {
    dispatch(fetchBrokers.request({ getAll: true, '$sort[name]': 1 }));
  }, [dispatch, showBrokers]);

  const handleOnChange = brokerId => {
    const selectedBroker = brokers.data.find(item => item.id === brokerId);
    dispatch(addBrokerInfo.request(selectedBroker));
  };

  const onPlaceSelected = async details => {
    const { description, terms } = details;
    if (details && details.place_id && terms) {
      try {
        const postcode = await getPostalCode(details.place_id);
        form.setFieldsValue({ [postalInput.fieldName]: postcode || '' });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      form.setFieldsValue({ [countryInput.fieldName]: terms[terms.length - 1].value });
      form.setFieldsValue({
        [provinceInput.fieldName]: terms[terms.length - 2] ? terms[terms.length - 2].value : '',
      });
      form.setFieldsValue({
        [cityInput.fieldName]: terms[terms.length - 3] ? terms[terms.length - 3].value : '',
      });
    }
    form.setFieldsValue({ [addressInput.fieldName]: description });
  };

  return (
    <>
      {!isBrokersPage && brokerList && (
        <DataSection className='add-broker' bottom={0} top={0} left={0} right={0} clearFix>
          <DataSection inline pullRight textRight bottom={0} top={0} left={0} right={0}>
            {showBrokers ? (
              <Button ghost type={'success'} onClick={() => dispatch(showBrokersForm.request())}>
                <Icon type='plus-circle' /> Create New Broker
              </Button>
            ) : (
              <Button ghost type={'success'} onClick={() => dispatch(showBrokersList.request())}>
                <Icon type='plus-circle' /> From Broker CRM
              </Button>
            )}
          </DataSection>
        </DataSection>
      )}
      {!isBrokersPage && showBrokers && brokers.data && (
        <DataSection>
          <Form
            id='brokersForm'
            layout={'vertical'}
            labelAlign={'left'}
            hideRequiredMark
            onSubmit={handleSubmit}
          >
            <FormItem label={searchBrokerInput.label}>
              {getFieldDecorator(searchBrokerInput.fieldName, {
                ...searchBrokerInput.decoratorOptions,
                initialValue: broker ? broker.id : undefined,
              })(
                <Select
                  placeholder='Search broker by name or email'
                  size={'large'}
                  style={{ width: '100%' }}
                  onChange={value => {
                    handleOnChange(value);
                  }}
                  showArrow={false}
                  showSearch
                  allowClear={!!broker}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {brokers.data.map(c => (
                    <Option value={c.id} key={c.createdAt} title={`${c.name} - ${c.email}`}>
                      {`${c.name} - ${c.email}`}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Form>
        </DataSection>
      )}
      {(isBrokersPage || !showBrokers) && (
        <Form
          id='brokersForm'
          layout={'vertical'}
          labelAlign={'left'}
          hideRequiredMark
          onSubmit={handleSubmit}
        >
          <FormItem label={nameInput.label}>
            {getFieldDecorator(nameInput.fieldName, {
              ...nameInput.decoratorOptions,
              initialValue: broker ? broker.name : null,
            })(<Input size={'large'} autocomplete='new-password' />)}
          </FormItem>

          <FormItem label={emailInput.label}>
            {getFieldDecorator(emailInput.fieldName, {
              ...emailInput.decoratorOptions,
              initialValue: broker ? broker.email : null,
            })(
              <Input
                size={'large'}
                autocomplete='new-password'
                onChange={e => {
                  setEmail(e.target.value);
                }}
              />
            )}
          </FormItem>
          {crmEmails && (
            <span
              style={{
                fontSize: '14px',
                color: '#f5222d',
                position: 'relative',
                top: modalVisible ? '-35px' : '-15px',
              }}
            >
              Broker with that email already exist in CRM
            </span>
          )}

          <FormItem label={companyName.label}>
            {getFieldDecorator(companyName.fieldName, {
              ...companyName.decoratorOptions,
              initialValue: broker ? broker.companyName : null,
            })(<Input size={'large'} autocomplete='new-password' />)}
          </FormItem>

          <FormItem label={addressInput.label}>
            {getFieldDecorator(addressInput.fieldName, {
              ...addressInput.decoratorOptions,
              initialValue: broker ? broker.address : null,
            })(
              <GoogleMapAutocomplete
                address={broker && broker.address}
                onPlaceSelected={onPlaceSelected}
              />
            )}
          </FormItem>

          <Row gutter={25}>
            <Col span={12}>
              <FormItem label={cityInput.label}>
                {getFieldDecorator(cityInput.fieldName, {
                  ...cityInput.decoratorOptions,
                  initialValue: broker ? broker.city : null,
                })(<Input size={'large'} autocomplete='new-password' />)}
              </FormItem>
            </Col>{' '}
            <Col span={12}>
              <FormItem label={provinceInput.label}>
                {getFieldDecorator(provinceInput.fieldName, {
                  ...provinceInput.decoratorOptions,
                  initialValue: broker ? broker.province : null,
                })(<Input size={'large'} autocomplete='new-password' />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={25}>
            <Col span={12}>
              <FormItem label={postalInput.label}>
                {getFieldDecorator(postalInput.fieldName, {
                  ...postalInput.decoratorOptions,
                  normalize: postalCodeFormatter,
                  initialValue: broker ? broker.postal : null,
                })(
                  <Input size={'large'} autocomplete='new-password' {...postalInput.elementProps} />
                )}
              </FormItem>
            </Col>{' '}
            <Col span={12}>
              <FormItem label={countryInput.label}>
                {getFieldDecorator(countryInput.fieldName, {
                  ...countryInput.decoratorOptions,
                  initialValue: broker ? broker.country : null,
                })(<Input size={'large'} autocomplete='new-password' />)}
              </FormItem>
            </Col>
          </Row>
          <FormItem label={notesInput.label}>
            {getFieldDecorator(notesInput.fieldName, {
              ...notesInput.decoratorOptions,
              initialValue: broker ? broker.notes : null,
            })(<TextArea size={'large'} />)}
          </FormItem>
          <FormItem label={phoneInput.label}>
            {getFieldDecorator(phoneInput.fieldName, {
              ...phoneInput.decoratorOptions,
              initialValue: broker ? broker.phone : null,
            })(<Input size={'large'} autocomplete='new-password' {...phoneInput.elementProps} />)}
          </FormItem>
        </Form>
      )}
    </>
  );
};

export default React.memo(BrokersForm);
