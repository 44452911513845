import { call, takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import {
  fetchContacts,
  fetchOneContact,
  addContact,
  updateContact,
  deleteContact,
} from 'actions/contacts';
import {
  fetchAllContactsApi,
  fetchOneContactApi,
  addContactApi,
  updateContactApi,
  deleteContactApi,
} from 'services/contacts';
import fetchEntity from './fetch-entity';

const fetchContactsData = fetchEntity.bind(null, fetchContacts.actions, fetchAllContactsApi);

const fetchContactData = fetchEntity.bind(null, fetchOneContact.actions, fetchOneContactApi);

const fetchAddContact = fetchEntity.bind(null, addContact.actions, addContactApi);

const fetchUpdateContact = fetchEntity.bind(null, updateContact.actions, updateContactApi);

const fetchDeleteContact = fetchEntity.bind(null, deleteContact.actions, deleteContactApi);

export function* loadFetchContacts({ params }) {
  yield call(fetchContactsData, { ...params });
}

export function* loadGetContact({ params }) {
  yield put(fetchOneContact.actions.failure({}, undefined));
  yield call(fetchContactData, params);
}

export function* loadAddContact({ params }) {
  yield call(fetchAddContact, params);
}

export function* loadUpdateContact({ params }) {
  yield call(fetchUpdateContact, params);
}

export function* loadDeleteContact({ params }) {
  yield call(fetchDeleteContact, { ...params });
}

function* watchFetchContacts() {
  yield takeLatest(fetchContacts.actionName, loadFetchContacts);
}

function* watchAddContact() {
  yield takeEvery(addContact.actionName, loadAddContact);
}

function* watchUpdateContact() {
  yield takeLatest(updateContact.actionName, loadUpdateContact);
}

function* watchDeleteContact() {
  yield takeLatest(deleteContact.actionName, loadDeleteContact);
}

function* watchGetContact() {
  yield takeLatest(fetchOneContact.actionName, loadGetContact);
}

export function* loadContactsOnChange() {
  const contacts = yield select(state => state.contacts);
  const { total, skip, limit } = contacts;
  if (skip && skip >= total) {
    yield call(fetchContactsData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchContactsData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchContactsOnChange() {
  yield takeLatest(
    [deleteContact.requestTypes.SUCCESS, addContact.requestTypes.SUCCESS],
    loadContactsOnChange
  );
}

export default {
  watchFetchContacts,
  watchAddContact,
  watchGetContact,
  watchUpdateContact,
  watchContactsOnChange,
  watchDeleteContact,
};
