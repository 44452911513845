import {
  fetchContractsToReports,
  fetchOneContractToReport,
  addContractToReport,
  updateContractToReport,
  deleteContractToReport,
} from 'actions/contracts-to-reports';

const contractsToReports = () => {
  const initialState = {
    contractsToReports: null,
    selectedContractToReport: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchContractsToReports.requestTypes.SUCCESS:
        return {
          selectedContractToReport: null,
          contractsToReports: response,
        };
      case addContractToReport.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addContractToReport.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addContractToReport.requestTypes.SUCCESS:
        return {
          ...state,
          selectedContractToReport: response,
          isSuccessful: true,
        };
      case updateContractToReport.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateContractToReport.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateContractToReport.requestTypes.SUCCESS:
        return {
          ...state,
          selectedContractToReport: response,
          isSuccessful: true,
        };
      case fetchOneContractToReport.requestTypes.SUCCESS:
        return {
          ...state,
          selectedContractToReport: response,
        };
      case deleteContractToReport.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default contractsToReports();
