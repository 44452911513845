import {
  fetchInspetionTypesToUsers,
  fetchOneInspetionTypesToUser,
  addInspetionTypesToUser,
  updateInspetionTypesToUser,
} from 'actions/inspetion-types-to-users';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getInspetionTypesToUsers = state => state.inspetionTypesToUsers;
export const getInspetionTypesToUsersState = createSelector(getInspetionTypesToUsers, data => {
  if (!data.inspetionTypesToUsers) {
    return [];
  }
  if (data.inspetionTypesToUsers.data) {
    return data.inspetionTypesToUsers;
  }
  const inspetionTypesToUsersData = Object.entries(data.inspetionTypesToUsers).map(i => i[1]);
  return { total: inspetionTypesToUsersData.length, data: inspetionTypesToUsersData };
});

export const getSuccessState = createSelector(getInspetionTypesToUsers, data => data.isSuccessful);
export const getSelectedInspetionTypesToUser = createSelector(
  getInspetionTypesToUsers,
  data => data.selectedInspetionTypesToUser
);

export const inspetionTypesToUsersValuesLoading = createLoadingSelector(
  fetchInspetionTypesToUsers.actionName
)();
export const inspetionTypesToUsersValuesError = createErrorSelector(
  fetchInspetionTypesToUsers.actionName
)();

export const getAddInspetionTypesToUserLoading = createLoadingSelector(
  addInspetionTypesToUser.actionName
)();
export const getAddInspetionTypesToUserError = createErrorSelector(
  addInspetionTypesToUser.actionName
)();

export const getUpdateInspetionTypesToUserLoading = createLoadingSelector(
  updateInspetionTypesToUser.actionName
)();
export const getUpdateInspetionTypesToUserError = createErrorSelector(
  updateInspetionTypesToUser.actionName
)();

export const getSelectedInspetionTypesToUserLoading = createLoadingSelector(
  fetchOneInspetionTypesToUser.actionName
)();
export const getSelectedInspetionTypesToUserError = createErrorSelector(
  fetchOneInspetionTypesToUser.actionName
)();
