import {
  fetchLimitationsUsers,
  fetchOneLimitationsUser,
  addLimitationsUser,
  updateLimitationsUser,
  deleteLimitationsUser,
} from 'actions/limitations-users';

const limitationsUsers = () => {
  const initialState = {
    limitationsUsers: null,
    selectedLimitationsUser: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchLimitationsUsers.requestTypes.SUCCESS:
        return {
          selectedLimitationsUser: null,
          limitationsUsers: response,
        };
      case addLimitationsUser.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addLimitationsUser.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addLimitationsUser.requestTypes.SUCCESS:
        return {
          ...state,
          selectedLimitationsUser: response,
          isSuccessful: true,
        };
      case updateLimitationsUser.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateLimitationsUser.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateLimitationsUser.requestTypes.SUCCESS:
        return {
          ...state,
          selectedLimitationsUser: response,
          isSuccessful: true,
        };
      case fetchOneLimitationsUser.requestTypes.SUCCESS:
        return {
          ...state,
          selectedLimitationsUser: response,
        };
      case deleteLimitationsUser.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default limitationsUsers();
