import { getReport } from 'actions/guest-report';

const guestReport = () => {
  const initialState = {};

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getReport.requestTypes.SUCCESS:
        return {
          ...response,
        };
      default:
        return state;
    }
  };
};

export default guestReport();
