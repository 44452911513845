import React, { useState, useEffect } from 'react';
import { isRoutPermitted } from 'configs/isUserPermitted';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu, Icon } from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';
import { protectedRouts } from 'constants/routes';
import { getIsSidebarClosed } from 'selectors/reports';
import { Sider, Logo } from '.';

const { pathname } = window.location;

const isFromMobileApp = pathname.includes('/appreports') || pathname.includes('/app-rep');

const Sidebar = ({ history, location, userData }) => {
  const TRAININGS = 'https://www.youtube.com/channel/UCTAB4gSeZBW00VVvQGo0jig';
  const { SubMenu } = Menu;
  const isSidebarClosed = useSelector(getIsSidebarClosed);

  const [isIcon, setIsIcon] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  const editReportLocation = location.state;

  useEffect(() => {
    if (editReportLocation === 'Edit report') {
      setCollapsed(!isSidebarClosed);
      setIsIcon(false);
    }
    return () => {
      setCollapsed(isSidebarClosed);
      setIsIcon(!isSidebarClosed);
    };
  }, [editReportLocation, isSidebarClosed]);

  useEffect(() => {
    setCollapsed(isSidebarClosed);
    setIsIcon(!isSidebarClosed);
  }, [isSidebarClosed]);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
    setIsIcon(!isIcon);
  };

  const changeRoute = item => {
    history.push({
      pathname: item.pathname,
      state: item.name,
    });
  };

  const getKeys = () => {
    return [location.pathname];
  };

  const topMenu = menuitem => (
    <Menu.Item key={menuitem.key} onClick={() => changeRoute(menuitem)}>
      <Icon type={menuitem.icon} />
      <span>{menuitem.name}</span>
    </Menu.Item>
  );

  const subMenu = menuitem =>
    menuitem
      .filter(item => isRoutPermitted(item.pathname) && item.showInSideBar)
      .map(item => (
        <Menu.Item key={item.key} onClick={() => changeRoute(item)}>
          <span>{item.name}</span>
        </Menu.Item>
      ));
  if (isFromMobileApp) return <div />;

  return (
    <Sider width={240} collapsible collapsed={collapsed} trigger={null}>
      <Logo margin={'10px 15px 13px 15px'} isIcon={isIcon} />
      <Menu
        theme='light'
        selectedKeys={getKeys('select')}
        defaultOpenKeys={getKeys('open')}
        mode='inline'
      >
        {Object.values(protectedRouts)
          .filter(menuitem => isRoutPermitted(menuitem.pathname))
          .map(menuitem => {
            return menuitem.items &&
              Object.values(menuitem.items).some(item => item.showInSideBar) ? (
              <SubMenu
                key={menuitem.name}
                title={
                  <span>
                    <Icon type='setting' />
                    <span> {menuitem.name}</span>
                  </span>
                }
              >
                {menuitem.items && subMenu(Object.values(menuitem.items))}
              </SubMenu>
            ) : (
              topMenu(menuitem)
            );
          })}
        {userData?.role !== 'super admin' && (
          <Menu.Item key='training'>
            <VideoCameraOutlined onClick={() => window.open(TRAININGS)} />
            <span onClick={() => window.open(TRAININGS)}>Trainings</span>
          </Menu.Item>
        )}
        <Menu.Item key='closemenu' onClick={toggleCollapse}>
          {!collapsed ? <Icon type='left-circle' /> : <Icon type='right-circle' />}
          <span>{!collapsed ? 'Close menu' : 'Open Menu'}</span>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default React.memo(withRouter(Sidebar));
