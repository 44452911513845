import { makeRequestAction } from './index';

/**
 * Fetch bookings
 */
export const fetchBookings = makeRequestAction('FETCH_BOOKINGS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addBooking = makeRequestAction('ADD_BOOKING', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateBooking = makeRequestAction('UPDATE_BOOKING', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneBooking = makeRequestAction('GET_BOOKING', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteBooking = makeRequestAction('DELETE_BOOKING', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
