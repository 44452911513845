import client from './main-client';

export const fetchAllLimitationsUsersApi = params =>
  client().get('/limitations-to-users', { params });
export const fetchOneLimitationsUserApi = ({ id, ...params }) =>
  client().get(`/limitations-to-users/${id}`, { params });
export const updateLimitationsUserApi = params =>
  client().put(`/limitations-to-users/${params.id}`, params.values);
export const addLimitationsUserApi = data => client().post('/limitations-to-users', data);
export const deleteLimitationsUserApi = params =>
  client().delete(`/limitations-to-users/${params.id}`, { params });
