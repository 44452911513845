import {
  fetchBuildingTypesToUsers,
  fetchOneBuildingTypesToUser,
  addBuildingTypesToUser,
  updateBuildingTypesToUser,
  deleteBuildingTypesToUser,
} from 'actions/building-types-to-users';

const buildingTypesToUsers = () => {
  const initialState = {
    buildingTypesToUsers: null,
    selectedBuildingTypesToUser: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchBuildingTypesToUsers.requestTypes.SUCCESS:
        return {
          selectedBuildingTypesToUser: null,
          buildingTypesToUsers: response,
        };
      case addBuildingTypesToUser.requestTypes.REQUEST:
      case deleteBuildingTypesToUser.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addBuildingTypesToUser.requestTypes.FAILURE:
      case deleteBuildingTypesToUser.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addBuildingTypesToUser.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBuildingTypesToUser: response,
          isSuccessful: true,
        };
      case updateBuildingTypesToUser.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateBuildingTypesToUser.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateBuildingTypesToUser.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBuildingTypesToUser: response,
          isSuccessful: true,
        };
      case fetchOneBuildingTypesToUser.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBuildingTypesToUser: response,
        };
      case deleteBuildingTypesToUser.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
          isSuccessful: true,
        };
      default:
        return state;
    }
  };
};

export default buildingTypesToUsers();
