import client from './main-client';

export const fetchAllBuildingTypesApi = params =>
  client().get('/options-building-types', { params });
export const fetchOneBuildingTypeApi = ({ id, ...params }) =>
  client().get(`/options-building-types/${id}`, { params });
export const updateBuildingTypeApi = params =>
  client().put(`/options-building-types/${params.id}`, params);
export const addBuildingTypeApi = data => client().post('/options-building-types', data);
export const deleteBuildingTypeApi = params =>
  client().delete(`/options-building-types/${params.id}`, { params });
