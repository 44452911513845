import React, { useState } from 'react';
import { Card, Col, Form, List, Row, Skeleton, Icon } from 'antd';
import { TextArea, InputNumber } from 'components';

const InvoiceForm = ({
  selectedServices,
  setSelectedServices,
  additionalFeeDesc,
  additionalFeePrice,
  travelFee,
  handleChange,
  handleTravelChange,
  subtotal,
  hst,
  additionalHst,
  travelHst,
  total,
}) => {
  const [isCorrectFormat, setIsCorrectFormat] = useState(true);

  const deleteService = item =>
    setSelectedServices([...selectedServices.filter(s => s.id !== item.id)]);

  const handleAddFeeChange = (value, isTravel = false) => {
    if (Number.isNaN(value)) {
      return setIsCorrectFormat(false);
    }
    setIsCorrectFormat(true);
    if (isTravel) {
      return handleTravelChange(Number(value).toFixed(2));
    }
    handleChange(Number(value).toFixed(2), null);
  };

  const handleServicePriceChange = (price, id) => {
    const index = selectedServices.findIndex(service => service.id === id);
    const edited = { ...selectedServices[index], price: price };
    const updated = [...selectedServices];
    updated.splice(index, 1, edited);
    setSelectedServices([...updated]);
  };

  return (
    <Card title='Invoice'>
      <List
        size='large'
        header={false}
        footer={false}
        bordered={false}
        dataSource={selectedServices}
        renderItem={item => (
          <List.Item>
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                title={
                  <span className='invoice-service'>
                    <Icon
                      className='delete-icon'
                      type='delete'
                      onClick={() => deleteService(item)}
                    />{' '}
                    {item.name}
                  </span>
                }
              />
              <div>
                <InputNumber
                  value={item.price}
                  precision={2}
                  step={0.1}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={value => handleServicePriceChange(+value, item.id)}
                />
              </div>
            </Skeleton>
          </List.Item>
        )}
      />
      <div className='invoice-details'>
        <Row className='additional-fee'>
          <Col span={24} className='additional-fee-title'>
            Additional Fee
          </Col>
          <Col span={24}>Description</Col>
          <Col className='align-right' span={24}>
            <TextArea
              value={additionalFeeDesc}
              onChange={e => handleChange(null, e.target.value)}
            />
          </Col>
          <Col span={12}>Price</Col>
          <Col className='align-right additional-fee-price' span={12}>
            <InputNumber
              value={additionalFeePrice}
              precision={2}
              step={0.1}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              onChange={value => handleAddFeeChange(+value, false)}
            />
            {!isCorrectFormat && <p className='validation-msg'>Please insert a valid amount.</p>}
          </Col>
        </Row>
        <Row className='additional-fee travel-fee'>
          <Col span={12} className='additional-fee-title'>
            Travel Fee
          </Col>
          <Col className='align-right additional-fee-price' span={12}>
            <InputNumber
              value={travelFee}
              precision={2}
              step={0.1}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              onChange={value => handleAddFeeChange(+value, true)}
            />
            {!isCorrectFormat && <p className='validation-msg'>Please insert a valid amount.</p>}
          </Col>
        </Row>
        <Row>
          <Col span={12}>Subtotal</Col>
          <Col className='align-right' span={12}>
            {`$ ${Number(subtotal).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </Col>
        </Row>
        <Row>
          <Col span={12}>HST (%)</Col>
          <Col className='align-right' span={12}>
            {`$ ${Number(hst + additionalHst + travelHst).toFixed(2)}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            )}
          </Col>
        </Row>
      </div>
      <Row className='total'>
        <Col span={12}>Total</Col>
        <Col className='align-right' span={12}>
          {`$ ${Number(total).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </Col>
      </Row>
    </Card>
  );
};

export default Form.create({ name: 'invoice_form' })(InvoiceForm);
