import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchPayments,
  fetchOnePayment,
  addPayment,
  updatePayment,
  deletePayment,
  getClientSecret,
} from 'actions/payments';
import {
  fetchAllPaymentsApi,
  fetchOnePaymentApi,
  addPaymentApi,
  updatePaymentApi,
  deletePaymentApi,
} from 'services/payments';
import { loadUpdateLogedInUserData } from 'sagas/users';
import fetchEntity from './fetch-entity';

const fetchPaymentsData = fetchEntity.bind(null, fetchPayments.actions, fetchAllPaymentsApi);

const fetchPaymentData = fetchEntity.bind(null, fetchOnePayment.actions, fetchOnePaymentApi);

const fetchAddPayment = fetchEntity.bind(null, addPayment.actions, addPaymentApi);

const fetchUpdatePayment = fetchEntity.bind(null, updatePayment.actions, updatePaymentApi);

const fetchDeletePayment = fetchEntity.bind(null, deletePayment.actions, deletePaymentApi);

export function* loadFetchPayments({ params }) {
  yield call(fetchPaymentsData, { ...params });
}

export function* loadGetPayment({ params }) {
  yield put(fetchOnePayment.actions.failure({}, undefined));
  yield call(fetchPaymentData, params);
}

export function* loadAddPayment({ params }) {
  yield call(fetchAddPayment, params);
}

export function* loadUpdatePayment({ params }) {
  yield call(fetchUpdatePayment, params);
}

export function* loadDeletePayment({ params }) {
  yield call(fetchDeletePayment, { ...params });
}

function* watchFetchPayments() {
  yield takeLatest(fetchPayments.actionName, loadFetchPayments);
}

function* watchAddPayment() {
  yield takeLatest(addPayment.actionName, loadAddPayment);
}

function* watchUpdatePayment() {
  yield takeLatest(updatePayment.actionName, loadUpdatePayment);
}

function* watchDeletePayment() {
  yield takeLatest(deletePayment.actionName, loadDeletePayment);
}

function* watchGetPayment() {
  yield takeLatest(fetchOnePayment.actionName, loadGetPayment);
}

export function* loadPaymentsOnChange() {
  const payments = yield select(state => state.payments);
  const { total, skip, limit } = payments;
  if (skip && skip >= total) {
    yield call(fetchPaymentsData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchPaymentsData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchPaymentsOnChange() {
  yield takeLatest([deletePayment.requestTypes.SUCCESS], loadPaymentsOnChange);
}

function* watchOnPaymentComplate() {
  yield takeLatest([addPayment.requestTypes.SUCCESS], loadUpdateLogedInUserData);
}

const getClientSecretData = fetchEntity.bind(null, getClientSecret.actions, fetchAllPaymentsApi);

export function* loadGetClintSecret({ params }) {
  yield call(getClientSecretData, { ...params });
}

function* watchGetClientSecret() {
  yield takeLatest(getClientSecret.actionName, loadGetClintSecret);
}

export default {
  watchFetchPayments,
  watchAddPayment,
  watchGetPayment,
  watchUpdatePayment,
  watchPaymentsOnChange,
  watchDeletePayment,
  watchGetClientSecret,
  watchOnPaymentComplate,
};
