import styled, { css } from 'styled-components';
import { Tabs as tabs } from 'antd';
import propTypes from 'prop-types';

const Tabs = styled(tabs)`
  .ant-tabs-tab {
    text-align: left !important;
    @media (max-width: 992px) {
      padding: 14px 16px 0 !important;
    }
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #3c29e6 !important;
    font-weight: bold !important;
  }
  .ant-tabs-ink-bar {
    background-color: #fff;
    @media (max-width: 992px) {
      display: none !important;
    }
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding-left: 0 !important;
    padding-right: 25px !important;
  }



  ${props =>
    props.separateLine &&
    css`
      .ant-tabs-nav-wrap{
        border-bottom:4px solid #fff;
        @media (max-width: 992px) {
            border-bottom:none;
      }

      .ant-tabs-small-bar .ant-tabs-tab {
        padding: 4px 16px !important;
      }

      .ant-tabs-nav {
        border-bottom: 4px solid white;
        @media (max-width: 992px) {
          border-bottom:none;

    }
      }
      .ant-tabs-nav .ant-tabs-tab {
        padding-left: 0 !important;
        padding-right: 5px !important;
        margin: 0 !important;
      }

      .ant-tabs-nav-scroll {
        overflow: unset !important;
        white-space: break-spaces !important;
      }

      @media (max-width: 992px) {
        .ant-tabs-nav-scroll {
          white-space: inherit !important;
        }
      }
    `}

    ${props =>
      props.generalTab === true &&
      css`
        .ant-tabs-tab {
          @media (max-width: 992px) {
            width: 100% !important;
            text-align: center !important;
          }
        }
      `}
  ${props =>
    css`
      .ant-tabs-bar {
        height: ${props.tabsheight};
        @media (max-width: 992px) {
          border-top: 1px solid #e8e8e8;
        }
      }
    `}
  ${props =>
    css`
      .ant-tabs-tab {
        @media (max-width: 992px) {
          width: ${props.tabs_width} !important;
        }
      }
    `}
`;

Tabs.propTypes = {
  type: propTypes.string,
};

Tabs.defaultProps = {};

/** @component */
export default Tabs;
