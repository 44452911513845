import React from 'react';
import { notification, Icon } from 'antd';
import { ReactComponent as WarningIcon } from '../../assets/imgs/warning.svg';
import { ReactComponent as SuccessIcon } from '../../assets/imgs/success.svg';
import { ReactComponent as InfoIcon } from '../../assets/imgs/info.svg';

notification.config({
  placement: 'topRight',
  top: 150,
  duration: 3,
  rtl: true,
});

const warning = description => {
  notification.warning({
    message: 'Oops! ',
    description,
    icon: <WarningIcon />,
    className: 'warning-notification',
    closeIcon: <Icon type='close' style={{ color: '#A35050' }} />,
    style: {
      borderRadius: 5,
      border: '1px solid rgba(163, 85, 85, 0.1)',
      backgroundColor: '#f3eaea',
      color: '#A35050',
      zIndex: '9',
    },
  });
};

const success = description => {
  notification.success({
    message: 'Success',
    description,
    icon: <SuccessIcon />,
    className: 'success-notification',
    closeIcon: <Icon type='close' style={{ color: '#929D2D' }} />,
    style: {
      borderRadius: 5,
      border: '1px solid rgba(146, 157, 45, 0.1)',
      backgroundColor: '#eef0d8',
      color: '#929D2D',
      zIndex: '9',
    },
  });
};

const info = description => {
  notification.info({
    message: 'Information',
    description,
    icon: <InfoIcon />,
    className: 'info-notification',
    closeIcon: <Icon type='close' style={{ color: '#4D638F' }} />,
    style: {
      borderRadius: 5,
      border: '1px solid rgba(77, 99, 143, 0.1)',
      backgroundColor: '#dfeaf5',
      color: '#4D638F',
      zIndex: '9',
    },
  });
};

const Notification = { warning, success, info };

export default Notification;
