/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LogoutButton from 'containers/Authorization/LogoutButton';
import { AddBooking } from 'components';
import { Icon, Row, Col } from 'antd';
import { getUserData } from 'selectors/users';
import { roles } from 'constants/users';
import { isUserPermitted } from 'configs/isUserPermitted';
import { Content, Layout, PageHeader, ResponsiveMenu } from './components/Partials';

const { pathname } = window.location;
const isFromMobileApp = pathname.includes('/appreports') || pathname.includes('/app-rep');

const DashboardWrapper = ({ location, history, children }) => {
  const isDashboardPage = location.pathname === '/';
  const isSuperAdmin = location.pathname === '/dashboard';

  const getLocationState = () => {
    return isUserPermitted([roles.ROLE_SUPER_ADMIN])
      ? 'Dashboard'
      : isUserPermitted([roles.ROLE_INSPECTOR])
      ? 'All Reports'
      : 'Calendar';
  };

  location.state = typeof location.state === 'undefined' ? getLocationState() : location.state;
  const userData = useSelector(getUserData);

  return (
    <Layout dashboardlayout='dashboardLayout'>
      <ResponsiveMenu userData={userData} />
      <Layout>
        <Row>
          <Col xs={0} md={24}>
            {!isFromMobileApp && (
              <PageHeader
                {...(isDashboardPage || (isSuperAdmin && { backIcon: <Icon type='home' /> }))}
                onBack={() =>
                  isDashboardPage ? history.push('/') : isSuperAdmin ? null : history.goBack()
                }
                title={location.state}
                extra={[
                  <div key='user-info' style={{ display: 'flex' }}>
                    <AddBooking />{' '}
                    <div
                      style={{ display: 'inline-block', position: 'relative', marginTop: '-7px' }}
                    >
                      Hello,
                      <span className={'username'}>
                        {userData.firstName ? userData.firstName : userData.email}
                      </span>
                      <Link to={'#'}>
                        <LogoutButton />
                      </Link>
                      <p
                        style={{
                          position: 'absolute',
                          fontSize: '10px',
                          top: '24px',
                        }}
                      >
                        ({userData?.role})
                      </p>
                    </div>
                  </div>,
                ]}
              />
            )}
          </Col>
        </Row>

        <Content isFromMobileApp={isFromMobileApp}>{children}</Content>
      </Layout>
    </Layout>
  );
};
export default React.memo(withRouter(DashboardWrapper));
