import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchReportsHistory,
  fetchOneReportHistory,
  addReportHistory,
  updateReportHistory,
  deleteReportHistory,
} from 'actions/reports-history';
import {
  fetchAllReportsHistoryApi,
  fetchOneReportHistoryApi,
  addReportHistoryApi,
  updateReportHistoryApi,
  deleteReportHistoryApi,
} from 'services/reports-history';
import fetchEntity from './fetch-entity';

const fetchReportsHistoryData = fetchEntity.bind(
  null,
  fetchReportsHistory.actions,
  fetchAllReportsHistoryApi
);

const fetchReportHistoryData = fetchEntity.bind(
  null,
  fetchOneReportHistory.actions,
  fetchOneReportHistoryApi
);

const fetchAddReportHistory = fetchEntity.bind(null, addReportHistory.actions, addReportHistoryApi);

const fetchUpdateReportHistory = fetchEntity.bind(
  null,
  updateReportHistory.actions,
  updateReportHistoryApi
);

const fetchDeleteReportHistory = fetchEntity.bind(
  null,
  deleteReportHistory.actions,
  deleteReportHistoryApi
);

export function* loadFetchReportsHistory({ params }) {
  yield call(fetchReportsHistoryData, { ...params });
}

export function* loadGetReportHistory({ params }) {
  yield put(fetchOneReportHistory.actions.failure({}, undefined));
  yield call(fetchReportHistoryData, params);
}

export function* loadAddReportHistory({ params }) {
  yield call(fetchAddReportHistory, params);
}

export function* loadUpdateReportHistory({ params }) {
  yield call(fetchUpdateReportHistory, params);
}

export function* loadDeleteReportHistory({ params }) {
  yield call(fetchDeleteReportHistory, { ...params });
}

function* watchFetchReportsHistory() {
  yield takeLatest(fetchReportsHistory.actionName, loadFetchReportsHistory);
}

function* watchAddReportHistory() {
  yield takeLatest(addReportHistory.actionName, loadAddReportHistory);
}

function* watchUpdateReportHistory() {
  yield takeLatest(updateReportHistory.actionName, loadUpdateReportHistory);
}

function* watchDeleteReportHistory() {
  yield takeLatest(deleteReportHistory.actionName, loadDeleteReportHistory);
}

function* watchGetReportHistory() {
  yield takeLatest(fetchOneReportHistory.actionName, loadGetReportHistory);
}

export function* loadReportsHistoryOnChange() {
  const reportsHistory = yield select(state => state.reportsHistory);
  const { total, skip, limit } = reportsHistory;
  if (skip && skip >= total) {
    yield call(fetchReportsHistoryData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchReportsHistoryData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchReportsHistoryOnChange() {
  yield takeLatest([deleteReportHistory.requestTypes.SUCCESS], loadReportsHistoryOnChange);
}

export default {
  watchFetchReportsHistory,
  watchAddReportHistory,
  watchGetReportHistory,
  watchUpdateReportHistory,
  watchReportsHistoryOnChange,
  watchDeleteReportHistory,
};
