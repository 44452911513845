import styled from 'styled-components';
import { Input } from 'antd';

const { TextArea: textarea } = Input;

const TextArea = styled(textarea)`
  border: 1px solid #e5e5e5 !important;
  border-radius: 5px !important;
  background-color: #f1f1f1 !important;
  font-size: 16px;
  &:focus {
    background-color: #fff !important;
  }
`;

/** @component */
export default TextArea;
