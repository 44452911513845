export default {
  content: `
  Thanks very much for choosing us to perform your home inspection. The inspection itself and the
  attached report comply with the requirements of the Standards of Practice of our national 
  Association. This document defines the scope of a home inspection.<br><br> Clients sometimes assume that a
  home inspection will include many things that are beyond the scope. We encourage you to read the 
  Standards of Practice so that you clearly understand what things are included in the home 
  inspection and report.<br><br>The report has been prepared for the exclusive use of our client. No use by 
  third parties is intended. We will not be responsible to any parties for the contents of the 
  report, other than the party named herein.<br><br> The report is effectively a snapshot of the house, 
  recording the conditions on a given date and time. Home inspectors cannot predict future 
  behavior, and as such, we cannot be responsible for things that occur after the inspection. 
  If conditions change, we are available to revisit the property and update our report.<br><br>
  The report itself is copyrighted and may not be used in whole or in part without our express 
  written permission. <br><br>
  Again, thanks very much for choosing us to perform your home inspection.<br>
 `,
};
