import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userLogin } from 'actions/users';
import React from 'react';

const qs = require('query-string');

const parsed = qs.parse(window.location.search);
localStorage.setItem('repId', parsed.id);
const AppRep = () => {
  const dispatch = useDispatch();
  dispatch(
    userLogin.request({
      email: parsed.u,
      password: parsed.p,
    })
  );
  return <></>;
};

export default withRouter(React.memo(AppRep));
