import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'antd';
import { Button, Notification } from 'components';
import { userLogout } from 'actions/users';
import { logoutError } from 'selectors/users';

const LogoutButton = props => {
  const { ismobile } = props;
  const dispatch = useDispatch();
  const error = useSelector(logoutError);
  useEffect(() => {
    if (error) {
      Notification.warning('error');
    }
  }, [error]);
  return (
    <Button type='link' ghost onClick={() => dispatch(userLogout.request())} {...props}>
      Log out {!ismobile && <Icon type='logout' />}
    </Button>
  );
};
LogoutButton.defaultProps = {
  text: 'Logout',
};
export default React.memo(LogoutButton);
