import { sendReportEmail, sendApprovalEmail } from 'actions/report-email';

const reportEmail = () => {
  const initialState = {};

  return (state = initialState, { type, response }) => {
    switch (type) {
      case sendReportEmail.requestTypes.SUCCESS:
        return {
          ...state,
          email: response,
        };
      case sendApprovalEmail.requestTypes.SUCCESS:
        return {
          ...state,
          email: response,
        };
      default:
        return state;
    }
  };
};

export default reportEmail();
