import {
  fetchDwellingsToUsers,
  fetchOneDwellingsToUser,
  addDwellingsToUser,
  updateDwellingsToUser,
  deleteDwellingsToUser,
} from 'actions/dwellings-to-users';

const dwellingsToUsers = () => {
  const initialState = {
    dwellingsToUsers: null,
    selectedDwellingsToUser: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchDwellingsToUsers.requestTypes.SUCCESS:
        return {
          selectedDwellingsToUser: null,
          dwellingsToUsers: response,
        };
      case addDwellingsToUser.requestTypes.REQUEST:
      case deleteDwellingsToUser.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addDwellingsToUser.requestTypes.FAILURE:
      case deleteDwellingsToUser.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addDwellingsToUser.requestTypes.SUCCESS:
        return {
          ...state,
          selectedDwellingsToUser: response,
          isSuccessful: true,
        };
      case updateDwellingsToUser.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateDwellingsToUser.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateDwellingsToUser.requestTypes.SUCCESS:
        return {
          ...state,
          selectedDwellingsToUser: response,
          isSuccessful: true,
        };
      case fetchOneDwellingsToUser.requestTypes.SUCCESS:
        return {
          ...state,
          selectedDwellingsToUser: response,
        };
      case deleteDwellingsToUser.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
          isSuccessful: true,
        };
      default:
        return state;
    }
  };
};

export default dwellingsToUsers();
