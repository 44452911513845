import { makeRequestAction } from './index';

/**
 * Fetch profiles
 */
export const fetchProfiles = makeRequestAction('FETCH_PROFILES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addProfile = makeRequestAction('ADD_PROFILE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateProfile = makeRequestAction('UPDATE_PROFILE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneProfile = makeRequestAction('GET_PROFILE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteProfile = makeRequestAction('DELETE_PROFILE', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
