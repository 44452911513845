import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchBuildingTypes,
  fetchOneBuildingType,
  addBuildingType,
  updateBuildingType,
  deleteBuildingType,
} from 'actions/building-types';
import {
  fetchAllBuildingTypesApi,
  fetchOneBuildingTypeApi,
  addBuildingTypeApi,
  updateBuildingTypeApi,
  deleteBuildingTypeApi,
} from 'services/building-types';
import fetchEntity from './fetch-entity';

const fetchBuildingTypesData = fetchEntity.bind(
  null,
  fetchBuildingTypes.actions,
  fetchAllBuildingTypesApi
);

const fetchBuildingTypeData = fetchEntity.bind(
  null,
  fetchOneBuildingType.actions,
  fetchOneBuildingTypeApi
);

const fetchAddBuildingType = fetchEntity.bind(null, addBuildingType.actions, addBuildingTypeApi);

const fetchUpdateBuildingType = fetchEntity.bind(
  null,
  updateBuildingType.actions,
  updateBuildingTypeApi
);

const fetchDeleteBuildingType = fetchEntity.bind(
  null,
  deleteBuildingType.actions,
  deleteBuildingTypeApi
);

export function* loadFetchBuildingTypes({ params }) {
  yield call(fetchBuildingTypesData, { ...params });
}

export function* loadGetBuildingType({ params }) {
  yield put(fetchOneBuildingType.actions.failure({}, undefined));
  yield call(fetchBuildingTypeData, params);
}

export function* loadAddBuildingType({ params }) {
  yield call(fetchAddBuildingType, params);
}

export function* loadUpdateBuildingType({ params }) {
  yield call(fetchUpdateBuildingType, params);
}

export function* loadDeleteBuildingType({ params }) {
  yield call(fetchDeleteBuildingType, { ...params });
}

function* watchFetchBuildingTypes() {
  yield takeLatest(fetchBuildingTypes.actionName, loadFetchBuildingTypes);
}

function* watchAddBuildingType() {
  yield takeLatest(addBuildingType.actionName, loadAddBuildingType);
}

function* watchUpdateBuildingType() {
  yield takeLatest(updateBuildingType.actionName, loadUpdateBuildingType);
}

function* watchDeleteBuildingType() {
  yield takeLatest(deleteBuildingType.actionName, loadDeleteBuildingType);
}

function* watchGetBuildingType() {
  yield takeLatest(fetchOneBuildingType.actionName, loadGetBuildingType);
}

export function* loadBuildingTypesOnChange() {
  const buildingTypes = yield select(state => state.buildingTypes);
  const { total, skip, limit } = buildingTypes;
  if (skip && skip >= total) {
    yield call(fetchBuildingTypesData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchBuildingTypesData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchBuildingTypesOnChange() {
  yield takeLatest([deleteBuildingType.requestTypes.SUCCESS], loadBuildingTypesOnChange);
}

export default {
  watchFetchBuildingTypes,
  watchAddBuildingType,
  watchGetBuildingType,
  watchUpdateBuildingType,
  watchBuildingTypesOnChange,
  watchDeleteBuildingType,
};
