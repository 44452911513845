import React from 'react';
import { Select } from 'components';

const { Option } = Select;

const SelectItem = ({ options, ...rest }) => (
  <Select {...rest}>
    {options.map(option => (
      <Option value={option.id} label={option.name} key={option.id} title={option.name}>
        {option.name}
      </Option>
    ))}
  </Select>
);

export default SelectItem;
