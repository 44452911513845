import {
  fetchTemplates,
  fetchOneTemplate,
  addTemplate,
  updateTemplate,
  addTemplateSection,
  addFormUploads,
  deleteFormUploads,
  addBase64Uploads,
} from 'actions/templates';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';
import getUploadsState from './uploads';

export const getTemplates = state => state.templates;
export const getTemplatesState = createSelector(getTemplates, data =>
  data.templates ? data.templates : []
);
export const getTemplatesTotal = createSelector(getTemplatesState, data => (data ? data.total : 0));

export const getSuccessState = createSelector(getTemplates, data => data.isSuccessful);
export const getSelectedTemplate = createSelector(getTemplates, data =>
  data.selectedTemplate ? data.selectedTemplate : {}
);
export const getAddSectionSuccessState = createSelector(
  getTemplates,
  data => data.isAddSectionSuccessful
);

export const templatesValuesLoading = createLoadingSelector(fetchTemplates.actionName)();
export const templatesValuesError = createErrorSelector(fetchTemplates.actionName)();

export const getAddTemplateLoading = createLoadingSelector(addTemplate.actionName)();
export const getAddTemplateError = createErrorSelector(addTemplate.actionName)();

export const getAddSectionLoading = createLoadingSelector(addTemplateSection.actionName)();
export const getAddSectionError = createErrorSelector(addTemplateSection.actionName)();

export const getReorderSectionsLoading = createLoadingSelector(updateTemplate.actionName)();

export const getSelectedTemplateLoading = createLoadingSelector(fetchOneTemplate.actionName)();
export const getSelectedTemplateError = createErrorSelector(fetchOneTemplate.actionName)();

export const getFormUploads = createSelector(
  getUploadsState,
  uploads => uploads[addFormUploads.actionName]
);
export const getBse64Uploads = createSelector(
  getUploadsState,
  uploads => uploads[addBase64Uploads.actionName]
);

export const addBase64UploadsLoading = createLoadingSelector(addBase64Uploads.actionName)();
export const addFormUploadsLoading = createLoadingSelector(addFormUploads.actionName)();
export const deleteFormUploadsLoading = createLoadingSelector(deleteFormUploads.actionName)();
