/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { Col, Row } from 'antd';

const { pathname } = window.location;
const isFromMobileApp = pathname.includes('/appreports') || pathname.includes('/app-rep');

// eslint-disable-next-line no-unused-vars
const nodes = [
  {
    url: 'https://neuralspect.com/privacy-policy/',
    label: 'Privacy Policy',
  },
  {
    url: 'https://neuralspect.com/terms-conditions/',
    label: 'Terms & Conditions',
  },
];

const GuestWrapper = ({ children }) => (
  <Fragment>
    <Row style={{ height: '100vh' }} type='flex' justify='center'>
      <Col xs={24} md={16} style={{ margin: 'auto' }}>
        {children}
        {!isFromMobileApp && (
          <div style={{ display: 'flex', justifyContent: 'space-around', margin: '0 30px' }}>
            <a href='https://neuralspect.com/privacy-policy/' style={{ color: '#000' }}>
              Privacy Policy
            </a>
            <a href='https://neuralspect.com/terms-conditions/' style={{ color: '#000' }}>
              Terms & Conditions
            </a>
          </div>
        )}
        {/* <Nav nodes={nodes} /> */}
      </Col>
      {/*<Col xs={0} md={8}>*/}
      {/*  <SidePhoto src={Background} />*/}
      {/*</Col>*/}
    </Row>
  </Fragment>
);

export default React.memo(GuestWrapper);
