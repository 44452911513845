import { fetchCoverLetter, addCoverLetter, updateCoverLetter } from 'actions/cover-letters';

const coverLetters = () => {
  return (state = {}, { type, response }) => {
    switch (type) {
      case fetchCoverLetter.requestTypes.SUCCESS:
        return {
          ...state,
          coverLetter: response,
        };
      case addCoverLetter.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addCoverLetter.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      case updateCoverLetter.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addCoverLetter.requestTypes.FAILURE:
      case updateCoverLetter.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateCoverLetter.requestTypes.SUCCESS:
        return {
          ...state,
          coverLetter: response,
          isSuccessful: true,
        };
      default:
        return state;
    }
  };
};

export default coverLetters();
