import React, { useEffect, useState } from 'react';
import { addBookingInfo, cancelSubmit } from 'actions/booking-form';
import { fetchContracts, fetchUserContracts } from 'actions/contracts';
import { RichTextEditor, Title } from 'components/index';
import {
  getContractsState,
  getUserContractsState,
  contractsValuesLoading,
  userContractsValuesLoading,
} from 'selectors/contracts';
import { useDispatch, useSelector } from 'react-redux';
import { Radio, Row, Col } from 'antd';
import { getBooking, getCurrent, getIsSubmitted } from 'selectors/booking-form';
import emailData from '../../../containers/Contracts/partials/emailData';
import { getUserData } from 'selectors/users';

const Contract = ({ handleChangeSlide }) => {
  const dispatch = useDispatch();
  const loading = useSelector(contractsValuesLoading);
  const userContractsLoading = useSelector(userContractsValuesLoading);
  const contractsData = useSelector(getContractsState);
  const userContractsData = useSelector(getUserContractsState);
  const booking = useSelector(getBooking);
  const isSubmitted = useSelector(getIsSubmitted);
  const current = useSelector(getCurrent);
  const userData = useSelector(getUserData);

  const [value, setValue] = useState(null);
  const [contentData, setContentData] = useState();
  const [allContracts, setAllContracts] = useState([]);
  const [showMsg, setShowMsg] = useState(false);

  useEffect(() => {
    dispatch(fetchUserContracts.request({ createdById: userData && userData.id }));
    dispatch(fetchContracts.request());
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (!loading && !userContractsLoading && contractsData.data && userContractsData.data) {
      setAllContracts([...contractsData.data, ...userContractsData.data]);
    } else if (!loading && contractsData.data) {
      setAllContracts([...contractsData.data]);
    }
    // eslint-disable-next-line
  }, [loading, userContractsLoading]);

  useEffect(() => {
    if (value) {
      setShowMsg(false);
    }
    if (value && allContracts) {
      const selectedContract = allContracts.find(c => c.id === value);
      setContentData((selectedContract && selectedContract.content) || emailData.text);
    }
  }, [allContracts, value]);

  useEffect(() => {
    if (booking && booking.contractId) {
      setValue(booking.contractId);
    }
  }, [booking]);

  useEffect(() => {
    if (isSubmitted) {
      if (value) {
        const values = {
          ...booking,
          contractId: value,
          contractContent: contentData,
        };

        dispatch(addBookingInfo.request(values));
        handleChangeSlide(current + 1);
      } else {
        dispatch(cancelSubmit.request());
        setShowMsg(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted]);

  const handleChange = e => {
    setValue(e.target.value);
  };

  return (
    <div className='contracts'>
      <Row>
        {showMsg && (
          <Title level={4} color='#f5222d'>
            Please select the agreement
          </Title>
        )}
        <Radio.Group onChange={handleChange} value={value}>
          {!loading &&
            allContracts &&
            allContracts.map(c => (
              <Col xs={24} md={12} xl={8}>
                <Radio value={c.id}>
                  <div className='contracts-details'>
                    <p className='contracts-title'>Title: {c.title}</p>
                    <p className='contracts-version'>Version: {c.version}</p>
                  </div>
                </Radio>
              </Col>
            ))}
        </Radio.Group>
      </Row>
      {value && contentData && (
        <>
          <p className='contracts-title'>Contract Content:</p>
          <RichTextEditor
            className='contracts-content'
            value={contentData}
            onChange={e => {
              setContentData(e);
            }}
          />
        </>
      )}
    </div>
  );
};

export default React.memo(Contract);
