export default {
  addressInput: {
    label: 'Inspection Address',
    fieldName: 'address',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input inspection address!',
        },
      ],
    },
    elementProps: {},
  },

  customAddressInput: {
    label: 'Inspection Address',
    fieldName: 'address',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input inspection address!',
        },
      ],
    },
    elementProps: {},
  },

  isCustomAddress: {
    label: 'The address is not found by Google',
    fieldName: 'isCustomAddress',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },

  addressLine2Input: {
    label: 'Address 2/Unit/Suite #',
    fieldName: 'addressLine2',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },
  inspectionTypeInput: {
    label: 'Inspection Type',
    fieldName: 'inspectionTypeId',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input inspection address!',
        },
      ],
    },
    elementProps: {},
  },
  dateInput: {
    label: 'Date',
    fieldName: 'date',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input inspection date!',
        },
      ],
    },
    elementProps: {},
  },
  timeInput: {
    label: 'Time',
    fieldName: 'time',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input inspection time!',
        },
      ],
    },
    elementProps: {},
  },
  notesInput: {
    label: 'Notes',
    fieldName: 'notes',
    decoratorOptions: {},
    elementProps: {},
  },
};
