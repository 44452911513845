import React from 'react';
import { Upload, Icon, Spin } from 'antd';
import { Notification } from 'components';

const { Dragger } = Upload;

const FileUpload = props => {
  const {
    fileList = [],
    changeHandler,
    loading,
    extensions,
    maxAmount,
    multiple,
    onPreview,
    fileSize = 25,
    ...otherProps
  } = props;

  const addFile = (file, selectedFileList) => {
    const hasBigFiles = selectedFileList.find(f => f.size > fileSize * 1024 * 1024);
    const hasRestrictedExtensions = !!selectedFileList.filter(
      f => extensions && !extensions.includes(f.name.split('.').pop())
    ).length;
    if (
      (!multiple && fileList.length >= 1) ||
      fileList.length + selectedFileList.length > maxAmount ||
      hasBigFiles
    ) {
      Notification.warning(
        `Maximum ${maxAmount || (!multiple && 1)} files allowed, ${fileSize} MB each`
      );
    } else if (hasRestrictedExtensions) {
      Notification.warning('Unsupported extension!');
    } else {
      changeHandler && changeHandler(selectedFileList, [...fileList, ...selectedFileList], 'add');
    }
    return false;
  };

  const deleteFile = file => {
    changeHandler &&
      changeHandler(
        file,
        fileList.filter(f => f.uid !== file.uid),
        'delete'
      );
  };

  return (
    <Spin spinning={!!loading}>
      <Dragger
        multiple={multiple}
        fileList={fileList}
        onRemove={deleteFile}
        beforeUpload={addFile}
        listType='picture'
        onPreview={onPreview}
        disabled={!multiple && fileList.length >= 1}
        {...otherProps}
      >
        <p className='ant-upload-drag-icon'>
          {' '}
          <Icon type='inbox' />{' '}
        </p>
        <p className='ant-upload-text'> Click or drag file to this area to upload </p>
        <p className='ant-upload-hint'>
          <span />
          {multiple && maxAmount ? (
            <span>
              {`Maximum ${maxAmount} files allowed `}
              {fileSize && `${fileSize}MB each`}
            </span>
          ) : (
            fileSize && `Upload size limit is ${fileSize} MB`
          )}
        </p>
        {extensions && (
          <p className='ant-upload-hint'>
            Supported extensions:{' '}
            {extensions && extensions !== 'undefined' && extensions.map(ext => `.${ext}, `)}
          </p>
        )}
      </Dragger>
    </Spin>
  );
};

export default FileUpload;
