import {
  fetchContracts,
  fetchUserContracts,
  fetchOneContract,
  addContract,
  deleteContract,
  updateContract,
} from 'actions/contracts';

const contracts = () => {
  const initialState = {
    contracts: [],
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchContracts.requestTypes.SUCCESS:
        return {
          ...state,
          contracts: response,
        };
      case fetchUserContracts.requestTypes.SUCCESS:
        return {
          ...state,
          userContracts: response,
        };
      case fetchOneContract.requestTypes.SUCCESS:
        return {
          ...state,
          selectedContract: response,
        };
      case addContract.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addContract.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      case deleteContract.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - response.ids.length,
        };
      case updateContract.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addContract.requestTypes.FAILURE:
      case updateContract.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateContract.requestTypes.SUCCESS:
        return {
          ...state,
          selectedContract: response,
          isSuccessful: true,
        };
      case 'CLEAN_ALL':
        return {
          ...initialState,
        };
      default:
        return state;
    }
  };
};

export default contracts();
