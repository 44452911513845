import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchServices,
  fetchOneService,
  addService,
  updateService,
  deleteService,
} from 'actions/services';
import {
  fetchAllServicesApi,
  fetchOneServiceApi,
  addServiceApi,
  updateServiceApi,
  deleteServiceApi,
} from 'services/services';
import fetchEntity from './fetch-entity';

const fetchServicesData = fetchEntity.bind(null, fetchServices.actions, fetchAllServicesApi);

const fetchServiceData = fetchEntity.bind(null, fetchOneService.actions, fetchOneServiceApi);

const fetchAddService = fetchEntity.bind(null, addService.actions, addServiceApi);

const fetchUpdateService = fetchEntity.bind(null, updateService.actions, updateServiceApi);

const fetchDeleteService = fetchEntity.bind(null, deleteService.actions, deleteServiceApi);

export function* loadFetchServices({ params }) {
  yield call(fetchServicesData, { ...params });
}

export function* loadGetService({ params }) {
  yield put(fetchOneService.actions.failure({}, undefined));
  yield call(fetchServiceData, params);
}

export function* loadAddService({ params }) {
  yield call(fetchAddService, params);
}

export function* loadUpdateService({ params }) {
  yield call(fetchUpdateService, params);
}

export function* loadDeleteService({ params }) {
  yield call(fetchDeleteService, { ...params });
}

function* watchFetchServices() {
  yield takeLatest(fetchServices.actionName, loadFetchServices);
}

function* watchAddService() {
  yield takeLatest(addService.actionName, loadAddService);
}

function* watchUpdateService() {
  yield takeLatest(updateService.actionName, loadUpdateService);
}

function* watchDeleteService() {
  yield takeLatest(deleteService.actionName, loadDeleteService);
}

function* watchGetService() {
  yield takeLatest(fetchOneService.actionName, loadGetService);
}

export function* loadServicesOnChange() {
  const services = yield select(state => state.services);
  const { total, skip, limit } = services;
  if (skip && skip >= total) {
    yield call(fetchServicesData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchServicesData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchServicesOnChange() {
  yield takeLatest(
    [
      deleteService.requestTypes.SUCCESS,
      updateService.requestTypes.SUCCESS,
      addService.requestTypes.SUCCESS,
    ],
    loadServicesOnChange
  );
}

export default {
  watchFetchServices,
  watchAddService,
  watchGetService,
  watchUpdateService,
  watchServicesOnChange,
  watchDeleteService,
};
