import {
  fetchServices,
  fetchOneService,
  addService,
  updateService,
  deleteService,
} from 'actions/services';

const services = () => {
  const initialState = {
    services: null,
    selectedService: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchServices.requestTypes.SUCCESS:
        return {
          selectedService: null,
          services: response,
        };
      case addService.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addService.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addService.requestTypes.SUCCESS:
        return {
          ...state,
          selectedService: response,
          isSuccessful: true,
        };
      case updateService.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateService.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateService.requestTypes.SUCCESS:
        return {
          ...state,
          selectedService: response,
          isSuccessful: true,
        };
      case fetchOneService.requestTypes.SUCCESS:
        return {
          ...state,
          selectedService: response,
        };
      case deleteService.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default services();
