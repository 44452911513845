import { makeRequestAction } from './index';

/**
 * Fetch packages
 */
export const fetchPackages = makeRequestAction('FETCH_PACKAGES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addPackage = makeRequestAction('ADD_PACKAGE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updatePackage = makeRequestAction('UPDATE_PACKAGE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOnePackage = makeRequestAction('GET_PACKAGE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deletePackage = makeRequestAction('DELETE_PACKAGE', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
