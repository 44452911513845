import styled, { css } from 'styled-components';
import propTypes from 'prop-types';

const DataSection = styled.div`

  padding-top: ${props => `${props.top}px`};
  padding-bottom: ${props => `${props.bottom}px`};
  padding-right: ${props => `${props.left}px`};
  padding-left: ${props => `${props.right}px`};
  ${props =>
    props.textLeft &&
    css`
      text-align: left;
    `}
    ${props =>
      props.width &&
      css`
        width: ${props.width};
      `}
  ${props =>
    props.textRight &&
    css`
      text-align: right;
    `}
  ${props =>
    props.inline &&
    css`
      display: inline;
    `}

  ${props =>
    props.pullLeft &&
    css`
      float: left;
    `}
  ${props =>
    props.pullRight &&
    css`
      float: right;
    `}
  ${props =>
    props.clearFix &&
    css`
      &::after {
        display: block;
        content: '';
        clear: both;
      }
    `}
`;

DataSection.propTypes = {
  top: propTypes.number,
  bottom: propTypes.number,
  left: propTypes.number,
  right: propTypes.number,
};

DataSection.defaultProps = {
  top: 25,
  bottom: 25,
  left: 0,
  right: 0,
};

/** @component */
export default DataSection;
