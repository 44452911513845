import { makeRequestAction } from './index';

/**
 * Fetch payments
 */
export const fetchPayments = makeRequestAction('FETCH_PAYMENTS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addPayment = makeRequestAction('ADD_PAYMENT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updatePayment = makeRequestAction('UPDATE_PAYMENT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOnePayment = makeRequestAction('GET_PAYMENT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deletePayment = makeRequestAction('DELETE_PAYMENT', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});

export const getClientSecret = makeRequestAction('GET_CLIENT_SECRET', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
