import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchTemplates,
  fetchOneTemplate,
  addTemplate,
  updateTemplate,
  addTemplateSection,
  deleteTemplate,
  addFormUploads,
  addBase64Uploads,
  deleteFormUploads,
  updateTemplateStatus,
} from 'actions/templates';
import {
  fetchAllTemplatesApi,
  fetchOneTemplateApi,
  addTemplateApi,
  updateTemplateApi,
  deleteTemplateApi,
  updateTemplateStatusApi,
} from 'services/templates';
import { addUploadApi, deleteUploadApi, addBase64UploadApi } from 'services/uploads';
import fetchEntity from './fetch-entity';

const fetchTemplatesData = fetchEntity.bind(null, fetchTemplates.actions, fetchAllTemplatesApi);

const fetchTemplateData = fetchEntity.bind(null, fetchOneTemplate.actions, fetchOneTemplateApi);

const fetchAddTemplate = fetchEntity.bind(null, addTemplate.actions, addTemplateApi);

const fetchUpdateTemplate = fetchEntity.bind(null, updateTemplate.actions, updateTemplateApi);

const fetchUpdateStatusTemplate = fetchEntity.bind(
  null,
  updateTemplateStatus.actions,
  updateTemplateStatusApi
);

const fetchAddTemplateSection = fetchEntity.bind(
  null,
  addTemplateSection.actions,
  updateTemplateApi
);

const fetchDeleteTemplate = fetchEntity.bind(null, deleteTemplate.actions, deleteTemplateApi);

const fetchAddFormUploads = fetchEntity.bind(null, addFormUploads.actions, addUploadApi);

const fetchAddBase64Uploads = fetchEntity.bind(null, addBase64Uploads.actions, addBase64UploadApi);

const fetchDeleteFormUploads = fetchEntity.bind(null, deleteFormUploads.actions, deleteUploadApi);

export function* loadFetchTemplates({ params }) {
  yield call(fetchTemplatesData, { ...params });
}

export function* loadGetTemplate({ params }) {
  yield put(fetchOneTemplate.actions.failure({}, undefined));
  yield call(fetchTemplateData, params);
}

export function* loadAddTemplate({ params }) {
  yield call(fetchAddTemplate, params);
}

export function* loadUpdateTemplate({ params }) {
  yield call(fetchUpdateTemplate, params);
}

export function* loadUpdateStatusTemplate({ params }) {
  yield call(fetchUpdateStatusTemplate, params);
}

export function* loadAddTemplateSection({ params }) {
  yield call(fetchAddTemplateSection, params);
}

export function* loadDeleteTemplate({ params }) {
  yield call(fetchDeleteTemplate, { ...params });
}

export function* loadAddFormUploads({ params }) {
  yield call(fetchAddFormUploads, { ...params });
}
export function* loadAddBase64Uploads({ params }) {
  yield call(fetchAddBase64Uploads, { ...params });
}

export function* loadDeleteFormUploads({ params }) {
  yield call(fetchDeleteFormUploads, { ...params });
}

function* watchFetchTemplates() {
  yield takeLatest(fetchTemplates.actionName, loadFetchTemplates);
}

function* watchAddTemplate() {
  yield takeLatest(addTemplate.actionName, loadAddTemplate);
}

function* watchUpdateTemplate() {
  yield takeLatest(updateTemplate.actionName, loadUpdateTemplate);
}

function* watchUpdateStatusTemplate() {
  yield takeLatest(updateTemplateStatus.actionName, loadUpdateStatusTemplate);
}

function* watchAddTemplateSection() {
  yield takeLatest(addTemplateSection.actionName, loadAddTemplateSection);
}

function* watchDeleteTemplate() {
  yield takeLatest(deleteTemplate.actionName, loadDeleteTemplate);
}

function* watchGetTemplate() {
  yield takeLatest(fetchOneTemplate.actionName, loadGetTemplate);
}

function* watchAddFormUploads() {
  yield takeLatest(addFormUploads.actionName, loadAddFormUploads);
}

function* watchAddBase64Uploads() {
  yield takeLatest(addBase64Uploads.actionName, loadAddBase64Uploads);
}

function* watchDeleteFormUploads() {
  yield takeLatest(deleteFormUploads.actionName, loadDeleteFormUploads);
}

export function* loadTemplatesOnChange() {
  const templates = yield select(state => state.templates);
  const { total, skip, limit } = templates;
  if (skip && skip >= total) {
    yield call(fetchTemplatesData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchTemplatesData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchTemplatesOnChange() {
  yield takeLatest(
    [deleteTemplate.requestTypes.SUCCESS, addTemplate.requestTypes.SUCCESS],
    loadTemplatesOnChange
  );
}

export default {
  watchFetchTemplates,
  watchAddTemplate,
  watchGetTemplate,
  watchUpdateTemplate,
  watchAddTemplateSection,
  watchTemplatesOnChange,
  watchDeleteTemplate,
  watchAddFormUploads,
  watchDeleteFormUploads,
  watchAddBase64Uploads,
  watchUpdateStatusTemplate,
};
