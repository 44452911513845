import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchDwellings,
  fetchOneDwelling,
  addDwelling,
  updateDwelling,
  deleteDwelling,
} from 'actions/dwellings';
import {
  fetchAllDwellingsApi,
  fetchOneDwellingApi,
  addDwellingApi,
  updateDwellingApi,
  deleteDwellingApi,
} from 'services/dwellings';
import fetchEntity from './fetch-entity';

const fetchDwellingsData = fetchEntity.bind(null, fetchDwellings.actions, fetchAllDwellingsApi);

const fetchDwellingData = fetchEntity.bind(null, fetchOneDwelling.actions, fetchOneDwellingApi);

const fetchAddDwelling = fetchEntity.bind(null, addDwelling.actions, addDwellingApi);

const fetchUpdateDwelling = fetchEntity.bind(null, updateDwelling.actions, updateDwellingApi);

const fetchDeleteDwelling = fetchEntity.bind(null, deleteDwelling.actions, deleteDwellingApi);

export function* loadFetchDwellings({ params }) {
  yield call(fetchDwellingsData, { ...params });
}

export function* loadGetDwelling({ params }) {
  yield put(fetchOneDwelling.actions.failure({}, undefined));
  yield call(fetchDwellingData, params);
}

export function* loadAddDwelling({ params }) {
  yield call(fetchAddDwelling, params);
}

export function* loadUpdateDwelling({ params }) {
  yield call(fetchUpdateDwelling, params);
}

export function* loadDeleteDwelling({ params }) {
  yield call(fetchDeleteDwelling, { ...params });
}

function* watchFetchDwellings() {
  yield takeLatest(fetchDwellings.actionName, loadFetchDwellings);
}

function* watchAddDwelling() {
  yield takeLatest(addDwelling.actionName, loadAddDwelling);
}

function* watchUpdateDwelling() {
  yield takeLatest(updateDwelling.actionName, loadUpdateDwelling);
}

function* watchDeleteDwelling() {
  yield takeLatest(deleteDwelling.actionName, loadDeleteDwelling);
}

function* watchGetDwelling() {
  yield takeLatest(fetchOneDwelling.actionName, loadGetDwelling);
}

export function* loadDwellingsOnChange() {
  const dwellings = yield select(state => state.dwellings);
  const { total, skip, limit } = dwellings;
  if (skip && skip >= total) {
    yield call(fetchDwellingsData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchDwellingsData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchDwellingsOnChange() {
  yield takeLatest([deleteDwelling.requestTypes.SUCCESS], loadDwellingsOnChange);
}

export default {
  watchFetchDwellings,
  watchAddDwelling,
  watchGetDwelling,
  watchUpdateDwelling,
  watchDwellingsOnChange,
  watchDeleteDwelling,
};
