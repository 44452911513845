import { makeRequestAction } from './index';

/**
 * Fetch reports-history
 */
export const fetchReportsHistory = makeRequestAction('FETCH_REPORTS_HISTORY', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addReportHistory = makeRequestAction('ADD_REPORT_HISTORY', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateReportHistory = makeRequestAction('UPDATE_REPORT_HISTORY', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneReportHistory = makeRequestAction('GET_REPORT_HISTORY', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteReportHistory = makeRequestAction('DELETE_REPORT_HISTORY', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
