import { makeRequestAction } from 'actions/index';
import { makeAction } from './index';

export const notifyUser = makeRequestAction('NOTIFY_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const showModal = makeAction('SHOW_MODAL');
export const closeModal = makeAction('CLOSE_MODAL');
