import React, { useState } from 'react';
import { Button, Input } from 'components';
import Highlighter from 'react-highlight-words';
import SearchFilterWrapper from './wrappers/SearchFilterWrapper';

export default function useSearchFilter(type, subType) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState();

  const addColumnSearch = () => {
    const getColumnSearchProps = (dataIndex, secondIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilterWrapper>
          <Input
            ref={node => {
              setSearchInput(node);
            }}
            placeholder={`Search ${dataIndex.split('.')[0]}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          />
          <div className={'buttons-wrapper'}>
            <Button type={'primary'} onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}>
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)}>Reset</Button>
          </div>
        </SearchFilterWrapper>
      ),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput && searchInput.select());
        }
      },
      render: text =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text && text.toString()}
          />
        ) : (
          text
        ),
    });

    const handleSearch = (selectedKeys, confirm, searchedIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(searchedIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    return getColumnSearchProps;
  };

  return addColumnSearch(type, subType);
}
