import styled from 'styled-components';
import { Input } from 'antd';

const { Password: password } = Input;

const Password = styled(password)`
  .ant-input {
    border: 1px solid #e5e5e5 !important;
    border-radius: 5px !important;
    background-color: #f1f1f1 !important;

    &:focus {
      background-color: #fff !important;
    }
  }
`;

/** @component */
export default Password;
