import { fetchBrokers, fetchOneBroker, addBroker, updateBroker } from 'actions/brokers';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getBrokers = state => state.brokers;
export const getBrokersState = createSelector(getBrokers, data => {
  if (!data.brokers) {
    return [];
  }
  if (data.brokers.data) {
    return data.brokers;
  }
  const brokersData = Object.entries(data.brokers).map(c => c[1]);
  return { total: brokersData.length, data: brokersData };
});

export const getSuccessState = createSelector(getBrokers, data => data.isSuccessful);
export const getSelectedBroker = createSelector(getBrokers, data => data.selectedBroker);

export const brokersValuesLoading = createLoadingSelector(fetchBrokers.actionName)();
export const brokersValuesError = createErrorSelector(fetchBrokers.actionName)();

export const getAddBrokerLoading = createLoadingSelector(addBroker.actionName)();
export const getAddBrokerError = createErrorSelector(addBroker.actionName)();

export const updateBrokerLoading = createLoadingSelector(updateBroker.actionName)();
export const updateBrokerError = createErrorSelector(updateBroker.actionName)();

export const getSelectedBrokerLoading = createLoadingSelector(fetchOneBroker.actionName)();
export const getSelectedBrokerError = createErrorSelector(fetchOneBroker.actionName)();
