import styled, { css } from 'styled-components';
import { Collapse as collapse } from 'antd';

const Collapse = styled(collapse)`
  border: none !important;
  margin-bottom: 4px;
  .ant-collapse-item {
    margin: 0 12px;
    border: none !important;
    & > .ant-collapse-header {
      min-height: 45px;
      position: relative;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      font-weight: 600;
      .ant-collapse-arrow {
        color: #b7b2b2;
        margin-left: 4px;
        right: 16px;
        left: auto;
        border: 1px solid #d9d9d9;
        padding: 4px;
        border-radius: 50%;
      }
      .progress {
        color: #b7b2b2;
        margin-right: 8px;
      }
    }
  }
  .ant-collapse-content {
    border: none !important;
  }
  .panel-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 12px 0 12px 16px;
  }
  ${props =>
    props.bg &&
    css`
      .ant-collapse-header {
        background: ${props.bg};
      }
    `}
  ${props =>
    props.head_padding_right &&
    css`
      .ant-collapse-header {
        padding-right: ${props.head_padding_right}!important;
      }
    `}
`;

/** @component */
export default Collapse;
