export default (value, prevValue) => {
  if (value && prevValue && value !== prevValue) {
    if (prevValue.length > value.length) {
      return value;
    }
    if (value.match(/^[A-Za-z]/)) {
      if (value.length > 7) {
        return prevValue;
      }
      return value
        .replace(/^([A-Za-z]\d[A-Za-z])$/, '$1 ')
        .replace(
          /^([A-Za-z]\d[A-Za-z]\d)$/,
          (origin, firstMatch) => `${firstMatch.slice(0, 3)} ${firstMatch.slice(3)}`
        );
    }
    if (value.length > 10) {
      return prevValue;
    }
    return value
      .replace(/^(\d{5})$/g, '$1-')
      .replace(
        /^(\d{6})$/g,
        (origin, firstMatch) => `${firstMatch.slice(0, 5)}-${firstMatch.slice(5)}`
      );
  }
  return value;
};
