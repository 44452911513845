import { fetchCoverLetter, addCoverLetter, updateCoverLetter } from 'actions/cover-letters';
import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getCoverLetters = state => state.coverLetters;
export const getCoverLetter = createSelector(getCoverLetters, data =>
  data.coverLetter && data.coverLetter.data ? data.coverLetter.data[0] : {}
);
export const getSuccessState = createSelector(getCoverLetters, data => data.isSuccessful);

export const updateCoverLetterLoading = createLoadingSelector(updateCoverLetter.actionName)();
export const updateCoverLetterError = createErrorSelector(updateCoverLetter.actionName)();

export const addCoverLettertLoading = createLoadingSelector(addCoverLetter.actionName)();
export const addCoverLetterError = createErrorSelector(addCoverLetter.actionName)();

export const getCoverLetterLoading = createLoadingSelector(fetchCoverLetter.actionName)();
export const getCoverLetterError = createErrorSelector(fetchCoverLetter.actionName)();
