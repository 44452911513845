import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { Button, FormItem, Input } from '../index';

const PhoneNumbers = ({ form, contact = null }) => {
  const { getFieldDecorator } = form;
  const [phoneNumbers, setPhoneNumbers] = useState([]);

  useEffect(() => {
    if (contact && contact.contact_phone_numbers) {
      setPhoneNumbers(contact.contact_phone_numbers);
    }
  }, [contact]);

  const handleAddPhoneNumber = () => {
    setPhoneNumbers([
      ...phoneNumbers,
      {
        phone: '',
      },
    ]);
  };

  // const handleRemovePhoneNumber = () => {};

  return (
    <Card
      title='Phone numbers'
      style={{ marginTop: 20 }}
      extra={<Button onClick={() => handleAddPhoneNumber()}>Add</Button>}
    >
      <Row>
        {phoneNumbers &&
          phoneNumbers.map((phoneNumber, i) => (
            <Col key={phoneNumber.id}>
              <FormItem label='Phone'>
                {getFieldDecorator(`contact_phone_numbers[${i}][phone]`, {
                  initialValue: phoneNumber && phoneNumber.phone,
                  rules: [
                    {
                      pattern: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                      message: 'Please input valid phone number',
                    },
                  ],
                })(<Input autocomplete='new-password' />)}
              </FormItem>

              {phoneNumber && phoneNumber.id && (
                <FormItem label='' style={{ display: 'none' }}>
                  {getFieldDecorator(`contact_phone_numbers[${i}][id]`, {
                    initialValue: phoneNumber ? phoneNumber.id : '',
                  })(<input type='hidden' autocomplete='new-password' />)}
                </FormItem>
              )}

              {/* <Button type={'warning'} onClick={() => handleRemovePhoneNumber()}> */}
              {/*  Delete */}
              {/* </Button> */}
            </Col>
          ))}
      </Row>
    </Card>
  );
};

export default PhoneNumbers;
