import { useState, useEffect } from 'react';
import { MOBILE_BREAKPOINT } from 'configs/env-vars';

const useBreakpoint = (breakpoint = MOBILE_BREAKPOINT) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint);

  useEffect(() => {
    const resizeListener = () => {
      if (window.innerWidth < breakpoint) {
        setIsMobile(true);
      }
      if (window.innerWidth > breakpoint) {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isMobile;
};

export default useBreakpoint;
