import {
  fetchContacts,
  fetchOneContact,
  addContact,
  updateContact,
  deleteContact,
} from 'actions/contacts';

const contacts = () => {
  const initialState = {
    contacts: null,
    selectedContact: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchContacts.requestTypes.SUCCESS:
        return {
          ...state,
          contacts: response,
          isSuccessful: false,
        };
      case addContact.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addContact.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addContact.requestTypes.SUCCESS:
        return {
          ...state,
          selectedContact: response,
          contacts: { ...state.contacts, response },
          isSuccessful: true,
        };
      case updateContact.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateContact.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateContact.requestTypes.SUCCESS:
        return {
          ...state,
          selectedContact: response,
          isSuccessful: true,
        };
      case fetchOneContact.requestTypes.SUCCESS:
        return {
          ...state,
          selectedContact: response,
        };
      case deleteContact.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default contacts();
