import { fetchDwellings, fetchOneDwelling, addDwelling, updateDwelling } from 'actions/dwellings';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getDwellings = state => state.dwellings;
export const getDwellingsState = createSelector(getDwellings, data => {
  if (!data.dwellings) {
    return [];
  }
  if (data.dwellings.data) {
    return data.dwellings;
  }
  const dwellingsData = Object.entries(data.dwellings).map(d => d[1]);
  return { total: dwellingsData.length, data: dwellingsData };
});

export const getSuccessState = createSelector(getDwellings, data => data.isSuccessful);
export const getSelectedDwelling = createSelector(getDwellings, data => data.selectedDwelling);

export const dwellingsValuesLoading = createLoadingSelector(fetchDwellings.actionName)();
export const dwellingsValuesError = createErrorSelector(fetchDwellings.actionName)();

export const getAddDwellingLoading = createLoadingSelector(addDwelling.actionName)();
export const getAddDwellingError = createErrorSelector(addDwelling.actionName)();

export const getUpdateDwellingLoading = createLoadingSelector(updateDwelling.actionName)();
export const getUpdateDwellingError = createErrorSelector(updateDwelling.actionName)();

export const getSelectedDwellingLoading = createLoadingSelector(fetchOneDwelling.actionName)();
export const getSelectedDwellingError = createErrorSelector(fetchOneDwelling.actionName)();
