import { call, takeLatest } from 'redux-saga/effects';
import { getAdvice, updateAdvice } from 'actions/advice';
import { fetchAdviceApi, updateAdviceApi } from 'services/advice';
import fetchEntity from './fetch-entity';

const fetchAdviceEntity = fetchEntity.bind(null, getAdvice.actions, fetchAdviceApi);

const updateAdviceEntity = fetchEntity.bind(null, updateAdvice.actions, updateAdviceApi);

export function* loadFetchAdviceEntity({ params }) {
  yield call(fetchAdviceEntity, { ...params });
}

export function* loadUpdateAdviceEntity({ params }) {
  yield call(updateAdviceEntity, { ...params });
}

function* watchGetAdvice() {
  yield takeLatest(getAdvice.actionName, loadFetchAdviceEntity);
}

function* watchUpdateAdvice() {
  yield takeLatest(updateAdvice.actionName, loadUpdateAdviceEntity);
}

export default {
  watchGetAdvice,
  watchUpdateAdvice,
};
