import { getContract } from 'actions/guest-contract';

const guestContract = () => {
  const initialState = {};

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getContract.requestTypes.SUCCESS:
        return {
          ...response,
        };
      default:
        return state;
    }
  };
};

export default guestContract();
