import React, { useState, useEffect } from 'react';
import { Card, Title, Button } from 'components';
import dompurify from 'dompurify';
import { Typography, Col, Row, Input } from 'antd';
import moment from 'moment';
import FormItem from 'antd/es/form/FormItem';
import SignaturePad from 'react-signature-canvas';
import { addBase64Uploads } from 'actions/templates';
import { useDispatch } from 'react-redux';
import PreviewContentWrapper from './partials/PreviewContentWrapper';

const { Paragraph } = Typography;

const PreviewContent = ({ contractData, setFullName }) => {
  const dispatch = useDispatch();

  const sanitizer = dompurify.sanitize;
  const [content, setContent] = useState();

  const { contract, report, contact, profile } = contractData;

  useEffect(() => {
    console.log(contractData);
    if (contract && contract.content) {
      const newContent = contract.content
        .replace('{client_name}', contact.name)
        .replace('{company_name}', profile.name);
      setContent(newContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  const imageEditor = React.createRef();
  const handleOk = () => {
    const imageEditorInst = imageEditor.current.toDataURL();

    if (imageEditorInst) {
      dispatch(
        addBase64Uploads.request({
          file: imageEditorInst,
          source: 'contractSignature',
          uploadType: 'base64',
          name: 'sign.png',
        })
      );
    }
  };
  const clear = () => {
    imageEditor.current.clear();
  };

  return (
    <>
      {contract && (
        <PreviewContentWrapper>
          <Row className='preview-content'>
            <Col span={24}>
              <Row type='flex' align='center'>
                <Col span={12}>
                  {/* <Title className='preview-content-title' level={1}>
                    Contract: {contract.title}
                  </Title>
                  <p className='preview-content-version'>Version: {contract.version}</p> */}
                  <br />
                  <Paragraph>
                    {report.booking?.address} {moment(report.booking?.date).format('MMMM Do YYYY,')}
                    {report.booking?.time}
                  </Paragraph>
                </Col>
                <Col className='preview-content-date' span={12}>
                  <img src={profile?.logo?.url} height={155} width={155} alt={profile.name} />
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row className='preview-content'>
            <Col span={24}>
              <Row type='flex' align='center'>
                <Col span={19}>
                  <Title className='preview-content-title' level={1}>
                    PARTIES TO THE AGREEMENT
                  </Title>
                </Col>
                <Col className='preview-content-date' span={5}>
                  {moment(report.createdAt).format('lll')}
                </Col>
              </Row>
              <br />
              <Row type='flex' align='center'>
                <Col span={12}>
                  <Title level={2}>Company</Title>
                  <Paragraph style={{ margin: '0' }}>{profile.name}</Paragraph>
                  <Paragraph style={{ margin: '0' }}>{profile.address}</Paragraph>
                </Col>
                <Col span={12}>
                  <Title level={2}>Client</Title>
                  <Paragraph style={{ margin: '0' }}>{contact.name}</Paragraph>
                </Col>
              </Row>
              <Row type='flex' align='center' style={{ marginTop: '10px' }}>
                <Col span={24}>
                  <Title level={2}>Total Fee: ${report.booking?.invoice?.total.toFixed(2)}</Title>
                </Col>
              </Row>
            </Col>
            <Col span={24} className='preview-content-text'>
              <hr />
              <div dangerouslySetInnerHTML={{ __html: sanitizer(content) }} />
              <hr />
              <Paragraph>
                Your confirmation of this agreement constitutes a binding contract. Under most
                circumstances, acceptance of this agreement is necessary prior to execution of the
                inspection.
              </Paragraph>
              <FormItem
                name='name'
                label='Please enter your First and Last Name here'
                rules={[
                  {
                    required: true,
                    message: 'Please fill your First and Last Name!',
                  },
                ]}
              >
                <Input autocomplete='new-password' onChange={e => setFullName(e.target.value)} />
              </FormItem>
              <br />
              <Col span={16}>
                <Card title='Please Sign Here'>
                  <SignaturePad
                    ref={imageEditor}
                    onEnd={data => handleOk(data)}
                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                  />
                  <Button onClick={() => clear()}>Clear</Button>
                </Card>
              </Col>
            </Col>
          </Row>
        </PreviewContentWrapper>
      )}
    </>
  );
};

export default React.memo(PreviewContent);
