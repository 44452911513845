import React, { useEffect, useState } from 'react';
import { DataSection } from 'components/Partials';
import { Button, FormItem, Input, Title, Select } from 'components/index';
import { Col, Form, Row, Spin } from 'antd';
import postalCodeFormatter from 'utils/postalCodeFormatter';
import userFormConfigs from 'constants/userFormConfigs';
import userRoles from 'constants/user-roles';
import { canadaProvinceList, usStateList } from 'constants/etc';
import MediaField from './MediaField';

const { Option } = Select;

const {
  companyNameInput,
  logoInput,
  coverInput,
  roleInput,
  emailInput,
  fullNameInput,
  firstNameInput,
  lastNameInput,
  addressInput,
  cityInput,
  phoneInput,
  websiteInput,
  postalInput,
  contactEmail,
  country,
  provinces,
  states,
} = userFormConfigs;

const UserForm = ({ handleSubmit, form, loading, user = null, actionType = null }) => {
  const { getFieldDecorator } = form;
  const dataMargin = actionType && { top: 0, bottom: 0 };

  const [userCountry, setUserCountry] = useState(true);
  useEffect(() => {
    form.setFieldsValue({ province: user?.profile?.province });
    setUserCountry(user?.profile?.country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.profile?.country]);

  return (
    <Spin spinning={!!loading}>
      <Form layout={'vertical'} labelAlign={'left'} hideRequiredMark onSubmit={handleSubmit}>
        <DataSection {...dataMargin}>
          <Title level={3}>Profile Info</Title>
          <p>{user && user.email}</p>
          <p>{user && `User Type: ${user.role.toUpperCase()}`}</p>

          {!actionType && (
            <FormItem label={fullNameInput.label}>
              {getFieldDecorator(companyNameInput.fieldName, {
                ...companyNameInput.decoratorOptions,
                initialValue: user && user.profile ? user.profile.name : null,
              })(<Input size={'large'} autocomplete='new-password' />)}
            </FormItem>
          )}
          {!actionType && (
            <Row gutter={25}>
              <Col span={12}>
                <FormItem label={logoInput.label}>
                  {getFieldDecorator(logoInput.fieldName, {
                    ...logoInput.decoratorOptions,
                    initialValue: user && user.profile ? user.profile.logo : null,
                  })(<MediaField profile={user && user.profile} field='logo' form={form} />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={coverInput.label}>
                  {getFieldDecorator(coverInput.fieldName, {
                    ...coverInput.decoratorOptions,
                    initialValue: user && user.profile ? user.profile.cover : null,
                  })(<MediaField profile={user && user.profile} field='cover' form={form} />)}
                </FormItem>
              </Col>
            </Row>
          )}

          {actionType && actionType === 'CREATE' && (
            <FormItem label={emailInput.label}>
              {getFieldDecorator(emailInput.fieldName, {
                ...emailInput.decoratorOptions,
                initialValue: user ? user.email : null,
              })(<Input size={'large'} autocomplete='new-password' />)}
            </FormItem>
          )}

          {actionType && (
            <FormItem label={roleInput.label}>
              {getFieldDecorator(roleInput.fieldName, {
                ...roleInput.decoratorOptions,
                initialValue: user ? user.role : userRoles.ADMIN,
              })(
                <Select size={'large'} style={{ width: '100%' }} optionLabelProp='label'>
                  {Object.values(userRoles).map(role => (
                    <Option value={role} label={role} key={role}>
                      {role}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          )}
        </DataSection>

        <DataSection {...dataMargin}>
          <Title level={3}>Personal Info</Title>
          <Row gutter={25}>
            <Col span={12}>
              <FormItem label={firstNameInput.label}>
                {getFieldDecorator(firstNameInput.fieldName, {
                  ...firstNameInput.decoratorOptions,
                  initialValue: user && user.firstName ? user.firstName : null,
                })(<Input size={'large'} autocomplete='new-password' />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={lastNameInput.label}>
                {getFieldDecorator(lastNameInput.fieldName, {
                  ...lastNameInput.decoratorOptions,
                  initialValue: user && user.lastName ? user.lastName : null,
                })(<Input size={'large'} autocomplete='new-password' />)}
              </FormItem>
            </Col>
          </Row>
        </DataSection>
        <DataSection {...dataMargin}>
          <Title level={3}>Address Info</Title>
          <Row gutter={25}>
            <Col span={12}>
              <FormItem label={country.label}>
                {getFieldDecorator(country.fieldName, {
                  ...country.decoratorOptions,
                  initialValue: user?.profile?.country || null,
                })(
                  <Select
                    height='40px'
                    padding='5px 0 0 0 '
                    onChange={el => {
                      setUserCountry(el);
                      form.setFieldsValue({ province: '' });
                    }}
                  >
                    <Option value='United States'>United States</Option>
                    <Option value='Canada'>Canada</Option>
                  </Select>
                )}
              </FormItem>
              <FormItem label={addressInput.label}>
                {getFieldDecorator(addressInput.fieldName, {
                  ...addressInput.decoratorOptions,
                  initialValue: user && user.profile.address ? user.profile.address : null,
                })(<Input autocomplete='new-password' style={{ height: '40px' }} />)}
              </FormItem>
            </Col>
            {/* <Col span={12}>
              <FormItem label={stateInput.label}>
                {getFieldDecorator(stateInput.fieldName, {
                  ...stateInput.decoratorOptions,
                  initialValue: user && user.profile.province ? user.profile.province : null,
                })(<Input size={'large'} autocomplete='new-password' />)}
              </FormItem>
            </Col> */}
            <Col span={12}>
              {userCountry === 'United States' ? (
                <FormItem label={states.label}>
                  {getFieldDecorator(states.fieldName, {
                    ...states.decoratorOptions,
                    initialValue: user?.profile?.province || null,
                  })(
                    <Select height='40px' padding='5px 0 0 0 ' showSearch>
                      {usStateList?.map((el, idx) => (
                        <Option value={el} key={idx}>
                          {el}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              ) : userCountry === 'Canada' ? (
                <FormItem label={provinces.label}>
                  {getFieldDecorator(provinces.fieldName, {
                    ...provinces.decoratorOptions,
                    initialValue: user?.profile?.province || null,
                  })(
                    <Select height='40px' padding='5px 0 0 0 ' showSearch>
                      {canadaProvinceList?.map((el, idx) => (
                        <Option value={el} key={idx}>
                          {el}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              ) : (
                <></>
              )}
            </Col>
            <Col span={12}>
              <FormItem label={cityInput.label}>
                {getFieldDecorator(cityInput.fieldName, {
                  ...cityInput.decoratorOptions,
                  initialValue: user && user.profile.city ? user.profile.city : null,
                })(<Input size={'large'} autocomplete='new-password' />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={postalInput.label}>
                {getFieldDecorator(postalInput.fieldName, {
                  ...postalInput.decoratorOptions,
                  normalize: postalCodeFormatter,
                  initialValue: user && user.profile.postal ? user.profile.postal : null,
                })(
                  <Input size={'large'} autocomplete='new-password' {...postalInput.elementProps} />
                )}
              </FormItem>
            </Col>
          </Row>
        </DataSection>
        <DataSection {...dataMargin}>
          <Title level={3}>Contact Info</Title>
          <Row gutter={25}>
            <Col span={12}>
              <FormItem label={phoneInput.label}>
                {getFieldDecorator(phoneInput.fieldName, {
                  ...phoneInput.decoratorOptions,
                  initialValue:
                    user && user.profile && user.profile.phone ? user.profile.phone : null,
                })(<Input size={'large'} autocomplete='new-password' />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={contactEmail.label}>
                {getFieldDecorator(contactEmail.fieldName, {
                  ...contactEmail.decoratorOptions,
                  initialValue:
                    user && user.profile && user.profile.contactEmail
                      ? user.profile.contactEmail
                      : user?.email,
                })(<Input size={'large'} autocomplete='off' />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={websiteInput.label}>
                {getFieldDecorator(websiteInput.fieldName, {
                  ...websiteInput.decoratorOptions,
                  initialValue:
                    user && user.profile && user.profile.website ? user.profile.website : null,
                })(
                  <Input
                    size={'large'}
                    autocomplete='new-password'
                    {...websiteInput.elementProps}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </DataSection>
        <DataSection {...dataMargin}>
          <Title level={3} mb={5}>
            Preference
          </Title>
          <Row gutter={25}>
            <Col span={12}>
              <FormItem label='Edit report look'>
                {getFieldDecorator('editLook', {
                  initialValue: user?.profile?.editLook || 'Collapsed',
                })(
                  <Select size={'large'} style={{ width: '100%' }} optionLabelProp='label'>
                    {['Collapsed', 'Expanded'].map(el => (
                      <Option value={el} label={el} key={el}>
                        {el}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
        </DataSection>
        {!actionType && (
          <Row>
            <Col span={12}>
              <FormItem>
                <Button block loading={loading} type={'primary'} size={'large'} htmlType={'submit'}>
                  Update
                </Button>
              </FormItem>
            </Col>
          </Row>
        )}
      </Form>
    </Spin>
  );
};

export default UserForm;
