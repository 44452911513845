import { makeRequestAction } from './index';

/**
 * Fetch conditions
 */
export const fetchConditions = makeRequestAction('FETCH_CONDITIONS', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const addCondition = makeRequestAction('ADD_CONDITION', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateCondition = makeRequestAction('UPDATE_CONDITION', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneCondition = makeRequestAction('GET_CONDITION', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteCondition = makeRequestAction('DELETE_CONDITION', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
