import React from 'react';
import { Row, Col } from 'antd';
import { Sidebar, MobileHeader, ResponsiveMenuWrapper } from '.';

const ResponsiveMenu = ({ userData }) => {
  return (
    <ResponsiveMenuWrapper>
      <Row>
        <Col className='sidebar-column' xs={0} md={24}>
          <Sidebar userData={userData} />
        </Col>
        <Col xs={24} md={0}>
          <MobileHeader />
        </Col>
      </Row>
    </ResponsiveMenuWrapper>
  );
};

export default ResponsiveMenu;
