import styled, { css } from 'styled-components';
import { Tag as tag } from 'antd';

const Tag = styled(tag)`
  ${props =>
    props.color &&
    css`
      color: ${props.color} !important;
      border: 1px solid ${props.color} !important;
      background: none !important;
    `}
`;

/** @component */
export default Tag;
