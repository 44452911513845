import { call, takeLatest } from 'redux-saga/effects';
import {
  sendReportEmail as sendReportEmailAction,
  sendApprovalEmail as sendApprovalEmailAction,
  addEmailUploads,
} from 'actions/report-email';
import { sendReportApi, sendApprovalEmailApi } from 'services/report-email';
import { addBase64UploadApi } from 'services/uploads';
import fetchEntity from './fetch-entity';

const sendReportEmail = fetchEntity.bind(null, sendReportEmailAction.actions, sendReportApi);

export function* loadBonds({ params }) {
  yield call(sendReportEmail, { ...params });
}

function* watchSendReportEmail() {
  yield takeLatest(sendReportEmailAction.actionName, loadBonds);
}

const sendApprovalEmail = fetchEntity.bind(
  null,
  sendApprovalEmailAction.actions,
  sendApprovalEmailApi
);

export function* loadSendApprovalEmail({ params }) {
  yield call(sendApprovalEmail, { ...params });
}

function* watchSendApprovalEmail() {
  yield takeLatest(sendApprovalEmailAction.actionName, loadSendApprovalEmail);
}

const fetchAddEmailUploads = fetchEntity.bind(null, addEmailUploads.actions, addBase64UploadApi);

export function* loadAddEmailUploads({ params }) {
  yield call(fetchAddEmailUploads, { ...params });
}

function* watchAddEmailUploads() {
  yield takeLatest(addEmailUploads.actionName, loadAddEmailUploads);
}

export default {
  watchSendReportEmail,
  watchAddEmailUploads,
  watchSendApprovalEmail,
};
