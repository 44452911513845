import {
  fetchBookings,
  fetchOneBooking,
  addBooking,
  updateBooking,
  deleteBooking,
} from 'actions/bookings';

const bookings = () => {
  const initialState = {
    bookings: null,
    selectedBooking: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchBookings.requestTypes.SUCCESS:
        return {
          selectedBooking: null,
          bookings: response,
        };
      case addBooking.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addBooking.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addBooking.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBooking: response,
          isSuccessful: true,
        };
      case updateBooking.requestTypes.REQUEST:
        return {
          ...state,
          isUpdateSuccessful: false,
        };
      case updateBooking.requestTypes.FAILURE:
        return {
          ...state,
          isUpdateSuccessful: false,
        };
      case updateBooking.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBooking: response,
          isUpdateSuccessful: true,
        };
      case fetchOneBooking.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBooking: response,
        };
      case deleteBooking.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default bookings();
