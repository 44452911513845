const bookingForm = () => {
  const initialState = {
    booking: null,
    contact: null,
    broker: null,
    report: null,
    invoice: null,
    slide: 0,
    isSubmitted: false,
    showClients: false,
    showBrokers: false,
    isDone: false,
    clickedStep: null,
  };

  return (state = initialState, { type, params }) => {
    switch (type) {
      case 'CHANGE_SLIDE':
        return {
          ...state,
          ...params,
          isSubmitted: false,
          isDone: false,
          clickedStep: null,
        };
      case 'HANDLE_SUBMIT':
        return {
          ...state,
          isSubmitted: true,
        };
      case 'SHOW_CLIENT_LIST':
        return {
          ...state,
          showClients: true,
        };
      case 'SHOW_BROKERS_LIST':
        return {
          ...state,
          showBrokers: true,
        };
      case 'SHOW_CLIENT_FORM':
        return {
          ...state,
          showClients: false,
          contact: null,
        };
      case 'SHOW_BROKERS_FORM':
        return {
          ...state,
          showBrokers: false,
          broker: null,
        };
      case 'HANDLE_DONE':
        return {
          ...state,
          isDone: true,
        };
      case 'CANCEL_SUBMIT':
        return {
          ...state,
          isSubmitted: false,
          isDone: false,
        };
      case 'ADD_BOOKING_INFO':
        return {
          ...state,
          booking: { ...params },
        };
      case 'ADD_CONTACT_INFO':
        return {
          ...state,
          contact: { ...params },
        };
      case 'ADD_REPORT_INFO':
        return {
          ...state,
          report: { ...params },
        };
      case 'ADD_BROKER_INFO':
        return {
          ...state,
          broker: { ...params },
        };
      case 'ADD_INVOICE':
        return {
          ...state,
          invoice: { ...params },
        };
      case 'OPEN_STEP':
        return {
          ...state,
          clickedStep: params.clickedStep,
          isSubmitted: false,
        };
      case 'CLEAN_ALL':
        return {
          ...initialState,
        };
      default:
        return state;
    }
  };
};

export default bookingForm();
