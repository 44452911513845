import { makeRequestAction } from './index';

/**
 * Fetch services
 */
export const fetchServices = makeRequestAction('FETCH_SERVICES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addService = makeRequestAction('ADD_SERVICE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateService = makeRequestAction('UPDATE_SERVICE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneService = makeRequestAction('GET_SERVICE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteService = makeRequestAction('DELETE_SERVICE', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
