import React from 'react';
import { Col, Icon, Row, Typography } from 'antd';
import { DataSection, SidePhoto } from 'components/Partials';
import { Button, Title } from 'components/index';
import { roles } from 'constants/users';
import { isUserPermitted } from 'configs/isUserPermitted';
import Background from './assets/imgs/signupbg.jpeg';

const { Paragraph } = Typography;

const getRedirectUrl = () => {
  if (isUserPermitted([roles.ROLE_SUPER_ADMIN])) {
    return '/dashboard';
  }
  return isUserPermitted([roles.ROLE_INSPECTOR]) ? '/reports' : '/';
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Row style={{ height: '100vh' }} type='flex' justify='center' align='middle'>
          <Col span={16}>
            <Row>
              <Col span={12} offset={6}>
                <DataSection>
                  <Title level={1}>
                    <Icon type='alert' /> 500
                  </Title>
                  <Paragraph>{''}</Paragraph>
                  <Paragraph>Something went wrong</Paragraph>
                  <Paragraph>
                    <a href={getRedirectUrl()}>
                      <Button>Go to main page</Button>{' '}
                    </a>
                  </Paragraph>
                </DataSection>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <SidePhoto src={Background} />
          </Col>
        </Row>
      );
    }

    return this.props.children;
  }
}

export default React.memo(ErrorBoundary);
