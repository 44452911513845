import {
  fetchLimitations,
  fetchOneLimitation,
  addLimitation,
  updateLimitation,
  deleteLimitation,
} from 'actions/limitations';

const limitations = () => {
  const initialState = {
    limitations: null,
    selectedLimitation: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchLimitations.requestTypes.SUCCESS:
        return {
          selectedLimitation: null,
          limitations: response,
        };
      case addLimitation.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addLimitation.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addLimitation.requestTypes.SUCCESS:
        return {
          ...state,
          selectedLimitation: response,
          isSuccessful: true,
        };
      case updateLimitation.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateLimitation.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateLimitation.requestTypes.SUCCESS:
        return {
          ...state,
          selectedLimitation: response,
          isSuccessful: true,
        };
      case fetchOneLimitation.requestTypes.SUCCESS:
        return {
          ...state,
          selectedLimitation: response,
        };
      case deleteLimitation.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default limitations();
