import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReportsForm } from 'components';
import { Form } from 'antd';
import { getReport, getIsSubmitted, getCurrent } from 'selectors/booking-form';
import { cancelSubmit, addReportInfo } from 'actions/booking-form';

const PropertyInfo = ({ form, handleChangeSlide }) => {
  const dispatch = useDispatch();
  const report = useSelector(getReport);
  const isSubmitted = useSelector(getIsSubmitted);
  const current = useSelector(getCurrent);
  useEffect(() => {
    if (isSubmitted) {
      form.validateFields((err, values) => {
        if (!err) {
          dispatch(addReportInfo.request(values));
          handleChangeSlide(current + 1);
        } else {
          dispatch(cancelSubmit.request());
        }

        return false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted]);

  return (
    <div className='property-info'>
      <ReportsForm form={form} report={report} />
    </div>
  );
};

export default React.memo(Form.create({ name: 'property_info_form' })(PropertyInfo));
