import client from './main-client';

export const fetchAllInspectionTypesApi = params =>
  client().get('/options-inspection-types', { params });
export const fetchOneInspectionTypeApi = ({ id, ...params }) =>
  client().get(`/options-inspection-types/${id}`, { params });
export const updateInspectionTypeApi = params =>
  client().put(`/options-inspection-types/${params.id}`, params.values);
export const addInspectionTypeApi = data => client().post('/options-inspection-types', data);
export const deleteInspectionTypeApi = params =>
  client().delete(`/options-inspection-types/${params.id}`, { params });
