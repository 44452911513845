import { makeRequestAction } from './index';

/**
 * Fetch dwellings-to-users
 */
export const fetchDwellingsToUsers = makeRequestAction('FETCH_DWELLINGS_TO_USERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addDwellingsToUser = makeRequestAction('ADD_DWELLINGS_TO_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateDwellingsToUser = makeRequestAction('UPDATE_DWELLINGS_TO_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneDwellingsToUser = makeRequestAction('GET_DWELLINGS_TO_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteDwellingsToUser = makeRequestAction('DELETE_DWELLINGS_TO_USER', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
