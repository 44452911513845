import {
  fetchInspectionTypes,
  fetchOneInspectionType,
  addInspectionType,
  updateInspectionType,
} from 'actions/inspection-types';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getInspectionTypes = state => state.inspectionTypes;
export const getInspectionTypesState = createSelector(getInspectionTypes, data => {
  if (!data.inspectionTypes) {
    return [];
  }
  if (data.inspectionTypes.data) {
    return data.inspectionTypes;
  }
  const inspetionTypesData = Object.entries(data.inspectionTypes).map(i => i[1]);
  return { total: inspetionTypesData.length, data: inspetionTypesData };
});

export const getSuccessState = createSelector(getInspectionTypes, data => data.isSuccessful);
export const getSelectedInspectionType = createSelector(
  getInspectionTypes,
  data => data.selectedInspectionType
);

export const inspectionTypesValuesLoading = createLoadingSelector(
  fetchInspectionTypes.actionName
)();
export const inspectionTypesValuesError = createErrorSelector(fetchInspectionTypes.actionName)();

export const getAddInspectionTypeLoading = createLoadingSelector(addInspectionType.actionName)();
export const getAddInspectionTypeError = createErrorSelector(addInspectionType.actionName)();

export const getUpdateInspectionTypeLoading = createLoadingSelector(
  updateInspectionType.actionName
)();
export const getUpdateInspectionTypeError = createErrorSelector(updateInspectionType.actionName)();

export const getSelectedInspectionTypeLoading = createLoadingSelector(
  fetchOneInspectionType.actionName
)();
export const getSelectedInspectionTypeError = createErrorSelector(
  fetchOneInspectionType.actionName
)();
