import React, { useEffect, useState } from 'react';
import { Form, Col, Row, List, Checkbox, message, Skeleton } from 'antd';
import { getServicesState, servicesValuesError, servicesValuesLoading } from 'selectors/services';
import { fetchServices } from 'actions/services';
import { useDispatch, useSelector } from 'react-redux';
import { addInvoice } from 'actions/booking-form';
import { fetchOneInvoice } from 'actions/invoices';
import { getSelectedInvoice } from 'selectors/invoices';
import { getInvoice, getIsSubmitted, getBooking, getCurrent } from 'selectors/booking-form';
import InvoiceForm from './InvoiceForm';

const Invoice = ({ handleChangeSlide, mode }) => {
  const dispatch = useDispatch();
  const booking = useSelector(getBooking);
  const current = useSelector(getCurrent);
  const services = useSelector(getServicesState);
  const invoice = useSelector(getInvoice);
  const loading = useSelector(servicesValuesLoading);
  const error = useSelector(servicesValuesError);
  const selectedInvoice = useSelector(getSelectedInvoice);
  const isSubmitted = useSelector(getIsSubmitted);

  const [selectedServices, setSelectedServices] = useState([]);
  const [additionalFeePrice, setAdditionalFeePrice] = useState(0);
  const [additionalFeeDesc, setAdditionalFeeDesc] = useState();
  const [travelFee, setTravelFee] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [hst, setHst] = useState(0);
  const [additionalHst, setAdditionalHst] = useState(0);
  const [travelHst, setTravelHst] = useState(0);

  useEffect(() => {
    if (booking && booking.invoiceId) {
      dispatch(fetchOneInvoice.request({ id: booking.invoiceId }));
    }
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (isSubmitted) {
      const values = {
        additionalFee: {
          description: additionalFeeDesc,
          price: additionalFeePrice,
          hst: additionalHst,
        },
        travelFee: {
          price: travelFee,
          hst: travelHst,
        },
        total,
        subtotal,
        hst,
        services: selectedServices,
      };

      dispatch(addInvoice.request(values));
      handleChangeSlide(current + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted]);

  useEffect(() => {
    dispatch(fetchServices.request({ getAll: true, '$sort[name]': 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!loading && error) {
      message.error(error.message || 'Error');
    }
  }, [error, loading]);

  useEffect(() => {
    let subtotalSum = +additionalFeePrice + +travelFee;
    let hstSum = 0;
    let totalSum = +additionalFeePrice + additionalHst + +travelFee + travelHst;
    if (selectedServices.length) {
      selectedServices.forEach(s => {
        subtotalSum += s.price;
        hstSum += (s.price * s.tax) / 100;
      });

      totalSum = subtotalSum + hstSum;
    }

    setHst(hstSum);
    setSubtotal(subtotalSum);
    setTotal(totalSum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServices]);

  useEffect(() => {
    if (invoice) {
      setSelectedServices(invoice.services || []);
      setTotal(invoice.total || 0);
      setHst(invoice.hst || 0);
      setSubtotal(invoice.subtotal || 0);
      setAdditionalFeeDesc(invoice.additionalFee && invoice.additionalFee.description);
      setAdditionalFeePrice((invoice.additionalFee && invoice.additionalFee.price) || 0);
      setAdditionalHst((invoice.additionalFee && invoice.additionalFee.hst) || 0);
      setTravelFee((invoice.travelFee && invoice.travelFee.price) || 0);
      setTravelHst((invoice.travelFee && invoice.travelFee.hst) || 0);
    } else if (selectedInvoice) {
      setSelectedServices(
        (selectedInvoice.service_to_invoices &&
          selectedInvoice.service_to_invoices.map(s => {
            return { ...s.service, price: s.customPrice };
          })) ||
          []
      );
      setTotal(selectedInvoice.total || 0);
      setHst(selectedInvoice.hst || 0);
      setSubtotal(selectedInvoice.subtotal || 0);
      setAdditionalFeeDesc(
        selectedInvoice.additionalFee && selectedInvoice.additionalFee.description
      );
      setAdditionalFeePrice(
        (selectedInvoice.additionalFee && selectedInvoice.additionalFee.price) || 0
      );
      setAdditionalHst((selectedInvoice.additionalFee && selectedInvoice.additionalFee.hst) || 0);
      setTravelFee((selectedInvoice.travelFee && selectedInvoice.travelFee.price) || 0);
      setTravelHst((selectedInvoice.travelFee && selectedInvoice.travelFee.hst) || 0);
    }
  }, [invoice, selectedInvoice]);

  const handleChange = (e, item) => {
    if (e.target.checked) {
      setSelectedServices([...selectedServices, item]);
    } else {
      setSelectedServices([...selectedServices.filter(s => s.id !== item.id)]);
    }
  };

  const handleAddFeeChange = (price, description) => {
    if (description !== null) {
      return setAdditionalFeeDesc(description);
    }
    if (price) {
      let addHst = 0;
      if (price > 0) {
        addHst = (+price * 13) / 100;
      }
      const subtotalSum = subtotal - +additionalFeePrice + +price;
      const totalSum = subtotalSum + hst + addHst + travelHst;
      setSubtotal(subtotalSum);
      setTotal(totalSum);
      setAdditionalFeePrice(+price);
      setAdditionalHst(addHst);
    }
    return false;
  };

  const handleTravelChange = price => {
    if (price) {
      let travelFeeHst = 0;
      if (price > 0) {
        travelFeeHst = (+price * 13) / 100;
      }
      const subtotalSum = subtotal - +travelFee + +price;
      const totalSum = subtotalSum + hst + travelFeeHst + additionalHst;
      setSubtotal(subtotalSum);
      setTotal(totalSum);
      setTravelFee(+price);
      setTravelHst(travelFeeHst);
    }
    return false;
  };

  useEffect(() => {
    if (mode === 'create') {
      setSelectedServices([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row gutter={16} className='invoice'>
      <Col xs={24} md={12}>
        <div className='service-list'>
          <div className='gradient' />
          <List
            size='large'
            loading={loading}
            header={false}
            footer={false}
            dataSource={(services && services.data) || []}
            renderItem={item => (
              <List.Item
                className={
                  selectedServices.filter(s => s.id === item.id).length
                    ? 'service-list-item active'
                    : 'service-list-item'
                }
              >
                <Skeleton avatar title={false} loading={item.loading} active>
                  <Checkbox
                    checked={selectedServices.filter(s => s.id === item.id).length}
                    onChange={e => handleChange(e, item)}
                  >
                    {item.name}
                  </Checkbox>
                  <div className='service-price'>
                    {`$ ${item.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </div>
                </Skeleton>
              </List.Item>
            )}
          />
        </div>
      </Col>
      <Col xs={24} md={12} className='invoice-form'>
        <InvoiceForm
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
          additionalFeePrice={additionalFeePrice}
          additionalFeeDesc={additionalFeeDesc}
          travelFee={travelFee}
          handleChange={handleAddFeeChange}
          handleTravelChange={handleTravelChange}
          subtotal={subtotal}
          hst={hst}
          additionalHst={additionalHst}
          travelHst={travelHst}
          total={total}
        />
      </Col>
    </Row>
  );
};

export default Form.create({ name: 'invoice' })(Invoice);
