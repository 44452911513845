import {
  fetchDwellingsToUsers,
  fetchOneDwellingsToUser,
  addDwellingsToUser,
  updateDwellingsToUser,
} from 'actions/dwellings-to-users';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getDwellingsToUsers = state => state.dwellingsToUsers;
export const getDwellingsToUsersState = createSelector(getDwellingsToUsers, data => {
  if (!data.dwellingsToUsers) {
    return [];
  }
  if (data.dwellingsToUsers.data) {
    return data.dwellingsToUsers;
  }
  const dwellingsToUsersData = Object.entries(data.dwellingsToUsers).map(d => d[1]);
  return { total: dwellingsToUsersData.length, data: dwellingsToUsersData };
});

export const getSuccessState = createSelector(getDwellingsToUsers, data => data.isSuccessful);
export const getSelectedDwellingsToUser = createSelector(
  getDwellingsToUsers,
  data => data.selectedDwellingsToUser
);

export const dwellingsToUsersValuesLoading = createLoadingSelector(
  fetchDwellingsToUsers.actionName
)();
export const dwellingsToUsersValuesError = createErrorSelector(fetchDwellingsToUsers.actionName)();

export const getAddDwellingsToUserLoading = createLoadingSelector(addDwellingsToUser.actionName)();
export const getAddDwellingsToUserError = createErrorSelector(addDwellingsToUser.actionName)();

export const getUpdateDwellingsToUserLoading = createLoadingSelector(
  updateDwellingsToUser.actionName
)();
export const getUpdateDwellingsToUserError = createErrorSelector(
  updateDwellingsToUser.actionName
)();

export const getSelectedDwellingsToUserLoading = createLoadingSelector(
  fetchOneDwellingsToUser.actionName
)();
export const getSelectedDwellingsToUserError = createErrorSelector(
  fetchOneDwellingsToUser.actionName
)();
