import { getAdvice, updateAdvice } from 'actions/advice';

const advice = () => {
  const initialState = {
    advice: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getAdvice.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case getAdvice.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case getAdvice.requestTypes.SUCCESS:
        return {
          ...state,
          advice: response,
          isSuccessful: true,
        };

      case updateAdvice.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateAdvice.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateAdvice.requestTypes.SUCCESS:
        return {
          ...state,
          advice: response,
          isSuccessful: true,
        };

      default:
        return state;
    }
  };
};

export default advice();
