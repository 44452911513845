export const protectedRouts = {
  appRep: {
    pathname: '/app-rep/',
    component: 'AppRep',
  },
  dashboard: {
    name: 'Dashboard',
    icon: 'dashboard',
    key: 'dashboard',
    pathname: '/dashboard',
    component: 'Dashboard',
  },
  editAppReport: {
    name: 'Edit app report',
    absPathname: '/appreports/edit/:id',
    pathname: '/appreports/edit/',
    key: '/appreports/edit/',
    showInSideBar: false,
    component: 'Reports/EditReportPage',
  },
  calendar: {
    name: 'Calendar',
    icon: 'calendar',
    key: 'calendar',
    pathname: '/',
    component: 'DashboardPage',
  },
  reports: {
    name: 'Reports',
    icon: 'profile',
    key: 'reports',
    pathname: '/reports',
    component: 'Reports/ReportsPage',
    items: {
      list: {
        name: 'In Progress / Upcoming',
        key: '/reports/list',
        pathname: '/reports',
        showInSideBar: true,
        component: 'Reports/ReportsPage',
      },
      listByInspector: {
        name: 'All reports',
        key: '/reports/list/:id',
        pathname: '/reports/list/',
        showInSideBar: false,
        component: 'Reports/ReportsPage',
      },
      edit: {
        name: 'Edit report',
        absPathname: '/reports/edit/:id',
        pathname: '/reports/edit/',
        key: '/reports/edit/',
        showInSideBar: false,
        component: 'Reports/EditReportPage',
      },
      preview: {
        name: 'Preview report',
        absPathname: '/reports/preview/:id',
        pathname: '/reports/preview/',
        key: '/reports/preview/',
        showInSideBar: false,
        component: 'Reports/ReportPreview/ReportPreview',
      },
      pdf: {
        name: 'Preview report',
        absPathname: '/reports/pdf/:id',
        pathname: '/reports/pdf/',
        key: '/reports/pdf/',
        showInSideBar: false,
        component: 'Reports/ReportPdf/index',
      },
      create: {
        name: 'Add report',
        pathname: '/reports/create',
        key: '/reports/create',
        showInSideBar: false,
      },
      // archive: {
      //   name: 'Archived',
      //   pathname: '/reports/archive',
      //   key: '/reports/archive',
      //   showInSideBar: true,
      //   component: 'Reports/ArchivePage',
      // },
      completed: {
        name: 'Completed',
        pathname: '/reports/completed',
        key: '/reports/completed',
        showInSideBar: true,
        component: 'Reports/CompletedPage',
      },
      canceled: {
        name: 'Canceled',
        pathname: '/reports/canceled',
        key: '/reports/canceled',
        showInSideBar: true,
        component: 'Reports/CanceledPage',
      },
    },
  },
  invoices: {
    name: 'Invoices',
    icon: 'book',
    key: 'invoices',
    pathname: '/invoices',
    component: 'Invoices/InvoicesPage',
    items: {
      edit: {
        name: 'Edit invoice',
        absPathname: '/invoices/edit/:id',
        pathname: '/invoices/edit/',
        key: '/invoices/edit/',
        showInSideBar: false,
        component: 'Invoices/EditInvoicePage',
      },
    },
  },
  contacts: {
    name: 'Clients',
    icon: 'contacts',
    key: 'contacts',
    pathname: '/contacts',
    component: 'Contacts/ContactsPage',
    items: {
      edit: {
        name: 'Edit Client',
        absPathname: '/contacts/edit/:id',
        pathname: '/contacts/edit/',
        component: 'Contacts/EditContactPage',
      },
      create: {
        name: 'Add Client',
        pathname: '/contacts/create',
        component: 'Contacts/AddContactPage',
      },
    },
  },
  brokers: {
    name: 'Brokers',
    icon: 'bank',
    key: 'brokers',
    pathname: '/brokers',
    component: 'Brokers/BrokersPage',
    items: {
      edit: {
        name: 'Edit Broker',
        absPathname: '/brokers/edit/:id',
        pathname: '/brokers/edit/',
        component: 'Brokers/EditBrokerPage',
      },
      create: {
        name: 'Add Broker',
        pathname: '/brokers/create',
        component: 'Brokers/AddBrokerPage',
      },
    },
  },
  templates: {
    name: 'Templates',
    key: 'templates',
    pathname: '/templates',
    icon: 'file-protect',
    component: 'TemplateBuilder',
    items: {
      edit: {
        name: 'Edit Template',
        absPathname: '/templates/edit/:id',
        pathname: '/templates/edit/',
        component: 'TemplateBuilder/EditTemplate',
      },
      create: {
        name: 'Add Template',
        pathname: '/templates/create',
      },
    },
  },
  contracts: {
    name: 'Contracts',
    key: 'contracts',
    pathname: '/contracts',
    icon: 'file-protect',
    component: 'Contracts/ContractsPage',
    items: {
      edit: {
        name: 'Edit Contract',
        absPathname: '/contracts/edit/:id',
        pathname: '/contracts/edit/',
        component: 'Contracts/EditContractPage',
      },
      create: {
        name: 'Add Contract',
        pathname: '/contracts/create',
      },
      preview: {
        name: 'Preview Contract',
        absPathname: '/contracts/preview/:id',
        pathname: '/contracts/preview/',
        key: '/contracts/preview/',
        component: 'Contracts/ContractPreview',
      },
    },
  },

  configuration: {
    name: 'Configuration',
    icon: 'setting',
    key: 'configuration ',
    pathname: '/configuration ',
    items: {
      services: {
        name: 'Services',
        icon: 'services',
        key: 'services',
        pathname: '/services',
        showInSideBar: true,
        component: 'Settings/Services',
      },
      propertyTypesUser: {
        name: 'Property Types',
        key: 'property-types-user',
        pathname: '/property-types-user',
        showInSideBar: true,
        component: 'Settings/PropertyTypesUser',
      },
      inspectionTypesUser: {
        name: 'Inspection Types',
        key: 'inspection-types-user',
        pathname: '/inspection-types-user',
        showInSideBar: true,
        component: 'Settings/InspectionTypesUser',
      },
      dwellingsUser: {
        name: 'Dwellings',
        key: 'dwellings-user',
        pathname: '/dwellings-user',
        showInSideBar: true,
        component: 'Settings/DwellingsUser',
      },
      recommendationsUser: {
        name: 'Recommendations',
        key: 'recommendations-user',
        pathname: '/recommendations-user',
        showInSideBar: true,
        component: 'Settings/RecommendationsUser',
      },
      limitationsUser: {
        name: 'Limitations',
        key: 'limitations-user',
        pathname: '/limitations-user',
        showInSideBar: true,
        component: 'Settings/LimitationsUser',
      },
      summary: {
        name: 'Summary',
        key: 'summary',
        pathname: '/summary',
        showInSideBar: true,
        component: 'Configurations/Summary',
      },
    },
  },

  settings: {
    name: 'Settings',
    icon: 'setting',
    key: '/settings',
    pathname: '/settings',
    items: {
      profile: {
        name: 'Profile',
        icon: 'profile',
        key: '/profile',
        pathname: '/profile',
        showInSideBar: true,
        component: 'Settings/Profile',
      },
      thankyouletter: {
        name: 'Thank You Letter',
        key: '/thankyouletter',
        absPathname: '/thankyouletter',
        pathname: '/thankyouletter',
        showInSideBar: true,
        component: 'Settings/ThankYouLetter',
      },
      users: {
        name: 'Users',
        icon: 'users',
        key: '/users',
        pathname: '/users',
        showInSideBar: true,
        component: 'Settings/Users',
        edit: {
          absPathname: '/users/:id',
          pathname: '/users/',
        },
        create: {
          pathname: '/users/create',
        },
      },
      security: {
        icon: 'Security',
        name: 'Change password',
        pathname: '/security',
        key: '/security',
        showInSideBar: true,
        component: 'Settings/Security',
      },
      accounts: {
        name: 'Accounts',
        icon: 'accounts',
        key: '/accounts',
        pathname: '/accounts',
        showInSideBar: true,
        component: 'Settings/Accounts',
      },
      billings: {
        name: 'Billing',
        icon: 'billing',
        key: '/billing',
        pathname: '/billing',
        showInSideBar: true,
        component: 'Settings/Billings',
      },
      adviceNote: {
        name: "Inspector's Executive Summary",
        key: '/advice',
        pathname: '/advice',
        showInSideBar: true,
        component: 'Settings/AdviceNote',
      },
      editAccount: {
        name: 'Edit Accounts',
        absPathname: '/accounts/edit/:id',
        pathname: '/accounts/edit/',
        component: 'Settings/Accounts/EditAccountPage',
      },
      accountUsers: {
        name: 'Users',
        absPathname: '/accounts/users/:id',
        pathname: '/accounts/users/',
        component: 'Settings/Accounts/AccountUsers',
      },
    },
  },

  configs: {
    name: 'Configs',
    key: 'configs',
    pathname: '/configs',
    icon: 'database-outlined',
    items: {
      packages: {
        name: 'Packages',
        key: '/packages',
        pathname: '/packages',
        showInSideBar: true,
        component: 'Configs/Packages',
      },
      inspectionTypes: {
        name: 'Inspection Types',
        key: '/inspection-types',
        pathname: '/inspection-types',
        showInSideBar: true,
        component: 'Configs/InspectionTypes',
      },
      propertyTypes: {
        name: 'Property Types',
        key: '/property-types',
        pathname: '/property-types',
        showInSideBar: true,
        component: 'Configs/PropertyTypes',
      },
      limitations: {
        name: 'Limitations',
        key: '/limitations',
        pathname: '/limitations',
        showInSideBar: true,
        component: 'Configs/Limitations',
      },
      conditions: {
        name: 'Conditions',
        key: '/conditions',
        pathname: '/conditions',
        showInSideBar: true,
        component: 'Configs/Conditions',
      },
    },
  },
};

const routes = {
  signUp: {
    pathname: '/sign-up',
    component: 'Authorization/SignUp',
  },
  notFound: {
    pathname: '/404',
  },
  signIn: {
    pathname: '/sign-in',
    component: 'Authorization/SignIn',
  },
  forgotPassword: {
    pathname: '/forgot-password',
    component: 'Authorization/ForgotPassword',
  },
  resetPassword: {
    pathname: '/reset/:token',
    component: 'Authorization/ResetPassword',
  },
  thankYou: {
    pathname: '/thank-you/:email',
    component: 'Authorization/ThankYouPage',
  },
  notVerified: {
    pathname: '/not-verified',
    component: 'Authorization/VerifyAccountPage',
  },
  verifyAccount: {
    pathname: '/verify-account/:token',
    component: 'Authorization/VerifyAccountPage',
  },
  guestReport: {
    pathname: '/guest-report/:token',
    component: 'GuestReport',
  },
  termsAndConditions: {
    pathname: '/terms-and-conditions',
    component: 'TermsAndConditions',
  },
  subUserVerifyAccount: {
    pathname: '/sub-user-verify-account/:token',
    component: 'Authorization/SubUserVerifyPage',
  },
  appRep: {
    pathname: '/app-rep/',
    component: 'AppRep',
  },
};

export default routes;
