import { makeRequestAction } from './index';

/**
 * Fetch contacts
 */
export const fetchContacts = makeRequestAction('FETCH_CONTACTS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addContact = makeRequestAction('ADD_CONTACT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateContact = makeRequestAction('UPDATE_CONTACT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneContact = makeRequestAction('GET_CONTACT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteContact = makeRequestAction('DELETE_CONTACT', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
