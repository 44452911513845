import { makeRequestAction } from './index';

/**
 * Fetch brokers
 */
export const fetchBrokers = makeRequestAction('FETCH_BROKERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addBroker = makeRequestAction('ADD_BROKER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateBroker = makeRequestAction('UPDATE_BROKER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneBroker = makeRequestAction('GET_BROKER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteBroker = makeRequestAction('DELETE_BROKER', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
