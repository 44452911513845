import { fetchServices, fetchOneService, addService, updateService } from 'actions/services';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getServices = state => state.services;
export const getServicesState = createSelector(getServices, data => {
  if (!data.services) {
    return [];
  }
  if (data.services.data) {
    return data.services;
  }
  const servicesData = Object.entries(data.services).map(s => s[1]);
  return { total: servicesData.length, data: servicesData };
});

export const getSuccessState = createSelector(getServices, data => data.isSuccessful);
export const getSelectedService = createSelector(getServices, data => data.selectedService);

export const servicesValuesLoading = createLoadingSelector(fetchServices.actionName)();
export const servicesValuesError = createErrorSelector(fetchServices.actionName)();

export const getAddServiceLoading = createLoadingSelector(addService.actionName)();
export const getAddServiceError = createErrorSelector(addService.actionName)();

export const getUpdateServiceLoading = createLoadingSelector(updateService.actionName)();
export const getUpdateServiceError = createErrorSelector(updateService.actionName)();

export const getSelectedServiceLoading = createLoadingSelector(fetchOneService.actionName)();
export const getSelectedServiceError = createErrorSelector(fetchOneService.actionName)();
