import { makeRequestAction, makeAction } from './index';

/**
 * Fetch reports
 */
export const fetchReports = makeRequestAction('FETCH_REPORTS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addReport = makeRequestAction('ADD_REPORT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateReport = makeRequestAction('UPDATE_REPORT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneReport = makeRequestAction('GET_REPORT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteReport = makeRequestAction('DELETE_REPORT', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});

export const resentReport = makeRequestAction('RESENT_REPORT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const setRecommendation = makeRequestAction('SET_RECOMMENDATION', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteRecommendation = makeRequestAction('DELETE_RECOMMENDATION', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const closeSidebar = makeAction('CLOSE_SIDEBAR');

export const clearAuthoClose = makeAction('CLEAR_AUTO_CLOSE');
