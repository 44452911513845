import { API_ROOT } from 'configs/env-vars';
import { makeRequestAction } from './index';

/**
 * Fetch templates
 */
export const fetchTemplates = makeRequestAction('FETCH_TEMPLATES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addTemplate = makeRequestAction('ADD_TEMPLATE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateTemplate = makeRequestAction('UPDATE_TEMPLATE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateTemplateStatus = makeRequestAction('UPDATE_TEMPLATE_STATUS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addTemplateSection = makeRequestAction('ADD_TEMPLATE_SECTION', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneTemplate = makeRequestAction('GET_TEMPLATE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteTemplate = makeRequestAction('DELETE_TEMPLATE', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});

export const addFormUploads = makeRequestAction('ADD_FORM_UPLOADS', {
  onSuccess(params, response) {
    return {
      files: response.map(file => ({
        uid: file.hashedId,
        name: file.fileName,
        url: `${API_ROOT}/file-storage/${file.hashedId}`,
        source: file.source,
      })),
    };
  },
});

export const addBase64Uploads = makeRequestAction('ADD_BASE64_UPLOADS', {
  onSuccess(params, response) {
    return {
      files: response.map(file => ({
        uid: file.hashedId,
        name: file.fileName,
        url: `${API_ROOT}/file-storage/${file.hashedId}`,
        source: file.source,
        id: file.id,
      })),
    };
  },
});

export const deleteFormUploads = makeRequestAction('DELETE_FORM_UPLOADS', {
  onSuccess(params, response) {
    return {
      deletedFileId: response.hashedId,
    };
  },
});
