import React from 'react';
import styled from 'styled-components';
import { InputNumber as input } from 'antd';
import propTypes from 'prop-types';
import { formatter, parser } from 'utils/numberInputFormatter';

const StyledInput = styled(input)`
  border: 1px solid #e5e5e5 !important;
  border-radius: 5px !important;
  background-color: #f1f1f1 !important;
  .ant-input-number-input:focus {
    background-color: #fff !important;
  }
`;

/** @component */
const InputNumber = props => {
  const { prefix, postfix, moneyFormat, ...otherProps } = props;
  return (
    <StyledInput
      formatter={val => formatter(val, { prefix, postfix, moneyFormat })}
      parser={val => parser(val, { prefix, postfix, moneyFormat })}
      {...otherProps}
    />
  );
};

InputNumber.propTypes = {
  block: propTypes.bool,
};

InputNumber.defaultProps = {
  block: false,
};

export default InputNumber;
