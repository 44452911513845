import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchInspetionTypesToUsers,
  fetchOneInspetionTypesToUser,
  addInspetionTypesToUser,
  updateInspetionTypesToUser,
  deleteInspetionTypesToUser,
} from 'actions/inspetion-types-to-users';
import {
  fetchAllInspetionTypesToUsersApi,
  fetchOneInspetionTypesToUserApi,
  addInspetionTypesToUserApi,
  updateInspetionTypesToUserApi,
  deleteInspetionTypesToUserApi,
} from 'services/inspetion-types-to-users';
import fetchEntity from './fetch-entity';

const fetchInspetionTypesToUsersData = fetchEntity.bind(null, fetchInspetionTypesToUsers.actions, fetchAllInspetionTypesToUsersApi);

const fetchInspetionTypesToUserData = fetchEntity.bind(null, fetchOneInspetionTypesToUser.actions, fetchOneInspetionTypesToUserApi);

const fetchAddInspetionTypesToUser = fetchEntity.bind(null, addInspetionTypesToUser.actions, addInspetionTypesToUserApi);

const fetchUpdateInspetionTypesToUser = fetchEntity.bind(null, updateInspetionTypesToUser.actions, updateInspetionTypesToUserApi);

const fetchDeleteInspetionTypesToUser = fetchEntity.bind(null, deleteInspetionTypesToUser.actions, deleteInspetionTypesToUserApi);

export function* loadFetchInspetionTypesToUsers({ params }) {
  yield call(fetchInspetionTypesToUsersData, { ...params });
}

export function* loadGetInspetionTypesToUser({ params }) {
  yield put(fetchOneInspetionTypesToUser.actions.failure({}, undefined));
  yield call(fetchInspetionTypesToUserData, params);
}

export function* loadAddInspetionTypesToUser({ params }) {
  yield call(fetchAddInspetionTypesToUser, params);
}

export function* loadUpdateInspetionTypesToUser({ params }) {
  yield call(fetchUpdateInspetionTypesToUser, params);
}

export function* loadDeleteInspetionTypesToUser({ params }) {
  yield call(fetchDeleteInspetionTypesToUser, { ...params });
}

function* watchFetchInspetionTypesToUsers() {
  yield takeLatest(fetchInspetionTypesToUsers.actionName, loadFetchInspetionTypesToUsers);
}

function* watchAddInspetionTypesToUser() {
  yield takeLatest(addInspetionTypesToUser.actionName, loadAddInspetionTypesToUser);
}

function* watchUpdateInspetionTypesToUser() {
  yield takeLatest(updateInspetionTypesToUser.actionName, loadUpdateInspetionTypesToUser);
}

function* watchDeleteInspetionTypesToUser() {
  yield takeLatest(deleteInspetionTypesToUser.actionName, loadDeleteInspetionTypesToUser);
}

function* watchGetInspetionTypesToUser() {
  yield takeLatest(fetchOneInspetionTypesToUser.actionName, loadGetInspetionTypesToUser);
}

export function* loadInspetionTypesToUsersOnChange({ params }) {
  const inspetionTypesToUsers = yield select(state => state.inspetionTypesToUsers);
  const { total, skip, limit } = inspetionTypesToUsers;
  if (skip && skip >= total) {
    yield call(fetchInspetionTypesToUsersData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchInspetionTypesToUsersData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchInspetionTypesToUsersOnChange() {
  yield takeLatest([deleteInspetionTypesToUser.requestTypes.SUCCESS], loadInspetionTypesToUsersOnChange);
}

export default {
  watchFetchInspetionTypesToUsers,
  watchAddInspetionTypesToUser,
  watchGetInspetionTypesToUser,
  watchUpdateInspetionTypesToUser,
  watchInspetionTypesToUsersOnChange,
  watchDeleteInspetionTypesToUser,
};
