import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import numberFormat from 'react-number-format';

const NumberFormat = styled(numberFormat)`
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  background-color: #f1f1f1 !important;
  height: 40px !important;
  padding: 4px 11px;
  width: 100%;
  transition: all 0.3s;
  font-size: 16px;
  &::placeholder {
    color: #c4c4c4;
  }
  &:focus {
    border-color: #758fff;
    box-shadow: 0 0 0 2px rgba(73, 98, 245, 0.2);
    outline: 0;
  }
  &:hover {
    border-color: #758fff;
  }
  ${props =>
    props.displayType === 'text' &&
    css`
      background: none !important;
      border: none !important;
    `}
`;
NumberFormat.propTypes = {
  placeholder: propTypes.string,
  format: propTypes.string,
  mask: propTypes.string,
  displayType: propTypes.string,
};

/** @component */
export default NumberFormat;
