import {
  fetchPackages,
  fetchOnePackage,
  addPackage,
  updatePackage,
  deletePackage,
} from 'actions/packages';

const packages = () => {
  const initialState = {
    packages: null,
    selectedPackage: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchPackages.requestTypes.SUCCESS:
        return {
          selectedPackage: null,
          packages: response,
        };
      case addPackage.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addPackage.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addPackage.requestTypes.SUCCESS:
        return {
          ...state,
          selectedPackage: response,
          isSuccessful: true,
        };
      case updatePackage.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updatePackage.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updatePackage.requestTypes.SUCCESS:
        return {
          ...state,
          selectedPackage: response,
          isSuccessful: true,
        };
      case fetchOnePackage.requestTypes.SUCCESS:
        return {
          ...state,
          selectedPackage: response,
        };
      case deletePackage.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default packages();
