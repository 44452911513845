import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContactsForm, Button } from 'components';
import { Form } from 'antd';
import { getContact, getCurrent, getIsSubmitted, getShowClients } from 'selectors/booking-form';
import { changeSlide, cancelSubmit, addContactInfo, handleSubmit } from 'actions/booking-form';

const ClientInfo = ({ form, handleChangeSlide, setDisabled, setArr, arr, modalVisible }) => {
  const dispatch = useDispatch();
  const current = useSelector(getCurrent);
  const contact = useSelector(getContact);
  const showClients = useSelector(getShowClients);
  const isSubmitted = useSelector(getIsSubmitted);
  const [isSkipped, setIsSkipped] = useState(false);

  useEffect(() => {
    if (isSubmitted) {
      if (isSkipped) {
        dispatch(addContactInfo.request({ skipped: true }));
        dispatch(changeSlide.request({ slide: current + 1 }));
      }
      form.validateFields((err, values) => {
        if (!err) {
          if (!contact || (contact && !showClients)) {
            dispatch(addContactInfo.request(values));
          }

          handleChangeSlide(current + 1);
        } else {
          dispatch(cancelSubmit.request());
        }

        return false;
      });
    }
    dispatch(cancelSubmit.request());
    // eslint-disable-next-line
  }, [isSubmitted, current, dispatch, form, contact, showClients, isSkipped]);

  return (
    <>
      {' '}
      <Button
        type='outlined'
        className='skip-btn'
        onClick={() => {
          setIsSkipped(true);
          setArr([...arr, 3]);
          dispatch(handleSubmit.request());
        }}
      >
        Skip
      </Button>
      <ContactsForm
        form={form}
        contact={contact}
        clientList
        setDisabled={setDisabled}
        modalVisible={modalVisible}
      />
    </>
  );
};

export default React.memo(Form.create({ name: 'client_info_form' })(ClientInfo));
