import { makeRequestAction } from './index';

/**
 * Fetch inspetion-types-to-users
 */
export const fetchInspetionTypesToUsers = makeRequestAction('FETCH_INSPETION_TYPES_TO_USERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addInspetionTypesToUser = makeRequestAction('ADD_INSPETION_TYPES_TO_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateInspetionTypesToUser = makeRequestAction('UPDATE_INSPETION_TYPES_TO_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneInspetionTypesToUser = makeRequestAction('GET_INSPETION_TYPES_TO_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteInspetionTypesToUser = makeRequestAction('DELETE_INSPETION_TYPES_TO_USER', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
