import { makeRequestAction } from './index';

/**
 * Fetch conditions-to-users
 */
export const fetchConditionsToUsers = makeRequestAction('FETCH_CONDITIONS_TO_USERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addConditionsToUser = makeRequestAction('ADD_CONDITIONS_TO_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateConditionsToUser = makeRequestAction('UPDATE_CONDITIONS_TO_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneConditionsToUser = makeRequestAction('GET_CONDITIONS_TO_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteConditionsToUser = makeRequestAction('DELETE_CONDITIONS_TO_USER', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
