import client from './main-client';

export const fetchAllInspetionTypesToUsersApi = params =>
  client().get('/inspection-types-to-users', { params });
export const fetchOneInspetionTypesToUserApi = ({ id, ...params }) =>
  client().get(`/inspection-types-to-users/${id}`, { params });
export const updateInspetionTypesToUserApi = params =>
  client().put(`/inspection-types-to-users/${params.id}`, params.values);
export const addInspetionTypesToUserApi = data => client().post('/inspection-types-to-users', data);
export const deleteInspetionTypesToUserApi = params =>
  client().delete(`/inspection-types-to-users/${params.id}`, { params });
