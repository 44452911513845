import { makeAction } from './index';

export const changeSlide = makeAction('CHANGE_SLIDE');
export const openStep = makeAction('OPEN_STEP');
export const handleSubmit = makeAction('HANDLE_SUBMIT');
export const showClientList = makeAction('SHOW_CLIENT_LIST');
export const showBrokersList = makeAction('SHOW_BROKERS_LIST');
export const showClientForm = makeAction('SHOW_CLIENT_FORM');
export const showBrokersForm = makeAction('SHOW_BROKERS_FORM');
export const handleDone = makeAction('HANDLE_DONE');
export const cancelSubmit = makeAction('CANCEL_SUBMIT');
export const addBookingInfo = makeAction('ADD_BOOKING_INFO');
export const addContactInfo = makeAction('ADD_CONTACT_INFO');
export const addBrokerInfo = makeAction('ADD_BROKER_INFO');
export const addReportInfo = makeAction('ADD_REPORT_INFO');
export const addInvoice = makeAction('ADD_INVOICE');
export const cleanAll = makeAction('CLEAN_ALL');
