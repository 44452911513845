import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import logo from './logo_neuralspect.svg';
import icon from './logo_icon.svg';

const LogoImg = styled.img`
  margin: ${props => `${props.margin}!important`};
`;

LogoImg.propTypes = {
  margin: propTypes.string,
};

LogoImg.defaultProps = {
  margin: '0 0 0 0',
};

const Logo = ({ margin, isIcon }) => (
  <LogoImg src={isIcon === false ? icon : logo} margin={margin} />
);

export default Logo;
