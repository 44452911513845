import {
  fetchConditions,
  fetchOneCondition,
  addCondition,
  updateCondition,
  deleteCondition,
} from 'actions/conditions';

const conditions = () => {
  const initialState = {
    conditions: null,
    selectedCondition: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchConditions.requestTypes.SUCCESS:
        return {
          selectedCondition: null,
          conditions: response,
        };
      case addCondition.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addCondition.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addCondition.requestTypes.SUCCESS:
        return {
          ...state,
          selectedCondition: response,
          isSuccessful: true,
        };
      case updateCondition.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateCondition.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateCondition.requestTypes.SUCCESS:
        return {
          ...state,
          selectedCondition: response,
          isSuccessful: true,
        };
      case fetchOneCondition.requestTypes.SUCCESS:
        return {
          ...state,
          selectedCondition: response,
        };
      case deleteCondition.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default conditions();
