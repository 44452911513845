export default {
  inspectorIdInput: {
    label: 'Inspector',
    fieldName: 'inspectorId',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please select the inspector!',
        },
      ],
    },
    elementProps: {},
  },
  clientNameInput: {
    label: 'Client',
    fieldName: 'contactId',
    decoratorOptions: {},
    elementProps: {},
  },

  brokerNameInput: {
    label: 'Broker',
    fieldName: 'brokerId',
    decoratorOptions: {},
    elementProps: {},
  },
  addressInput: {
    label: 'Property Address',
    fieldName: 'builingAddress',
    decoratorOptions: {},
    elementProps: {},
  },
  addressLine2Input: {
    label: 'Address 2/Unit/Suite #',
    fieldName: 'addressLine2',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },
  buildingTypeIdInput: {
    label: 'Building Type',
    fieldName: 'buildingTypeId',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please select building type!',
        },
      ],
    },
    elementProps: {},
  },
  dwellingIdInput: {
    label: 'Dwelling',
    fieldName: 'dwellingId',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please select dwelling!',
        },
      ],
    },
    elementProps: {},
  },
  storiesInput: {
    label: 'Stories',
    fieldName: 'stories',
    decoratorOptions: {
      rules: [
        {
          pattern: new RegExp(/^[1-9][0-9]{0,1}[:.,-]?$/g),
          message: 'Please insert a valid number!',
        },
      ],
    },
    elementProps: {},
  },
  roomsInput: {
    label: 'Rooms',
    fieldName: 'rooms',
    decoratorOptions: {
      rules: [
        {
          pattern: new RegExp(/^[1-9][0-9]{0,1}[:.,-]?$/g),
          message: 'Please insert a valid number!',
        },
      ],
    },
    elementProps: {},
  },
  bedroomsInput: {
    label: 'Bedrooms',
    fieldName: 'bedrooms',
    decoratorOptions: {
      rules: [
        {
          pattern: new RegExp(/^[1-9][0-9]{0,1}[:.,-]?$/g),
          message: 'Please insert a valid number!',
        },
      ],
    },
    elementProps: {},
  },
  bathroomsInput: {
    label: 'Bathrooms',
    fieldName: 'bathrooms',
    decoratorOptions: {
      rules: [
        {
          pattern: new RegExp(/^[1-9][0-9]{0,1}[:.,-]?$/g),
          message: 'Please insert a valid number!',
        },
      ],
    },
    elementProps: {},
  },
  minPriceInput: {
    label: 'Min Price',
    fieldName: 'minPrice',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input min price!',
        },
      ],
    },
    elementProps: {},
  },
  maxPriceInput: {
    label: 'Max Price',
    fieldName: 'maxPrice',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input max price!',
        },
      ],
    },
    elementProps: {},
  },
  yearBuiltInput: {
    label: 'Year built',
    fieldName: 'yearBuilt',
    decoratorOptions: {
      rules: [
        {
          pattern: new RegExp(/^(19|20)\d{2}$/g),
          message: 'Please insert a valid year!',
        },
      ],
    },
    elementProps: {},
  },
  sqft: {
    label: 'SqFt',
    fieldName: 'sqft',
    decoratorOptions: {
      rules: [
        {
          pattern: new RegExp(/^\d*\.?\d*$/),
          message: 'Please insert a valid square feet!',
        },
      ],
    },
    elementProps: {},
  },
  statusInput: {
    label: 'Status',
    fieldName: 'status',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please select status!',
        },
      ],
    },
    elementProps: {},
  },
  coverInput: {
    label: 'Cover',
    fieldName: 'cover',
    decoratorOptions: {},
    elementProps: {},
  },
};
