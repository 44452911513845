import React, { useEffect, useState } from 'react';
import { addBookingInfo } from 'actions/booking-form';
import { fetchCoverLetter } from 'actions/cover-letters';
import {
  getCoverLetter,
  getCoverLetterLoading,
  getCoverLetterError,
} from 'selectors/cover-letters';
import { getBooking, getCurrent, getIsSubmitted } from 'selectors/booking-form';
import { RichTextEditor } from 'components/index';
import { useDispatch, useSelector } from 'react-redux';
import defaultContent from '../../../containers/Settings/ThankYouLetter/partials/letterData';

const CoverLetter = ({ handleChangeSlide }) => {
  const dispatch = useDispatch();
  const coverLetter = useSelector(getCoverLetter);
  const loading = useSelector(getCoverLetterLoading);
  const error = useSelector(getCoverLetterError);
  const booking = useSelector(getBooking);
  const isSubmitted = useSelector(getIsSubmitted);
  const current = useSelector(getCurrent);

  const [content, setContent] = useState(defaultContent.content);

  useEffect(() => {
    dispatch(fetchCoverLetter.request());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && !error && coverLetter && coverLetter.content) {
      setContent(coverLetter.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverLetter, loading, error]);

  useEffect(() => {
    if (isSubmitted) {
      const values = {
        ...booking,
        coverLetter: content,
      };

      dispatch(addBookingInfo.request(values));
      handleChangeSlide(current + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted]);

  return (
    <>
      <RichTextEditor
        defaultValue={content}
        onChange={e => {
          setContent(e);
        }}
      />
    </>
  );
};

export default CoverLetter;
