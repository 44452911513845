import styled from 'styled-components';

const BookingModalWrapper = styled.div`
  .back-btn {
    color: #3936e8;
    font-weight: bold;
    margin-bottom: 8px;
    cursor: pointer;
    width: fit-content;
    span {
      margin-left: 8px;
    }
    svg {
      color: #3936e8 !important;
    }
  }
  .ant-page-header-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
    .username {
      margin-left: 8px;
    }
  }
  .booking-steps {
    padding: 32px 48px;
    max-width: 312px;
    @media (max-width: 576px) {
      display: none;
    }
    .anticon svg {
      color: #f6f3f3;
    }
  }
  .booking-forms {
    max-width: 720px;
  }
  .general-info,
  .property-info,
  .review,
  .contracts {
    padding-top: 56px;
    @media (max-width: 576px) {
      padding-top: 5px;
    }
    .ant-radio {
      position: absolute;
      top: 26px;
      right: 24px;
    }
    .ant-radio-wrapper {
      width: 100%;
    }
    &-details {
      margin-right: 16px;
      padding: 8px;
      border: 1px solid #e4e4e4;
      border-radius: 3px;
      min-height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      p {
        margin: 0;
        white-space: normal;
        padding-right: 16px;
      }
    }
    &-title {
      color: #000000;
      font-size: 14px;
      margin-top: 8px;
    }
    &-version {
      font-size: 12px;
    }
    &-content {
      margin-bottom: 40px;
    }
    .ant-radio-checked .ant-radio-inner {
      border-color: #3535dd !important ;
    }
    .ant-radio-checked .ant-radio-inner:after {
      background-color: #3535dd;
    }
    .ant-radio:hover .ant-radio-inner {
      border-color: #3535dd;
    }
    .ant-radio-wrapper-checked .contracts-details {
      border: 2px solid #3535dd;
      background-color: rgba(228, 228, 255, 0.75);
    }
  }
  .add-client,
  .add-broker {
    display: flex;
    justify-content: start;
    margin-bottom: 16px;
  }
  .skip-btn {
    position: absolute;
    right: 0;
    top: 6px;
  }
  .ant-steps-item .ant-steps-item-icon {
    height: 40px;
    width: 40px;
    border: 2px solid rgb(57, 54, 232, 0.1);
    color: #3936e8;
    font-family: Heebo;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1px;
  }
  .ant-steps-item .ant-steps-item-title {
    font-size: 14px;
    padding: 4px 0;
  }
  .ant-steps-item-finish .ant-steps-item-icon,
  .ant-steps-item-active .ant-steps-item-icon {
    background-color: #3535dd;
    color: #ffffff;
    cursor: pointer;
  }
  .ant-steps-item-wait .ant-steps-item-title {
    color: rgb(65, 41, 241, 0.65) !important;
  }
  .ant-steps-item-tail::after {
    width: 2px !important;
    height: 8px !important;
    position: relative;
    top: 2px;
    background-color: rgb(57, 54, 232, 0.1) !important;
    left: 4px;
  }
  .step-flag .ant-steps-item-icon {
    background: rgb(53, 53, 221, 0.1) !important;
    border: 1px solid #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .steps-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 36px;
    @media (max-width: 576px) {
      padding-top: 1px;
    }
  }
  .next-btn {
    height: 40px;
    width: 181px;
    font-family: Heebo;
    font-size: 14px;
    padding: 8px 0;
    margin-left: 10px;
    @media (max-width: 576px) {
      height: 40px;
      width: 100px;
    }
  }
  .cancel-btn {
    height: 40px;
    width: 181px;
    font-family: Heebo;
    font-size: 14px;
    padding: 8px 0;
    margin-left: auto;
    @media (max-width: 576px) {
      height: 40px;
      width: 100px;
    }
  }
  .ant-form-item {
    @media (max-width: 576px) {
      margin-bottom: 1px;
    }
  }

  .ant-form-item label {
    color: #000000;
    font-family: Heebo;
    font-size: 12px;
    font-weight: bold;
  }
  .ant-form-item .anticon {
    color: black;
    font-weight: bold !important;
  }
  .invoice {
    .gradient {
      height: 60px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(238, 238, 238, 0) 100%);
    }
    .ant-form-item label {
      font-weight: unset;
    }
    .service-list-item {
      padding: 16px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 12px !important;
      line-height: 18px !important;
      font-weight: 400 !important;
      .ant-checkbox-inner {
        box-sizing: border-box;
        height: 24px;
        width: 24px;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        background-color: #f1f1f1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ant-checkbox-checked .ant-checkbox-inner:after {
        border-color: #3e27e7 !important;
        left: calc(50% - 4px);
      }
    }
    .active {
      background: #f1f1f1;
      font-weight: bold;
    }
    .service-list {
      border: 1px solid #e7e7e7;
      min-height: 60vh;
    }
    .service-price {
      font-weight: bold;
    }
    .invoice-form {
      padding: 48px !important;
      .ant-card-body {
        padding: 0 !important;
      }
      .ant-card-head-title {
        font-weight: bold;
        font-size: 18px;
      }
      .ant-list-items {
        padding: 16px;
        background-color: #f1f1f1;
      }
      .ant-list-item {
        border: none !important;
        padding: 0 !important;
      }
      .invoice-details {
        padding: 16px;
        div {
          margin: 8px 0;
        }
      }
      .total {
        font-weight: bold;
        padding: 16px;
        border-top: 1px solid rgba(151, 151, 151, 0.2);
      }
      .align-right {
        text-align: right;
      }
      .finish {
        background-color: #f1f1f1;
        padding: 16px;
      }
      .delete-icon {
        color: #b57c7c;
        margin-right: 16px;
      }
      .ant-list-item-meta-title {
        font-weight: bold;
      }
    }
  }
  .ant-form-vertical .ant-form-item-control {
    position: relative;
    padding-bottom: 16px;
  }
  .has-error .ant-form-explain {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .additional-fee {
    border-bottom: 1px solid rgb(232, 232, 232);
    border-top: 1px solid rgb(232, 232, 232);
    &-title {
      font-weight: bold;
    }
    &-price div {
      margin: 0 !important;
    }
  }
  .travel-fee {
    border-top: none;
  }
`;

/** @component */
export default BookingModalWrapper;
