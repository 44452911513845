import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import Autocomplete from 'react-google-autocomplete';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';

const MapAutocomplete = withScriptjs(
  withGoogleMap(
    ({
      lat,
      lng,
      setLat,
      setLng,
      address,
      setAddress,
      google,
      onPlaceSelected,
      searchInput,
      markers,
    }) => {
      return (
        <GoogleMap
          google={google}
          defaultZoom={9}
          defaultCenter={{ lat, lng }}
          center={{ lat, lng }}
        >
          <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
            {!markers && <Marker position={{ lat, lng }} />}
            {markers &&
              markers.length &&
              markers.map((marker, index) => (
                <Marker key={`${marker.address}-${index}`} position={marker} />
              ))}
          </MarkerClusterer>

          {searchInput && (
            <Autocomplete
              defaultValue={address}
              style={{
                width: '100%',
                height: '40px',
                paddingLeft: '16px',
                marginTop: '2px',
                marginBottom: '500px',
                border: '1px solid #e5e5e5',
                borderRadius: '5px',
                background: '#f1f1f1',
              }}
              onPlaceSelected={onPlaceSelected}
              types={null}
            />
          )}
        </GoogleMap>
      );
    }
  )
);

export default React.memo(MapAutocomplete);
