import {
  fetchConditionsToUsers,
  fetchOneConditionsToUser,
  addConditionsToUser,
  updateConditionsToUser,
  deleteConditionsToUser,
} from 'actions/conditions-to-users';

const conditionsToUsers = () => {
  const initialState = {
    conditionsToUsers: null,
    selectedConditionsToUser: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchConditionsToUsers.requestTypes.SUCCESS:
        return {
          selectedConditionsToUser: null,
          conditionsToUsers: response,
        };
      case addConditionsToUser.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addConditionsToUser.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addConditionsToUser.requestTypes.SUCCESS:
        return {
          ...state,
          selectedConditionsToUser: response,
          isSuccessful: true,
        };
      case updateConditionsToUser.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateConditionsToUser.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateConditionsToUser.requestTypes.SUCCESS:
        return {
          ...state,
          selectedConditionsToUser: response,
          isSuccessful: true,
        };
      case fetchOneConditionsToUser.requestTypes.SUCCESS:
        return {
          ...state,
          selectedConditionsToUser: response,
        };
      case deleteConditionsToUser.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default conditionsToUsers();
