/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { Col, Row } from 'antd';

// eslint-disable-next-line no-unused-vars
const nodes = [
  {
    url: 'https://neuralspect.com/privacy-policy/',
    label: 'Privacy Policy',
  },
  {
    url: 'https://neuralspect.com/terms-conditions/',
    label: 'Terms & Conditions',
  },
];

const CheckoutWrapper = ({ children }) => (
  <Fragment>
    <Row style={{ height: '100vh' }} type='flex' justify='center' align='middle'>
      <Col xs={20} lg={10}>
        {children}
        <div style={{ display: 'flex', justifyContent: 'space-around', margin: '0 30px' }}>
          <a href='https://neuralspect.com/privacy-policy/' style={{ color: '#000' }}>
            Privacy Policy
          </a>
          <a href='https://neuralspect.com/terms-conditions/' style={{ color: '#000' }}>
            Terms & Conditions
          </a>
        </div>
        {/* <Nav nodes={nodes} /> */}
      </Col>
    </Row>
  </Fragment>
);

export default React.memo(CheckoutWrapper);
