import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userIsAuth, getIsPayed } from 'selectors/users';
import DashboardWrapper from 'DashboardWrapper';
import GuestWrapper from 'GuestWrapper';
import GuestContract from 'containers/GuestContract/index';
import AppRep from 'containers/AppRep/index';
import Routes from './Routes';
import CheckoutWrapper from './CheckoutWrapper';

const AppRouter = props => {
  const isAuth = useSelector(userIsAuth);
  const isPayed = useSelector(getIsPayed);
  const path = window.location.pathname.substring(0, 16);

  const renderWrapper = () => {
    if (!isPayed && isAuth) {
      return (
        <CheckoutWrapper isAuth={isAuth}>
          <Routes {...props} />
        </CheckoutWrapper>
      );
    }
    if (path === '/app-rep/') {
      return (
        <DashboardWrapper isAuth={isAuth}>
          <AppRep {...props} />
        </DashboardWrapper>
      );
    }

    if (path === '/guest-contract/') {
      return (
        <GuestWrapper>
          <Route exact path='/guest-contract/:token' component={GuestContract} />
        </GuestWrapper>
      );
    }

    if (isAuth) {
      return (
        <DashboardWrapper isAuth={isAuth}>
          <Routes {...props} />
        </DashboardWrapper>
      );
    }

    return (
      <GuestWrapper>
        <Routes {...props} />
      </GuestWrapper>
    );
  };

  return <BrowserRouter>{renderWrapper()}</BrowserRouter>;
};

export default AppRouter;
