import client from './main-client';

export const fetchAllLimitationsApi = params => client().get('/limitations', { params });
export const fetchOneLimitationApi = ({ id, ...params }) =>
  client().get(`/limitations/${id}`, { params });
export const updateLimitationApi = params =>
  client().put(`/limitations/${params.id}`, params.values);
export const addLimitationApi = data => client().post('/limitations', data);
export const deleteLimitationApi = params =>
  client().delete(`/limitations/${params.id}`, { params });
