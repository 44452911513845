import { getUserRole } from 'selectors/users';
import store from 'configs/configureStore';
import { routePermissions } from 'constants/users';

export const isUserPermitted = (allowForRoles = []) => {
  const currentRole = getUserRole(store.getState());
  return allowForRoles.some(res => res === currentRole);
};

export const isRoutPermitted = route => {
  const currentRole = getUserRole(store.getState());
  const allowForRoles = routePermissions[currentRole];

  return allowForRoles.some(res => res === route);
};
