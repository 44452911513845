import { makeRequestAction } from './index';

export const fetchCoverLetter = makeRequestAction('GET_COVER_LETTER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addCoverLetter = makeRequestAction('ADD_COVER_LETTER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateCoverLetter = makeRequestAction('UPDATE_COVER_LETTER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
