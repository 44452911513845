import { makeRequestAction, makeAction } from './index';

export const fetchContracts = makeRequestAction('FETCH_CONTRACTS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchUserContracts = makeRequestAction('FETCH_USER_CONTRACTS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneContract = makeRequestAction('GET_CONTRACT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addContract = makeRequestAction('ADD_CONTRACT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteContract = makeRequestAction('DELETE_CONTRACT', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});

export const updateContract = makeRequestAction('UPDATE_CONTRACT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const cleanAll = makeAction('CLEAN_ALL');
