import { makeRequestAction } from './index';

/**
 * Fetch building types
 */
export const fetchBuildingTypes = makeRequestAction('FETCH_BUILDING_TYPES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addBuildingType = makeRequestAction('ADD_BUILDING_TYPE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateBuildingType = makeRequestAction('UPDATE_BUILDING_TYPE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneBuildingType = makeRequestAction('GET_BUILDING_TYPE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteBuildingType = makeRequestAction('DELETE_BUILDING_TYPE', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
