import styled, { css } from 'styled-components';
import { Button as button } from 'antd';
import propTypes from 'prop-types';

const Button = styled(button)`
  border-radius: 5px;
  font-size: 14px;
  line-height: 21px;
  font-weight: bold;
  ${props =>
    props.type === 'primary' &&
    css`
      background-color: #3535dd;
      border: none;
    `}
    ${props =>
      props.type === 'success' &&
      props.ghost &&
      css`
        background-color: none;
        border: none;
        color: #759d00;
        box-shadow: none;
        padding: 0 !important;
        margin: 0 !important;
      `}
  ${props =>
    props.type === 'link' &&
    css`
      color: #3535dd !important;
      font-weight: bold;
      &:disabled {
        border: 1px solid rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.1) !important;
      }
    `}
    ${props =>
      props.type === 'link' &&
      !props.ghost &&
      css`
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        color: #000;
      `}
    ${props =>
      props.type === 'link' &&
      props.ghost &&
      css`
        border: 0;
        padding: 0 !important;
        color: #3535dd !important;
      `}

      ${props =>
        props.ghost &&
        css`
          box-shadow: none !imporant;
        `}
        @media (max-width: 992px) {
          font-size: 12px;
        }
        @media (max-width: 365px) {
          font-size: 10px;
        }
      `;

Button.propTypes = {
  type: propTypes.string,
};

Button.defaultProps = {};

/** @component */
export default Button;
