import {
  fetchContractsToReports,
  fetchOneContractToReport,
  addContractToReport,
  updateContractToReport,
} from 'actions/contracts-to-reports';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getContractsToReports = state => state.contractsToReports;
export const getContractsToReportsState = createSelector(
  getContractsToReports,
  (data) => ((data.contractsToReports) ? data.contractsToReports : []),
);

export const getSuccessState = createSelector(
  getContractsToReports,
  (data) => data.isSuccessful,
);
export const getSelectedContractToReport = createSelector(
  getContractsToReports,
  (data) => data.selectedContractToReport,
);

export const contractsToReportsValuesLoading = createLoadingSelector(fetchContractsToReports.actionName)();
export const contractsToReportsValuesError = createErrorSelector(fetchContractsToReports.actionName)();

export const getAddContractToReportLoading = createLoadingSelector(
  addContractToReport.actionName,
)();
export const getAddContractToReportError = createErrorSelector(
  addContractToReport.actionName,
)();

export const getUpdateContractToReportLoading = createLoadingSelector(
  updateContractToReport.actionName,
)();
export const getUpdateContractToReportError = createErrorSelector(
  updateContractToReport.actionName,
)();

export const getSelectedContractToReportLoading = createLoadingSelector(
  fetchOneContractToReport.actionName,
)();
export const getSelectedContractToReportError = createErrorSelector(
  fetchOneContractToReport.actionName,
)();
