import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import store from 'configs/configureStore';
// eslint-disable-next-line import/no-extraneous-dependencies
import { registerLicense } from '@syncfusion/ej2-base';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { SYNCFUSION_KEY } from './configs/env-vars';

registerLicense(SYNCFUSION_KEY);

ReactDOM.render(<App store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
