import { makeRequestAction } from './index';

/**
 * Fetch contracts-to-reports
 */
export const fetchContractsToReports = makeRequestAction('FETCH_CONTRACTS_TO_REPORTS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addContractToReport = makeRequestAction('ADD_CONTRACT_TO_REPORT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateContractToReport = makeRequestAction('UPDATE_CONTRACT_TO_REPORT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneContractToReport = makeRequestAction('GET_CONTRACT_TO_REPORT', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteContractToReport = makeRequestAction('DELETE_CONTRACT_TO_REPORT', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
