import client from './main-client';

export const fetchAllReportsHistoryApi = params => client().get('/reports-history', { params });
export const fetchOneReportHistoryApi = ({ id, ...params }) =>
  client().get(`/reports-history/${id}`, { params });
export const updateReportHistoryApi = params =>
  client().put(`/reports-history/${params.id}`, params.values);
export const addReportHistoryApi = data => client().post('/reports-history', data);
export const deleteReportHistoryApi = params =>
  client().delete(`/reports-history/${params.id}`, { params });
