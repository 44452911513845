export default {
  title: 'Agreement',
  text:
    'This is an agreement between <%= clientName %> and <%= companyName %>. ' +
    'INSPECTION CONTRACT\n' +
    'I/we, the above-named Client(s), request a Home inspection of the Property ' +
    'noted above for the fees and ' +
    'charges quoted. The inspection is to be performed in accordance with industry accepted ' +
    'Standards of Practice.' +
    ' I/we acknowledge having had the opportunity to review and understand the Standards' +
    ' of Practice which can be found at: http://www.keyhomeinspect.ca/fi les/Standards of' +
    ' Practice-OAH l.pdf\n' +
    '\n' +
    'I/we understand that the inspection report to be provided for the Property is' +
    ' based on the limited visual inspection of the readily accessible aspects of the Property.' +
    " The report is representative of the inspector's opinion of the observable conditions" +
    ' on the day' +
    ' of the inspection. While this inspection may reduce the risk of home ownership, ' +
    'it is not an' +
    ' insurance policy, warranty, or guarantee on the home. Neither the inspector nor the ' +
    'inspection' +
    ' company assume any risk in connection with this Key Home inspection or the report to be ' +
    'provided ' +
    'or in connection with the Property or its current or future condition adequacy, deficiencies' +
    ' or ' +
    'performance. The report to be provided and all other information regarding the Property ' +
    'provided ' +
    'to the client are for the exclusive use of the Client and may not be used by anyone else ' +
    'without ' +
    'prior written permission of the inspection company.\n' +
    '\n' +
    'I/we understand and agree that the inspector and Key Home Inspections Inc. do not and ' +
    'will ' +
    'not assume any risk in connection with this Home inspection and the report to be ' +
    'provided to ' +
    'the Client or in connection with the property or its current or future adequacy, condition,' +
    ' deficiencies or performance. The liability of the inspector and Key Home Inspections Inc.' +
    ' arising out of this Home Inspection and the report to be provided to the Client for any ' +
    'cause of action whatsoever, whether in contract, negligence or otherwise, is limited to a ' +
    'refund of the fees that the Client has been charged for this inspection.',
};
