import styled from 'styled-components';

const PreviewContentWrapper = styled.div`
  .preview-content {
    padding: 10px 30px;
    &-title {
      color: #3c29e6;
      font-weight: bold;
    }
    &-date {
      font-size: 14px;
      text-align: right;
    }
    &-version {
      font-size: 14px;
      font-weight: bold;
    }
    &-text {
      margin-top: 24px;
    }
  }
  .contract-preview {
    p {
      margin: 0;
    }
  }
`;

export default PreviewContentWrapper;
