import {
  fetchReportsHistory,
  fetchOneReportHistory,
  addReportHistory,
  updateReportHistory,
  deleteReportHistory,
} from 'actions/reports-history';

const reportsHistory = () => {
  const initialState = {
    reportsHistory: null,
    selectedReportHistory: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchReportsHistory.requestTypes.SUCCESS:
        return {
          selectedReportHistory: null,
          reportsHistory: response,
        };
      case addReportHistory.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addReportHistory.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addReportHistory.requestTypes.SUCCESS:
        return {
          ...state,
          selectedReportHistory: response,
          isSuccessful: true,
        };
      case updateReportHistory.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateReportHistory.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateReportHistory.requestTypes.SUCCESS:
        return {
          ...state,
          selectedReportHistory: response,
          isSuccessful: true,
        };
      case fetchOneReportHistory.requestTypes.SUCCESS:
        return {
          ...state,
          selectedReportHistory: response,
        };
      case deleteReportHistory.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default reportsHistory();
