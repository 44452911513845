import { fetchInvoices, fetchOneInvoice, addInvoice, updateInvoice } from 'actions/invoices';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getInvoices = state => state.invoices;
export const getInvoicesState = createSelector(getInvoices, data =>
  data.invoices ? data.invoices : []
);

export const getSuccessState = createSelector(getInvoices, data => data.isSuccessful);
export const getSelectedInvoice = createSelector(getInvoices, data => data.selectedInvoice);

export const invoicesValuesLoading = createLoadingSelector(fetchInvoices.actionName)();
export const invoicesValuesError = createErrorSelector(fetchInvoices.actionName)();

export const getAddInvoiceLoading = createLoadingSelector(addInvoice.actionName)();
export const getAddInvoiceError = createErrorSelector(addInvoice.actionName)();

export const getUpdateInvoiceLoading = createLoadingSelector(updateInvoice.actionName)();
export const getUpdateInvoiceError = createErrorSelector(updateInvoice.actionName)();

export const getSelectedInvoiceLoading = createLoadingSelector(fetchOneInvoice.actionName)();
export const getSelectedInvoiceError = createErrorSelector(fetchOneInvoice.actionName)();
