import client from './main-client';

export const addUploadApi = data => {
  const formData = new FormData();
  Object.keys(data).forEach(key =>
    key === 'file'
      ? data[key].forEach(file => formData.append(key, file))
      : formData.append(key, data[key])
  );
  return client({ 'Content-type': 'application/x-www-form-urlencoded' }).post(
    '/file-storage',
    formData
  );
};
export const addBase64UploadApi = data => client().post('/file-storage', data);
export const deleteUploadApi = params => client().delete(`/file-storage/${params.id}`);
