import {
  fetchDwellings,
  fetchOneDwelling,
  addDwelling,
  updateDwelling,
  deleteDwelling,
} from 'actions/dwellings';

const dwellings = () => {
  const initialState = {
    dwellings: null,
    selectedDwelling: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchDwellings.requestTypes.SUCCESS:
        return {
          selectedDwelling: null,
          dwellings: response,
        };
      case addDwelling.requestTypes.REQUEST:
      case deleteDwelling.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addDwelling.requestTypes.FAILURE:
      case deleteDwelling.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addDwelling.requestTypes.SUCCESS:
        return {
          ...state,
          selectedDwelling: response,
          isSuccessful: true,
        };
      case updateDwelling.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateDwelling.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateDwelling.requestTypes.SUCCESS:
        return {
          ...state,
          selectedDwelling: response,
          isSuccessful: true,
        };
      case fetchOneDwelling.requestTypes.SUCCESS:
        return {
          ...state,
          selectedDwelling: response,
        };
      case deleteDwelling.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
          isSuccessful: true,
        };
      default:
        return state;
    }
  };
};

export default dwellings();
