import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchContractsToReports,
  fetchOneContractToReport,
  addContractToReport,
  updateContractToReport,
  deleteContractToReport,
} from 'actions/contracts-to-reports';
import {
  fetchAllContractsToReportsApi,
  fetchOneContractToReportApi,
  addContractToReportApi,
  updateContractToReportApi,
  deleteContractToReportApi,
} from 'services/contracts-to-reports';
import fetchEntity from './fetch-entity';

const fetchContractsToReportsData = fetchEntity.bind(null, fetchContractsToReports.actions, fetchAllContractsToReportsApi);

const fetchContractToReportData = fetchEntity.bind(null, fetchOneContractToReport.actions, fetchOneContractToReportApi);

const fetchAddContractToReport = fetchEntity.bind(null, addContractToReport.actions, addContractToReportApi);

const fetchUpdateContractToReport = fetchEntity.bind(null, updateContractToReport.actions, updateContractToReportApi);

const fetchDeleteContractToReport = fetchEntity.bind(null, deleteContractToReport.actions, deleteContractToReportApi);

export function* loadFetchContractsToReports({ params }) {
  yield call(fetchContractsToReportsData, { ...params });
}

export function* loadGetContractToReport({ params }) {
  yield put(fetchOneContractToReport.actions.failure({}, undefined));
  yield call(fetchContractToReportData, params);
}

export function* loadAddContractToReport({ params }) {
  yield call(fetchAddContractToReport, params);
}

export function* loadUpdateContractToReport({ params }) {
  yield call(fetchUpdateContractToReport, params);
}

export function* loadDeleteContractToReport({ params }) {
  yield call(fetchDeleteContractToReport, { ...params });
}

function* watchFetchContractsToReports() {
  yield takeLatest(fetchContractsToReports.actionName, loadFetchContractsToReports);
}

function* watchAddContractToReport() {
  yield takeLatest(addContractToReport.actionName, loadAddContractToReport);
}

function* watchUpdateContractToReport() {
  yield takeLatest(updateContractToReport.actionName, loadUpdateContractToReport);
}

function* watchDeleteContractToReport() {
  yield takeLatest(deleteContractToReport.actionName, loadDeleteContractToReport);
}

function* watchGetContractToReport() {
  yield takeLatest(fetchOneContractToReport.actionName, loadGetContractToReport);
}

export function* loadContractsToReportsOnChange({ params }) {
  const contractsToReports = yield select(state => state.contractsToReports);
  const { total, skip, limit } = contractsToReports;
  if (skip && skip >= total) {
    yield call(fetchContractsToReportsData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchContractsToReportsData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchContractsToReportsOnChange() {
  yield takeLatest([deleteContractToReport.requestTypes.SUCCESS], loadContractsToReportsOnChange);
}

export default {
  watchFetchContractsToReports,
  watchAddContractToReport,
  watchGetContractToReport,
  watchUpdateContractToReport,
  watchContractsToReportsOnChange,
  watchDeleteContractToReport,
};
