import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { Icon as icon, Dropdown, Menu } from 'antd';
import { withRouter } from 'react-router-dom';

const Icon = styled(icon)`
  color: #aaa !important;
  font-size: 18px !important;
  line-height: 18px !important;
  transform: rotate(90deg) !important;
`;

const ActionButtonWrapper = styled.div``;

const ActionButtons = props => {
  const menu = (
    <Menu>
      {props.actions && Object.keys(props.actions).length
        ? Object.keys(props.actions).map((key, index) => {
            const { to } = props.actions[key];
            return props.actions[key].action === 'devider' ? (
              <Menu.Divider key={key} />
            ) : (
              <Menu.Item
                disabled={props.disable}
                {...props.actions[key]}
                // href={props.actions[key].href}
                onClick={
                  to
                    ? () => {
                        props.history.replace(to);
                      }
                    : props.actions[key].onClick
                }
                key={props.actions[key].label}
              >
                {props.actions[key].label}
              </Menu.Item>
            );
          })
        : null}
    </Menu>
  );

  return (
    <ActionButtonWrapper>
      <Dropdown overlay={menu}>
        <Button type={'link'} ghost>
          <Icon type='more' className={'more-icon'} />
        </Button>
      </Dropdown>
    </ActionButtonWrapper>
  );
};

export default withRouter(ActionButtons);
