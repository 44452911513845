import styled from 'styled-components';
import { TimePicker as timepicker } from 'antd';

const TimePicker = styled(timepicker)`
  width: 100% !important;
  .ant-time-picker-input {
    border: 1px solid #e5e5e5 !important;
    border-radius: 5px !important;
    background-color: #f1f1f1 !important;
  }
`;

/** @component */
export default TimePicker;
