import styled from 'styled-components';

const GuestContractWrapper = styled.div`
  min-height: 100vh;
  padding: 40px 8px;
  p {
    margin: 0 !important;
  }
  .sign-btn {
    width: 100px;
    float: right;
  }
  .buttonWrapper {
    display: flex;
    column-gap: 20px;
    justify-content: flex-end;
  }
`;

export default GuestContractWrapper;
