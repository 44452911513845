import {
  fetchInspectionTypes,
  fetchOneInspectionType,
  addInspectionType,
  updateInspectionType,
  deleteInspectionType,
} from 'actions/inspection-types';

const inspectionTypes = () => {
  const initialState = {
    inspectionTypes: null,
    selectedInspectionType: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchInspectionTypes.requestTypes.SUCCESS:
        return {
          selectedInspectionType: null,
          inspectionTypes: response,
        };
      case addInspectionType.requestTypes.REQUEST:
      case deleteInspectionType.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addInspectionType.requestTypes.FAILURE:
      case deleteInspectionType.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addInspectionType.requestTypes.SUCCESS:
        return {
          ...state,
          selectedInspectionType: response,
          isSuccessful: true,
        };
      case updateInspectionType.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateInspectionType.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateInspectionType.requestTypes.SUCCESS:
        return {
          ...state,
          selectedInspectionType: response,
          isSuccessful: true,
        };
      case fetchOneInspectionType.requestTypes.SUCCESS:
        return {
          ...state,
          selectedInspectionType: response,
        };
      case deleteInspectionType.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
          isSuccessful: true,
        };
      default:
        return state;
    }
  };
};

export default inspectionTypes();
