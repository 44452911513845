import { call, takeLatest } from 'redux-saga/effects';
import { getContract } from 'actions/guest-contract';
import { getGuestContractApi } from 'services/guest-contract';
import fetchEntity from './fetch-entity';

const getGuestContractData = fetchEntity.bind(null, getContract.actions, getGuestContractApi);

export function* loadGetGuestContractData({ params }) {
  yield call(getGuestContractData, { ...params });
}

function* watchGetGuestContract() {
  yield takeLatest(getContract.actionName, loadGetGuestContractData);
}

export default {
  watchGetGuestContract,
};
