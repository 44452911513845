import { makeRequestAction } from './index';

/**
 * Fetch dwellings
 */
export const fetchDwellings = makeRequestAction('FETCH_DWELLINGS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addDwelling = makeRequestAction('ADD_DWELLING', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateDwelling = makeRequestAction('UPDATE_DWELLING', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneDwelling = makeRequestAction('GET_DWELLING', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteDwelling = makeRequestAction('DELETE_DWELLING', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
