import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchConditionsToUsers,
  fetchOneConditionsToUser,
  addConditionsToUser,
  updateConditionsToUser,
  deleteConditionsToUser,
} from 'actions/conditions-to-users';
import {
  fetchAllConditionsToUsersApi,
  fetchOneConditionsToUserApi,
  addConditionsToUserApi,
  updateConditionsToUserApi,
  deleteConditionsToUserApi,
} from 'services/conditions-to-users';
import fetchEntity from './fetch-entity';

const fetchConditionsToUsersData = fetchEntity.bind(
  null,
  fetchConditionsToUsers.actions,
  fetchAllConditionsToUsersApi
);

const fetchConditionsToUserData = fetchEntity.bind(
  null,
  fetchOneConditionsToUser.actions,
  fetchOneConditionsToUserApi
);

const fetchAddConditionsToUser = fetchEntity.bind(
  null,
  addConditionsToUser.actions,
  addConditionsToUserApi
);

const fetchUpdateConditionsToUser = fetchEntity.bind(
  null,
  updateConditionsToUser.actions,
  updateConditionsToUserApi
);

const fetchDeleteConditionsToUser = fetchEntity.bind(
  null,
  deleteConditionsToUser.actions,
  deleteConditionsToUserApi
);

export function* loadFetchConditionsToUsers({ params }) {
  yield call(fetchConditionsToUsersData, { ...params });
}

export function* loadGetConditionsToUser({ params }) {
  yield put(fetchOneConditionsToUser.actions.failure({}, undefined));
  yield call(fetchConditionsToUserData, params);
}

export function* loadAddConditionsToUser({ params }) {
  yield call(fetchAddConditionsToUser, params);
}

export function* loadUpdateConditionsToUser({ params }) {
  yield call(fetchUpdateConditionsToUser, params);
}

export function* loadDeleteConditionsToUser({ params }) {
  yield call(fetchDeleteConditionsToUser, { ...params });
}

function* watchFetchConditionsToUsers() {
  yield takeLatest(fetchConditionsToUsers.actionName, loadFetchConditionsToUsers);
}

function* watchAddConditionsToUser() {
  yield takeLatest(addConditionsToUser.actionName, loadAddConditionsToUser);
}

function* watchUpdateConditionsToUser() {
  yield takeLatest(updateConditionsToUser.actionName, loadUpdateConditionsToUser);
}

function* watchDeleteConditionsToUser() {
  yield takeLatest(deleteConditionsToUser.actionName, loadDeleteConditionsToUser);
}

function* watchGetConditionsToUser() {
  yield takeLatest(fetchOneConditionsToUser.actionName, loadGetConditionsToUser);
}

export function* loadConditionsToUsersOnChange({ params }) {
  const conditionsToUsers = yield select(state => state.conditionsToUsers);
  const { total, skip, limit } = conditionsToUsers;
  if (skip && skip >= total) {
    yield call(fetchConditionsToUsersData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchConditionsToUsersData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchConditionsToUsersOnChange() {
  yield takeLatest(
    [
      addConditionsToUser.requestTypes.SUCCESS,
      updateConditionsToUser.requestTypes.SUCCESS,
      deleteConditionsToUser.requestTypes.SUCCESS,
    ],
    loadConditionsToUsersOnChange
  );
}

export default {
  watchFetchConditionsToUsers,
  watchAddConditionsToUser,
  watchGetConditionsToUser,
  watchUpdateConditionsToUser,
  watchConditionsToUsersOnChange,
  watchDeleteConditionsToUser,
};
