import {
  fetchReports,
  fetchOneReport,
  addReport,
  updateReport,
  deleteReport,
  resentReport,
  setRecommendation,
  deleteRecommendation,
} from 'actions/reports';

const reports = () => {
  const initialState = {
    reports: null,
    selectedReport: null,
    recommendation: [],
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchReports.requestTypes.SUCCESS:
        return {
          ...state,
          reports: response,
          isSuccessful: false,
        };
      case addReport.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addReport.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addReport.requestTypes.SUCCESS:
        return {
          ...state,
          selectedReport: response,
          isSuccessful: true,
        };
      case updateReport.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateReport.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateReport.requestTypes.SUCCESS:
        return {
          ...state,
          selectedReport: response,
          isSuccessful: true,
        };
      case fetchOneReport.requestTypes.SUCCESS:
        return {
          ...state,
          selectedReport: response,
          isSuccessful: false,
        };
      case deleteReport.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
          isSuccessful: true,
        };

      case resentReport.requestTypes.SUCCESS:
        return {
          ...state,
          type: response.type,
          isSuccessful: true,
        };

      case setRecommendation.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case setRecommendation.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case setRecommendation.requestTypes.SUCCESS:
        const newArr = [...state.recommendation, response];
        return {
          ...state,
          recommendation: newArr,
          isSuccessful: true,
        };

      case deleteRecommendation.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case deleteRecommendation.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case deleteRecommendation.requestTypes.SUCCESS:
        var filtered = state?.recommendation?.filter(function(value) {
          return value !== response?.recommendation;
        });

        return {
          ...state,
          recommendation: filtered,
          isSuccessful: true,
        };

      case 'CLOSE_SIDEBAR':
        return {
          ...state,
          isSidebarClosed: true,
        };
      case 'CLEAR_AUTO_CLOSE':
        return {
          ...state,
          isSidebarClosed: false,
        };
      default:
        return state;
    }
  };
};

export default reports();
