import styled, { css } from 'styled-components';
import { Layout } from 'antd';

const content = Layout.Content;
const Content = styled(content)`
  background: #fff !important;
  padding: 15px 25px !important;
  ${props =>
    props.isFromMobileApp &&
    css`
      padding: 5px 10px !important;
    `}
`;

/** @component */
export default Content;
