import { call, takeLatest } from 'redux-saga/effects';
import { getReport } from 'actions/guest-report';
import { getGuestReportApi } from 'services/guest-report';
import fetchEntity from './fetch-entity';

const getGuestReportData = fetchEntity.bind(null, getReport.actions, getGuestReportApi);

export function* loadGetGuestReportData({ params }) {
  yield call(getGuestReportData, { ...params });
}

function* watchGetGuestReport() {
  yield takeLatest(getReport.actionName, loadGetGuestReportData);
}

export default {
  watchGetGuestReport,
};
