import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { Steps, Step, Button, Notification } from 'components';
import { Row, Col, Form, Icon } from 'antd';
import { DataSection } from 'components/Partials';
import {
  getBooking,
  getContact,
  getCurrent,
  getReport,
  getIsDone,
  getBroker,
  getInvoice,
  getClickedStep,
} from 'selectors/booking-form';
import { addBooking, updateBooking } from 'actions/bookings';
import { getAddBookingError, getUpdateBookingError } from 'selectors/bookings';
import { clearAllErrors } from 'actions/general';
import { changeSlide, handleSubmit, openStep, handleDone, cleanAll } from 'actions/booking-form';
import BrokerInfo from 'components/AddBooking/partials/BrokerInfo';
import BookingModalWrapper from './partials/BookingModalWrapper';
import GeneralInfo from './partials/GeneralInfo';
import ClientInfo from './partials/ClientInfo';
import PropertyInfo from './partials/PropertyInfo';
import Contract from './partials/Contract';
import CoverLetter from './partials/CoverLetter';
import Invoice from './partials/Invoice';
import Review from './partials/Review';
import { ReactComponent as FlagSvg } from '../../assets/imgs/flag.svg';
import { getUser } from '../../configs/localStorage';

const BookingModalContact = ({ mode, history, closeModal, modalVisible }) => {
  const currentUser = getUser();
  const dispatch = useDispatch();
  const current = useSelector(getCurrent);
  const report = useSelector(getReport);
  const contact = useSelector(getContact);
  const broker = useSelector(getBroker);
  const booking = useSelector(getBooking);
  const invoice = useSelector(getInvoice);
  const addBookingError = useSelector(getAddBookingError);
  const updateBookingError = useSelector(getUpdateBookingError);
  const isDone = useSelector(getIsDone);
  const clickedStep = useSelector(getClickedStep);

  const [disabled, setDisabled] = useState(false);
  const [arr, setArr] = useState([1]);

  useEffect(() => {
    if (isDone) {
      const combinedValues = {
        ...booking,
        report,
        // googleCalendarEventId: booking?.googleCalendarEventId,
        contact: Object.keys(contact).length === 0 ? { skipped: true } : contact,
        broker: { ...broker, profileId: currentUser?.profileId },
        invoice,
      };
      dispatch(
        mode === 'edit' && booking.id
          ? updateBooking.request({ id: combinedValues.id, values: combinedValues })
          : addBooking.request(combinedValues)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDone]);
  useEffect(() => {
    const errors = addBookingError || updateBookingError;
    if (errors) {
      if (errors.message && errors.message.errors) {
        errors.message.errors.map(error => Notification.warning(error.message));
      }

      dispatch(clearAllErrors.request());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addBookingError, updateBookingError]);

  const handleChangeSlide = slide => {
    if (clickedStep == null) {
      dispatch(changeSlide.request({ slide }));
    } else {
      dispatch(changeSlide.request({ slide: clickedStep }));
    }
  };

  const handleDoneSteps = () => {
    if (clickedStep == null) {
      dispatch(handleDone.request());
    } else {
      dispatch(changeSlide.request({ slide: clickedStep }));
    }
  };

  const steps = [
    {
      title: 'General Info',
      content: <GeneralInfo handleChangeSlide={handleChangeSlide} />,
    },
    {
      title: 'Client Info',
      content: (
        <ClientInfo
          clientList
          handleChangeSlide={handleChangeSlide}
          setDisabled={setDisabled}
          modalVisible={modalVisible}
          setArr={setArr}
          arr={arr}
        />
      ),
    },
    {
      title: 'Broker Info',
      content: (
        <BrokerInfo
          brokerList
          handleChangeSlide={handleChangeSlide}
          setDisabled={setDisabled}
          setArr={setArr}
          arr={arr}
          modalVisible={modalVisible}
        />
      ),
    },
    {
      title: 'Property info',
      content: <PropertyInfo handleChangeSlide={handleChangeSlide} />,
    },
    {
      title: 'Agreement',
      content: <Contract handleChangeSlide={handleChangeSlide} />,
    },
    {
      title: 'Cover Letter',
      content: <CoverLetter handleChangeSlide={handleChangeSlide} />,
    },
    {
      title: 'Invoice',
      content: <Invoice handleChangeSlide={handleChangeSlide} mode={mode} />,
    },
    {
      title: 'Review',
      content: <Review handleDoneSteps={handleDoneSteps} />,
    },
  ];

  const next = () => {
    dispatch(handleSubmit.request());
  };

  const prev = () => {
    dispatch(changeSlide.request({ slide: current - 1 }));
  };

  const handleStepChange = step => {
    dispatch(openStep.request({ clickedStep: step }));
    dispatch(handleSubmit.request());
  };

  return (
    <BookingModalWrapper>
      <Row className='booking-content'>
        <div
          className='back-btn'
          onClick={() => {
            history.push('/');
            closeModal();
          }}
        >
          <Icon type='arrow-left' />
          <span>Back to dashboard</span>
        </div>
        <Col xs={24} sm={10} md={8} className='booking-steps'>
          <DataSection>
            <Steps
              current={current}
              direction='vertical'
              onChange={mode === 'edit' && handleStepChange}
            >
              {steps.map((item, idx) => (
                <Step
                  key={item.title}
                  title={item.title}
                  onClick={() => {
                    if (arr.includes(idx + 1)) {
                      dispatch(changeSlide.request({ slide: idx }));
                    }
                  }}
                />
              ))}
              <Step className='step-flag' icon={<FlagSvg />} />
            </Steps>
          </DataSection>
        </Col>

        <Col xs={24} sm={14} className='booking-forms'>
          <div>{steps[current].content}</div>
          <Row className='steps-action'>
            {current > 0 && (
              <>
                <div className='back-btn' onClick={() => prev()}>
                  <Icon type='arrow-left' />
                </div>
                <div className='back-btn' onClick={() => prev()}>
                  <div style={{ marginLeft: '8px' }}>Previous Step</div>
                </div>
              </>
            )}
            <Button
              className='cancel-btn'
              disabled={disabled}
              onClick={async () => {
                await dispatch(cleanAll.request());
                await closeModal();
              }}
            >
              Cancel
            </Button>
            {current < steps.length - 1 && (
              <Button
                className='next-btn'
                disabled={disabled}
                type='primary'
                onClick={() => {
                  next();
                  setArr([...arr, current + 2]);
                }}
              >
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type='primary' className='next-btn' onClick={() => handleDoneSteps()}>
                Done
              </Button>
            )}
          </Row>
        </Col>
      </Row>
    </BookingModalWrapper>
  );
};

export default withRouter(React.memo(Form.create({ name: 'booking_form' })(BookingModalContact)));
