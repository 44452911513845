import { notifyUser } from 'actions/auth-management';

const authManagement = () => {
  const initialState = { modalVisible: false };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case notifyUser.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case 'SHOW_MODAL':
        return {
          ...state,
          modalVisible: true,
        };
      case 'CLOSE_MODAL':
        return {
          ...state,
          modalVisible: false,
        };
      default:
        return state;
    }
  };
};

export default authManagement();
