import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrokersForm, Button } from 'components';
import { Form } from 'antd';
import { getBroker, getCurrent, getIsSubmitted, getShowBrokers } from 'selectors/booking-form';
import { cancelSubmit, changeSlide, addBrokerInfo, handleSubmit } from 'actions/booking-form';

const BrokerInfo = ({ form, handleChangeSlide, setDisabled, setArr, arr, modalVisible }) => {
  const dispatch = useDispatch();
  const current = useSelector(getCurrent);
  const broker = useSelector(getBroker);
  const showBrokers = useSelector(getShowBrokers);
  const isSubmitted = useSelector(getIsSubmitted);

  const [isSkipped, setIsSkipped] = useState(false);

  useEffect(() => {
    if (isSubmitted) {
      if (isSkipped) {
        dispatch(addBrokerInfo.request({ skipped: true }));
        dispatch(changeSlide.request({ slide: current + 1 }));
      }
      form.validateFields((err, values) => {
        if (!err) {
          if (!broker || (broker && !showBrokers)) {
            dispatch(addBrokerInfo.request(values));
          }

          handleChangeSlide(current + 1);
        } else {
          dispatch(cancelSubmit.request());
        }

        return false;
      });
    }
    dispatch(cancelSubmit.request());
    // eslint-disable-next-line
  }, [isSubmitted, current, dispatch, form, broker, showBrokers, isSkipped]);

  return (
    <>
      <Button
        type='outlined'
        className='skip-btn'
        onClick={() => {
          setIsSkipped(true);
          setArr([...arr, 4]);
          dispatch(handleSubmit.request());
        }}
      >
        Skip
      </Button>
      <BrokersForm
        form={form}
        broker={broker}
        brokerList
        setDisabled={setDisabled}
        modalVisible={modalVisible}
      />
    </>
  );
};

export default React.memo(Form.create({ name: 'broker_info_form' })(BrokerInfo));
