import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { Title } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import {
  getBooking,
  getContact,
  getBroker,
  getInvoice,
  getIsSubmitted,
} from 'selectors/booking-form';
import { fetchOneInvoice } from 'actions/invoices';
import { getSelectedInvoice } from 'selectors/invoices';
import moment from 'moment';

const Review = ({ handleDoneSteps }) => {
  const dispatch = useDispatch();
  const isSubmitted = useSelector(getIsSubmitted);
  const contact = useSelector(getContact);
  const broker = useSelector(getBroker);
  const booking = useSelector(getBooking);
  const invoice = useSelector(getInvoice);
  const selectedInvoice = useSelector(getSelectedInvoice);

  useEffect(() => {
    if (!invoice && booking && booking.invoiceId) {
      dispatch(fetchOneInvoice.request({ id: booking.invoiceId }));
    }
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (isSubmitted) {
      handleDoneSteps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted]);

  return (
    <Row gutter={(24, 24)} className='review'>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Title level={3} mb={16} color='#3936e8'>
              General
            </Title>
          </Col>
          {booking.address && (
            <Col span={12}>
              <Title level={4}>Property address: </Title>
              <p>{booking.address}</p>
            </Col>
          )}
          {booking.addressLine2 && (
            <Col span={12}>
              <Title level={4}>Address 2</Title>
              <p>{booking.addressLine2}</p>
            </Col>
          )}
          {booking.date && (
            <Col span={12}>
              <Title level={4}>Date</Title>
              <p>{moment(booking.date).format('LL')}</p>
            </Col>
          )}
          {booking.time && (
            <Col span={12}>
              <Title level={4}>Time</Title>
              <p>{booking.time}</p>
            </Col>
          )}
        </Row>
      </Col>
      {contact && contact.name && (
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Title level={3} mb={16} color='#3936e8'>
                Client
              </Title>
            </Col>
            {contact.name && (
              <Col span={12}>
                <Title level={4}>Client name: </Title>
                <p>{contact.name}</p>
              </Col>
            )}
            {contact.email && (
              <Col span={12}>
                <Title level={4}>Client email: </Title>
                <p>{contact.email}</p>
              </Col>
            )}
            {(contact.address ||
              contact.city ||
              contact.country ||
              contact.province ||
              contact.postal) && (
              <Col span={11}>
                <Title level={4}>Client address: </Title>
                <p>
                  {contact?.address} {contact?.postal} {contact?.city} {contact?.province}{' '}
                  {contact?.country}
                </p>
              </Col>
            )}
            {contact.notes && (
              <Col span={12} offset={1}>
                <Title level={4}>Notes: </Title>
                <p>{contact.notes}</p>
              </Col>
            )}
          </Row>
        </Col>
      )}
      {broker && broker.name && (
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Title level={3} mb={16} color='#3936e8'>
                Broker
              </Title>
            </Col>
            {broker.name && (
              <Col span={12}>
                <Title level={4}>Broker name: </Title>
                <p>{broker.name}</p>
              </Col>
            )}
            {broker.email && (
              <Col span={12}>
                <Title level={4}>Broker email: </Title>
                <p>{broker.email}</p>
              </Col>
            )}
            {(broker.address ||
              broker.city ||
              broker.country ||
              broker.province ||
              broker.postal) && (
              <Col span={11}>
                <Title level={4}>Broker address: </Title>
                <p>
                  {broker?.address} {broker?.postal} {broker?.city} {broker?.province}
                  {broker?.country}
                </p>
              </Col>
            )}
            {broker.notes && (
              <Col span={12} offset={1}>
                <Title level={4}>Notes: </Title>
                <p>{broker.notes}</p>
              </Col>
            )}
          </Row>
        </Col>
      )}
      {invoice && (
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Title level={3} mb={16} color='#3936e8'>
                Invoice
              </Title>
            </Col>
            <Col span={12}>
              <Title level={4}>Subtotal: </Title>
              <p>{invoice.subtotal && `$ ${invoice.subtotal.toFixed(2)}`}</p>
            </Col>
            <Col span={12}>
              <Title level={4}>HST: </Title>
              <p>{invoice.hst && `$ ${invoice.hst.toFixed(2)}`}</p>
            </Col>
            <Col span={12}>
              <Title level={4}>Total: </Title>
              <p>{invoice.total && `$ ${invoice.total.toFixed(2)}`}</p>
            </Col>
          </Row>
        </Col>
      )}
      {!invoice && selectedInvoice && (
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Title level={3} mb={16} color='#3936e8'>
                Invoice
              </Title>
            </Col>
            <Col span={12}>
              <Title level={4}>Subtotal: </Title>
              <p>{selectedInvoice.subtotal && `$ ${selectedInvoice.subtotal.toFixed(2)}`}</p>
            </Col>
            <Col span={12}>
              <Title level={4}>HST: </Title>
              <p>{selectedInvoice.hst && `$ ${selectedInvoice.hst.toFixed(2)}`}</p>
            </Col>
            <Col span={12}>
              <Title level={4}>Total: </Title>
              <p>{selectedInvoice.total && `$ ${selectedInvoice.total.toFixed(2)}`}</p>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default Review;
