import styled from 'styled-components';
import { Calendar as calendar } from 'antd';

const Calendar = styled(calendar)`
  .ant-fullcalendar-fullscreen .ant-fullcalendar-content {
    overflow: hidden !important;
  }
  th.ant-fullcalendar-column-header {
    background: none !important;
    color: #aaaaaa !important;
    font-weight: 600 !important;
  }
  th.ant-fullcalendar-column-header > span {
    font-weight: 600 !important;
  }
  .events {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ant-badge-status-text {
    font-size: 11px;
  }
`;

/** @component */
export default Calendar;
