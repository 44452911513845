import { makeRequestAction } from './index';

/**
 * Fetch building-types-to-users
 */
export const fetchBuildingTypesToUsers = makeRequestAction('FETCH_BUILDING_TYPES_TO_USERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addBuildingTypesToUser = makeRequestAction('ADD_BUILDING_TYPES_TO_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateBuildingTypesToUser = makeRequestAction('UPDATE_BUILDING_TYPES_TO_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneBuildingTypesToUser = makeRequestAction('GET_BUILDING_TYPES_TO_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteBuildingTypesToUser = makeRequestAction('DELETE_BUILDING_TYPES_TO_USER', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
