import { API_ROOT } from 'configs/env-vars';
import { makeRequestAction } from './index';

export const sendReportEmail = makeRequestAction('SEND_REPORT_EMAIL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const sendApprovalEmail = makeRequestAction('SEND_APPROVAL_EMAIL', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addEmailUploads = makeRequestAction('ADD_EMAIL_UPLOADS', {
  onSuccess(params, response) {
    return {
      files: response.map(file => ({
        uid: file.hashedId,
        name: file.fileName,
        url: `${API_ROOT}/file-storage/${file.hashedId}`,
        source: file.source,
      })),
    };
  },
});
