import styled, { css } from 'styled-components';
import { Select as select } from 'antd';

const Select = styled(select)`
  .ant-select-selection {
    border: 1px solid #e5e5e5 !important;
    border-radius: 5px !important;
    background-color: #f1f1f1 !important;
    &:focus-within {
      background: #fff !important;
    }
  }

  ${props =>
    props.height &&
    css`
      .ant-select-selection {
        height: ${props.height};
      }
    `}

    ${props =>
      props.padding &&
      css`
        .ant-select-selection {
          padding: ${props.padding};
        }
      `}
  
  ${props =>
    props.renderSeparately === true &&
    css`
      .ant-select-selection__rendered {
        ul {
          display: inline-grid !important;
          grid-template-columns: 100%;
        }
      }
    `}

  ${props =>
    props.transparent === true &&
    css`
      .ant-select-selection {
        background: none !important;
        border: none !important;
      }
      .ant-select-selection__placeholder {
        font-weight: normal !important;
      }
    `}
`;
export default Select;
