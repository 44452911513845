import {
  fetchBuildingTypes,
  fetchOneBuildingType,
  addBuildingType,
  updateBuildingType,
  deleteBuildingType,
} from 'actions/building-types';

const buildingTypes = () => {
  const initialState = {
    buildingTypes: null,
    selectedBuildingType: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchBuildingTypes.requestTypes.SUCCESS:
        return {
          selectedBuildingType: null,
          buildingTypes: response,
        };
      case addBuildingType.requestTypes.REQUEST:
      case deleteBuildingType.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addBuildingType.requestTypes.FAILURE:
      case deleteBuildingType.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addBuildingType.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBuildingType: response,
          isSuccessful: true,
        };
      case updateBuildingType.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateBuildingType.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateBuildingType.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBuildingType: response,
          isSuccessful: true,
        };
      case fetchOneBuildingType.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBuildingType: response,
        };
      case deleteBuildingType.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
          isSuccessful: true,
        };
      default:
        return state;
    }
  };
};

export default buildingTypes();
