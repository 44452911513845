import { makeRequestAction } from './index';

export const getAdvice = makeRequestAction('GET_ADVICE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateAdvice = makeRequestAction('UPDATE_ADVICE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
