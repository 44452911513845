import {
  fetchTemplates,
  fetchOneTemplate,
  addTemplate,
  updateTemplate,
  addTemplateSection,
  deleteTemplate,
  updateTemplateStatus,
} from 'actions/templates';

const templates = () => {
  const initialState = {
    templates: null,
    selectedTemplate: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchTemplates.requestTypes.SUCCESS:
        return {
          ...state,
          templates: response,
        };
      case fetchOneTemplate.requestTypes.SUCCESS:
        return {
          ...state,
          selectedTemplate: response,
        };
      case deleteTemplate.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - response.ids.length,
        };
      case addTemplate.requestTypes.REQUEST:
      case updateTemplate.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addTemplate.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      case addTemplate.requestTypes.FAILURE:
      case updateTemplate.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateTemplate.requestTypes.SUCCESS:
        return {
          ...state,
          selectedTemplate: response,
          isSuccessful: true,
        };

      case updateTemplateStatus.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateTemplateStatus.requestTypes.SUCCESS:
        return {
          ...state,
          selectedTemplate: response,
          isSuccessful: true,
        };
      case addTemplateSection.requestTypes.REQUEST:
        return {
          ...state,
          isAddSectionSuccessful: false,
        };
      case addTemplateSection.requestTypes.FAILURE:
        return {
          ...state,
          isAddSectionSuccessful: false,
        };
      case addTemplateSection.requestTypes.SUCCESS:
        return {
          ...state,
          selectedTemplate: response,
          isAddSectionSuccessful: true,
        };
      default:
        return state;
    }
  };
};

export default templates();
