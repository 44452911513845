import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchDwellingsToUsers,
  fetchOneDwellingsToUser,
  addDwellingsToUser,
  updateDwellingsToUser,
  deleteDwellingsToUser,
} from 'actions/dwellings-to-users';
import {
  fetchAllDwellingsToUsersApi,
  fetchOneDwellingsToUserApi,
  addDwellingsToUserApi,
  updateDwellingsToUserApi,
  deleteDwellingsToUserApi,
} from 'services/dwellings-to-users';
import fetchEntity from './fetch-entity';

const fetchDwellingsToUsersData = fetchEntity.bind(null, fetchDwellingsToUsers.actions, fetchAllDwellingsToUsersApi);

const fetchDwellingsToUserData = fetchEntity.bind(null, fetchOneDwellingsToUser.actions, fetchOneDwellingsToUserApi);

const fetchAddDwellingsToUser = fetchEntity.bind(null, addDwellingsToUser.actions, addDwellingsToUserApi);

const fetchUpdateDwellingsToUser = fetchEntity.bind(null, updateDwellingsToUser.actions, updateDwellingsToUserApi);

const fetchDeleteDwellingsToUser = fetchEntity.bind(null, deleteDwellingsToUser.actions, deleteDwellingsToUserApi);

export function* loadFetchDwellingsToUsers({ params }) {
  yield call(fetchDwellingsToUsersData, { ...params });
}

export function* loadGetDwellingsToUser({ params }) {
  yield put(fetchOneDwellingsToUser.actions.failure({}, undefined));
  yield call(fetchDwellingsToUserData, params);
}

export function* loadAddDwellingsToUser({ params }) {
  yield call(fetchAddDwellingsToUser, params);
}

export function* loadUpdateDwellingsToUser({ params }) {
  yield call(fetchUpdateDwellingsToUser, params);
}

export function* loadDeleteDwellingsToUser({ params }) {
  yield call(fetchDeleteDwellingsToUser, { ...params });
}

function* watchFetchDwellingsToUsers() {
  yield takeLatest(fetchDwellingsToUsers.actionName, loadFetchDwellingsToUsers);
}

function* watchAddDwellingsToUser() {
  yield takeLatest(addDwellingsToUser.actionName, loadAddDwellingsToUser);
}

function* watchUpdateDwellingsToUser() {
  yield takeLatest(updateDwellingsToUser.actionName, loadUpdateDwellingsToUser);
}

function* watchDeleteDwellingsToUser() {
  yield takeLatest(deleteDwellingsToUser.actionName, loadDeleteDwellingsToUser);
}

function* watchGetDwellingsToUser() {
  yield takeLatest(fetchOneDwellingsToUser.actionName, loadGetDwellingsToUser);
}

export function* loadDwellingsToUsersOnChange({ params }) {
  const dwellingsToUsers = yield select(state => state.dwellingsToUsers);
  const { total, skip, limit } = dwellingsToUsers;
  if (skip && skip >= total) {
    yield call(fetchDwellingsToUsersData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchDwellingsToUsersData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchDwellingsToUsersOnChange() {
  yield takeLatest([deleteDwellingsToUser.requestTypes.SUCCESS], loadDwellingsToUsersOnChange);
}

export default {
  watchFetchDwellingsToUsers,
  watchAddDwellingsToUser,
  watchGetDwellingsToUser,
  watchUpdateDwellingsToUser,
  watchDwellingsToUsersOnChange,
  watchDeleteDwellingsToUser,
};
