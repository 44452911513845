import {
  fetchPayments,
  fetchOnePayment,
  addPayment,
  updatePayment,
  deletePayment,
  getClientSecret,
} from 'actions/payments';

const payments = () => {
  const initialState = {
    payments: null,
    selectedPayment: null,
    clientSecret: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchPayments.requestTypes.SUCCESS:
        return {
          selectedPayment: null,
          payments: response,
        };
      case getClientSecret.requestTypes.SUCCESS:
        return {
          ...initialState,
          clientSecret: response,
        };
      case addPayment.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addPayment.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addPayment.requestTypes.SUCCESS:
        return {
          ...state,
          selectedPayment: response,
          isSuccessful: true,
        };
      case updatePayment.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updatePayment.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updatePayment.requestTypes.SUCCESS:
        return {
          ...state,
          selectedPayment: response,
          isSuccessful: true,
        };
      case fetchOnePayment.requestTypes.SUCCESS:
        return {
          ...state,
          selectedPayment: response,
        };
      case deletePayment.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default payments();
