import { makeRequestAction } from './index';

/**
 * Fetch invoices
 */
export const fetchInvoices = makeRequestAction('FETCH_INVOICES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addInvoice = makeRequestAction('ADD_INVOICE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateInvoice = makeRequestAction('UPDATE_INVOICE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneInvoice = makeRequestAction('GET_INVOICE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteInvoice = makeRequestAction('DELETE_INVOICE', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
