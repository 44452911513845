import React, { useState } from 'react';
import { Button } from 'components';
import { DatePicker } from 'antd';
import moment from 'moment';
import SearchFilterWrapper from './wrappers/SearchFilterWrapper';

const { RangePicker } = DatePicker;

const useDateRangeFilter = type => {
  const [selectedRange, setSelectedRange] = useState();
  const addColumnSearch = () => {
    const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
        <>
          <SearchFilterWrapper>
            <RangePicker
              value={selectedRange}
              separator='-'
              onChange={(date, dateString) => {
                setSelectedRange(date);
                const selectedKeys = getDaysBetweenDates(dateString[0], dateString[1]);
                setSelectedKeys([...selectedKeys]);
              }}
            />
            <div className={'buttons-wrapper'}>
              <Button type={'primary'} onClick={() => handleSearch(confirm)}>
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)}>Reset</Button>
            </div>
          </SearchFilterWrapper>
        </>
      ),
      render: text => text,
    });

    const handleSearch = confirm => {
      confirm();
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSelectedRange();
    };
    const getDaysBetweenDates = (startDate, endDate) => {
      const now = moment(startDate);
      const end = moment(endDate);
      const dates = [];
      while (now.isBefore(endDate) || now.isSame(end)) {
        dates.push(now.format('YYYY-MM-DD'));
        now.add(1, 'days');
      }
      return dates;
    };

    return getColumnSearchProps;
  };

  return addColumnSearch(type);
};

export default useDateRangeFilter;
