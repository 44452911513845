import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

const Nav = styled.div`
  a {
    color: #000 !important;
    margin: 0 25px;
  }

  position: absolute;
  bottom: 0px;
  width: 100%;
`;

Nav.propTypes = {
  nodes: propTypes.object,
};

Nav.defaultProps = {
  nodes: {},
};

const NavComponent = ({ nodes }) =>
  nodes && (
    <Row justify={'center'} gutter={[24, 0]}>
      <Col span={24}>
        <Nav>
          {nodes.map((node, index) => {
            return (
              <Col style={{textAlign: 'center'}} xs={24} md={12}>
                <Link
                  key={`key-${index}`}
                  to={{ pathname: node.url, state: node.label }}
                  target='_blank'
                >
                  {node.label}
                </Link>
              </Col>
            );
          })}
        </Nav>
      </Col>
    </Row>
  );

export default NavComponent;
