import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchBuildingTypesToUsers,
  fetchOneBuildingTypesToUser,
  addBuildingTypesToUser,
  updateBuildingTypesToUser,
  deleteBuildingTypesToUser,
} from 'actions/building-types-to-users';
import {
  fetchAllBuildingTypesToUsersApi,
  fetchOneBuildingTypesToUserApi,
  addBuildingTypesToUserApi,
  updateBuildingTypesToUserApi,
  deleteBuildingTypesToUserApi,
} from 'services/building-types-to-users';
import fetchEntity from './fetch-entity';

const fetchBuildingTypesToUsersData = fetchEntity.bind(null, fetchBuildingTypesToUsers.actions, fetchAllBuildingTypesToUsersApi);

const fetchBuildingTypesToUserData = fetchEntity.bind(null, fetchOneBuildingTypesToUser.actions, fetchOneBuildingTypesToUserApi);

const fetchAddBuildingTypesToUser = fetchEntity.bind(null, addBuildingTypesToUser.actions, addBuildingTypesToUserApi);

const fetchUpdateBuildingTypesToUser = fetchEntity.bind(null, updateBuildingTypesToUser.actions, updateBuildingTypesToUserApi);

const fetchDeleteBuildingTypesToUser = fetchEntity.bind(null, deleteBuildingTypesToUser.actions, deleteBuildingTypesToUserApi);

export function* loadFetchBuildingTypesToUsers({ params }) {
  yield call(fetchBuildingTypesToUsersData, { ...params });
}

export function* loadGetBuildingTypesToUser({ params }) {
  yield put(fetchOneBuildingTypesToUser.actions.failure({}, undefined));
  yield call(fetchBuildingTypesToUserData, params);
}

export function* loadAddBuildingTypesToUser({ params }) {
  yield call(fetchAddBuildingTypesToUser, params);
}

export function* loadUpdateBuildingTypesToUser({ params }) {
  yield call(fetchUpdateBuildingTypesToUser, params);
}

export function* loadDeleteBuildingTypesToUser({ params }) {
  yield call(fetchDeleteBuildingTypesToUser, { ...params });
}

function* watchFetchBuildingTypesToUsers() {
  yield takeLatest(fetchBuildingTypesToUsers.actionName, loadFetchBuildingTypesToUsers);
}

function* watchAddBuildingTypesToUser() {
  yield takeLatest(addBuildingTypesToUser.actionName, loadAddBuildingTypesToUser);
}

function* watchUpdateBuildingTypesToUser() {
  yield takeLatest(updateBuildingTypesToUser.actionName, loadUpdateBuildingTypesToUser);
}

function* watchDeleteBuildingTypesToUser() {
  yield takeLatest(deleteBuildingTypesToUser.actionName, loadDeleteBuildingTypesToUser);
}

function* watchGetBuildingTypesToUser() {
  yield takeLatest(fetchOneBuildingTypesToUser.actionName, loadGetBuildingTypesToUser);
}

export function* loadBuildingTypesToUsersOnChange({ params }) {
  const buildingTypesToUsers = yield select(state => state.buildingTypesToUsers);
  const { total, skip, limit } = buildingTypesToUsers;
  if (skip && skip >= total) {
    yield call(fetchBuildingTypesToUsersData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchBuildingTypesToUsersData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchBuildingTypesToUsersOnChange() {
  yield takeLatest([deleteBuildingTypesToUser.requestTypes.SUCCESS], loadBuildingTypesToUsersOnChange);
}

export default {
  watchFetchBuildingTypesToUsers,
  watchAddBuildingTypesToUser,
  watchGetBuildingTypesToUser,
  watchUpdateBuildingTypesToUser,
  watchBuildingTypesToUsersOnChange,
  watchDeleteBuildingTypesToUser,
};
