import React from 'react';
import styled from 'styled-components';
import { Table as table } from 'antd';
import useBreakpoint from 'utils/useBreakpoint';

const CustomizedTable = styled(table)`
  .ant-table {
    border-color: #e5e5e5;
    border-style: solid;
    border-width: 1px 1px 0 1px;
    border-radius: 5px;
  }

  .ant-table-thead th {
    background: none !important;
    color: #aaaaaa;
    font-weight: 600;
  }
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: #f3f3f3;
  }
  .ant-table-tbody > tr > td {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .withBg {
    border: 1px solid #e5ded5;
    background-color: rgba(245, 218, 176, 0.2);
  }
`;

const Table = ({ columns, mobileColumns, ...rest }) => {
  const isMobile = useBreakpoint();
  return (
    <CustomizedTable columns={isMobile && mobileColumns ? mobileColumns : columns} {...rest} />
  );
};

/** @component */
export default Table;
