import { call, takeLatest, put } from 'redux-saga/effects';
import {
  fetchContracts,
  fetchUserContracts,
  fetchOneContract,
  addContract,
  deleteContract,
  updateContract,
} from 'actions/contracts';
import {
  fetchAllContractsApi,
  addContractApi,
  deleteContractApi,
  fetchOneContractApi,
  updateContractApi,
} from 'services/contracts';
import fetchEntity from './fetch-entity';

const fetchContractsData = fetchEntity.bind(null, fetchContracts.actions, fetchAllContractsApi);
const fetchUserContractsData = fetchEntity.bind(
  null,
  fetchUserContracts.actions,
  fetchAllContractsApi
);
const fetchContractData = fetchEntity.bind(null, fetchOneContract.actions, fetchOneContractApi);
const fetchAddContract = fetchEntity.bind(null, addContract.actions, addContractApi);
const fetchDeleteContract = fetchEntity.bind(null, deleteContract.actions, deleteContractApi);
const fetchUpdateContract = fetchEntity.bind(null, updateContract.actions, updateContractApi);

export function* loadFetchContracts({ params }) {
  yield call(fetchContractsData, { ...params });
}
export function* loadFetchUserContracts({ params }) {
  yield call(fetchUserContractsData, { ...params });
}
export function* loadGetContract({ params }) {
  yield put(fetchOneContract.actions.failure({}, undefined));
  yield call(fetchContractData, params);
}
export function* loadAddContract({ params }) {
  yield call(fetchAddContract, params);
}
export function* loadDeleteContract({ params }) {
  yield call(fetchDeleteContract, { ...params });
}
export function* loadUpdateContract({ params }) {
  yield call(fetchUpdateContract, { ...params });
}
function* watchFetchContracts() {
  yield takeLatest(fetchContracts.actionName, loadFetchContracts);
}
function* watchFetchUserContracts() {
  yield takeLatest(fetchUserContracts.actionName, loadFetchUserContracts);
}
function* watchGetContract() {
  yield takeLatest(fetchOneContract.actionName, loadGetContract);
}
function* watchAddContract() {
  yield takeLatest(addContract.actionName, loadAddContract);
}
function* watchDeleteContract() {
  yield takeLatest(deleteContract.actionName, loadDeleteContract);
}
function* watchUpdateContract() {
  yield takeLatest(updateContract.actionName, loadUpdateContract);
}

export default {
  watchFetchContracts,
  watchFetchUserContracts,
  watchGetContract,
  watchAddContract,
  watchDeleteContract,
  watchUpdateContract,
};
