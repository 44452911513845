import { makeRequestAction } from './index';

/**
 * Fetch limitations-users
 */
export const fetchLimitationsUsers = makeRequestAction('FETCH_LIMITATIONS_USERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addLimitationsUser = makeRequestAction('ADD_LIMITATIONS_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateLimitationsUser = makeRequestAction('UPDATE_LIMITATIONS_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneLimitationsUser = makeRequestAction('GET_LIMITATIONS_USER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteLimitationsUser = makeRequestAction('DELETE_LIMITATIONS_USER', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
