import {
  fetchBuildingTypes,
  fetchOneBuildingType,
  addBuildingType,
  updateBuildingType,
} from 'actions/building-types';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getBuildingTypes = state => state.buildingTypes;
export const getBuildingTypesState = createSelector(getBuildingTypes, data => {
  if (!data.buildingTypes) {
    return [];
  }
  if (data.buildingTypes.data) {
    return data.buildingTypes;
  }
  const buildingTypesData = Object.entries(data.buildingTypes).map(b => b[1]);
  return { total: buildingTypesData.length, data: buildingTypesData };
});

export const getSuccessState = createSelector(getBuildingTypes, data => data.isSuccessful);
export const getSelectedBuildingType = createSelector(
  getBuildingTypes,
  data => data.selectedBuildingType
);

export const buildingTypesValuesLoading = createLoadingSelector(fetchBuildingTypes.actionName)();
export const buildingTypesValuesError = createErrorSelector(fetchBuildingTypes.actionName)();

export const getAddBuildingTypeLoading = createLoadingSelector(addBuildingType.actionName)();
export const getAddBuildingTypeError = createErrorSelector(addBuildingType.actionName)();

export const getUpdateBuildingTypeLoading = createLoadingSelector(updateBuildingType.actionName)();
export const getUpdateBuildingTypeError = createErrorSelector(updateBuildingType.actionName)();

export const getSelectedBuildingTypeLoading = createLoadingSelector(
  fetchOneBuildingType.actionName
)();
export const getSelectedBuildingTypeError = createErrorSelector(fetchOneBuildingType.actionName)();
