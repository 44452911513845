const userModal = () => {
  const initialState = {
    isShown: false,
    user: null,
    type: 'CREATE',
  };

  return (state = initialState, { type, params }) => {
    switch (type) {
      case 'CHANGE_MODAL_STATE':
        return {
          ...params,
        };
      default:
        return state;
    }
  };
};

export default userModal();
