import React, { memo, useState, useEffect } from 'react';
import { fetchHistoryApi } from 'services/history-log';
import moment from 'moment';

const HistoryLog = () => {
  const [historyData, setHistoryData] = useState();

  useEffect(() => {
    fetchHistoryApi().then(res => setHistoryData(res?.data?.data));
  }, []);

  return (
    <>
      {historyData?.reverse()?.map(el => (
        <>
          <b>{moment(el?.releaseDate).format('LL')}</b>
          <div
            dangerouslySetInnerHTML={{ __html: el?.releaseNotes }}
            style={{ marginBottom: '40px', marginLeft: '8px' }}
          />
        </>
      ))}
    </>
  );
};

export default memo(HistoryLog);
