import { fetchBookings, fetchOneBooking, addBooking, updateBooking } from 'actions/bookings';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getBookings = state => state.bookings;
export const getBookingsState = createSelector(getBookings, data =>
  data.bookings ? data.bookings : []
);

export const getSuccessState = createSelector(getBookings, data => data.isSuccessful);
export const getSelectedBooking = createSelector(getBookings, data => data.selectedBooking);

export const bookingsValuesLoading = createLoadingSelector(fetchBookings.actionName)();
export const bookingsValuesError = createErrorSelector(fetchBookings.actionName)();

export const getAddBookingLoading = createLoadingSelector(addBooking.actionName)();
export const getAddBookingError = createErrorSelector(addBooking.actionName)();

export const getUpdateBookingLoading = createLoadingSelector(updateBooking.actionName)();
export const getUpdateBookingError = createErrorSelector(updateBooking.actionName)();
export const getUpdateSuccessState = createSelector(getBookings, data => data.isUpdateSuccessful);

export const getSelectedBookingLoading = createLoadingSelector(fetchOneBooking.actionName)();
export const getSelectedBookingError = createErrorSelector(fetchOneBooking.actionName)();
