import { fetchContacts, fetchOneContact, addContact, updateContact } from 'actions/contacts';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getContacts = state => state.contacts;
export const getContactsState = createSelector(getContacts, data => {
  if (!data.contacts) {
    return [];
  }
  if (data.contacts.data) {
    return data.contacts;
  }
  const contactsData = Object.entries(data.contacts).map(c => c[1]);
  return { total: contactsData.length, data: contactsData };
});

export const getSuccessState = createSelector(getContacts, data => data.isSuccessful);
export const getSelectedContact = createSelector(getContacts, data =>
  data.selectedContact ? data.selectedContact : {}
);

export const contactsValuesLoading = createLoadingSelector(fetchContacts.actionName)();
export const contactsValuesError = createErrorSelector(fetchContacts.actionName)();

export const updateContactLoading = createLoadingSelector(updateContact.actionName)();
export const updateContactError = createErrorSelector(updateContact.actionName)();

export const getAddContactLoading = createLoadingSelector(addContact.actionName)();
export const getAddContactError = createErrorSelector(addContact.actionName)();

export const getSelectedContactLoading = createLoadingSelector(fetchOneContact.actionName)();
export const getSelectedContactError = createErrorSelector(fetchOneContact.actionName)();
