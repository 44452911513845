import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ImageEditor, Button } from 'components';
import { Icon } from 'antd';
import {
  getFormUploads,
  addFormUploadsLoading,
  getBse64Uploads,
  addBase64UploadsLoading,
} from 'selectors/templates';
import { addBase64Uploads, addFormUploads, deleteFormUploads } from 'actions/templates';
import MediaUpload from './MediaUpload';
import { getBase64FromSyncRef } from '../../utils/etc';

const MediaField = ({ profile, form, field }) => {
  const editorRef = useRef(null);
  const dispatch = useDispatch();
  const uploads = useSelector(getFormUploads);
  const base64Uploads = useSelector(getBse64Uploads);
  const fileListLoading = useSelector(addFormUploadsLoading);
  const base64Loading = useSelector(addBase64UploadsLoading);
  const [isVisible, setIsVisible] = useState(false);
  const [image, setImage] = useState();
  const [fileList, setFileList] = useState(
    // eslint-disable-next-line no-nested-ternary
    profile && profile[field] ? [profile[field]] : []
  );
  const { id } = profile || {};

  const handleOk = () => {
    const file = getBase64FromSyncRef(editorRef);

    if (file) {
      dispatch(
        addBase64Uploads.request({
          file,
          source: field,
          uploadType: 'base64',
          id,
        })
      );
    }
  };

  const handlePreview = e => {
    setImage(e);
    setIsVisible(true);
  };

  useEffect(() => {
    const files = uploads;
    if (
      files &&
      files[0] &&
      files[0].source &&
      +files[0].source.id === id &&
      files[0].source.name === field
    ) {
      const uids = fileList.map(file => file.uid);

      if (!uids.includes(files[0].uid)) {
        const updatedList = [...fileList, ...files];
        setFileList(updatedList);

        form.setFieldsValue({ [field]: updatedList?.[0] });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploads]);

  useEffect(() => {
    const files = base64Uploads;
    if (
      files &&
      files[0] &&
      files[0].source &&
      files[0].source.id === id &&
      files[0].source.name === field
    ) {
      const uids = fileList.map(file => file.uid);

      if (!uids.includes(files[0].uid)) {
        const oldFileIndex = fileList.findIndex(file => file.uid === image.uid);
        const updatedList = [...fileList];
        dispatch(
          deleteFormUploads.request({
            id: fileList[oldFileIndex].uid,
          })
        );
        updatedList.splice(oldFileIndex, 1, files[0]);
        setFileList([...updatedList]);
        form.setFieldsValue({ [field]: updatedList?.[0] });

        setImage(null);
        setIsVisible(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [base64Uploads]);

  const fileListChangeHandler = (file, newFileList, changeType) => {
    if (changeType === 'add') {
      dispatch(
        addFormUploads.request({
          file,
          source: field,
          id,
        })
      );
    } else {
      dispatch(
        deleteFormUploads.request({
          id: file.uid,
        })
      );
      if (fileList[0].source.name === field) {
        setFileList([]);
      }

      form.setFieldsValue({ [field]: null });
    }
  };

  return (
    <>
      <MediaUpload
        fileSize={25}
        extensions={['png', 'jpg', 'jpeg']}
        fileList={fileList}
        style={{ float: 'right' }}
        onPreview={e => handlePreview(e)}
        changeHandler={fileListChangeHandler}
        loading={fileListLoading}
        multiple={false}
        hideUpload={fileList?.[0]}
        imageUrl={fileList?.[0]?.url}
      />

      <Modal
        mode='full-screen'
        centered
        bodyStyle={{ height: '100vh' }}
        title={''}
        footer={null}
        width='100%'
        height='100%'
        margin='0'
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        onOk={handleOk}
      >
        {image && (
          <Fragment>
            <div className='custom-actions'>
              <Button key='back' type='link' onClick={() => setIsVisible(false)} ghost>
                <Icon type='close-square' /> Close
              </Button>{' '}
              <Button key='submit' type='link' ghost onClick={handleOk}>
                <Icon type='save' /> Save Change
              </Button>
            </div>
            <ImageEditor
              handleSave={handleOk}
              image={image}
              id={id}
              editorRef={editorRef}
              loading={base64Loading}
            />
          </Fragment>
        )}
      </Modal>
    </>
  );
};

export default MediaField;
