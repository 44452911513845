import { call, takeLatest, put, select } from 'redux-saga/effects';
import moment from 'moment';
import {
  fetchBookings,
  fetchOneBooking,
  addBooking,
  updateBooking,
  deleteBooking,
} from 'actions/bookings';
import {
  fetchAllBookingsApi,
  fetchOneBookingApi,
  addBookingApi,
  updateBookingApi,
  deleteBookingApi,
} from 'services/bookings';
import fetchEntity from './fetch-entity';

const fetchBookingsData = fetchEntity.bind(null, fetchBookings.actions, fetchAllBookingsApi);

const fetchBookingData = fetchEntity.bind(null, fetchOneBooking.actions, fetchOneBookingApi);

const fetchAddBooking = fetchEntity.bind(null, addBooking.actions, addBookingApi);

const fetchUpdateBooking = fetchEntity.bind(null, updateBooking.actions, updateBookingApi);

const fetchDeleteBooking = fetchEntity.bind(null, deleteBooking.actions, deleteBookingApi);

export function* loadFetchBookings({ params }) {
  yield call(fetchBookingsData, { ...params });
}

export function* loadGetBooking({ params }) {
  yield put(fetchOneBooking.actions.failure({}, undefined));
  yield call(fetchBookingData, params);
}

export function* loadAddBooking({ params }) {
  yield call(fetchAddBooking, params);
}

export function* loadUpdateBooking({ params }) {
  yield call(fetchUpdateBooking, params);
}

export function* loadDeleteBooking({ params }) {
  yield call(fetchDeleteBooking, { ...params });
}

function* watchFetchBookings() {
  yield takeLatest(fetchBookings.actionName, loadFetchBookings);
}

function* watchAddBooking() {
  yield takeLatest(addBooking.actionName, loadAddBooking);
}

function* watchUpdateBooking() {
  yield takeLatest(updateBooking.actionName, loadUpdateBooking);
}

function* watchDeleteBooking() {
  yield takeLatest(deleteBooking.actionName, loadDeleteBooking);
}

function* watchGetBooking() {
  yield takeLatest(fetchOneBooking.actionName, loadGetBooking);
}

export function* loadBookingsOnChange() {
  const bookings = yield select(state => state.bookings);
  const { total, skip, limit } = bookings;
  const startDate = moment()
    .startOf('month')
    .toDate();
  const endDate = moment()
    .endOf('month')
    .toDate();

  if (skip && skip >= total) {
    yield call(fetchBookingsData, {
      $skip: total - Math.max(total % limit, limit),
      'date[$gt]': startDate,
      'date[$lte]': endDate,
      getAll: true,
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchBookingsData, {
      $skip: skip,
      '$sort[updatedAt]': -1,
      'date[$gt]': startDate,
      'date[$lte]': endDate,
      getAll: true,
    });
  }
}

function* watchBookingsOnChange() {
  yield takeLatest(
    [
      deleteBooking.requestTypes.SUCCESS,
      addBooking.requestTypes.SUCCESS,
      updateBooking.requestTypes.SUCCESS,
    ],
    loadBookingsOnChange
  );
}

export default {
  watchFetchBookings,
  watchAddBooking,
  watchGetBooking,
  watchUpdateBooking,
  watchBookingsOnChange,
  watchDeleteBooking,
};
