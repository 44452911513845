import styled from 'styled-components';
import { Tabs } from 'antd';
import propTypes from 'prop-types';

const { TabPane: tabpane } = Tabs;

const TabPane = styled(tabpane)``;

TabPane.propTypes = {
  type: propTypes.string,
};

TabPane.defaultProps = {};

/** @component */
export default TabPane;
