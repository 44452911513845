import React, { useState } from 'react';
import { isRoutPermitted } from 'configs/isUserPermitted';
import { Link, withRouter } from 'react-router-dom';
import LogoutButton from 'containers/Authorization/LogoutButton';
import { Menu, Icon } from 'antd';
import { AddBooking } from 'components';
import { protectedRouts } from 'constants/routes';
import { Logo } from '.';

const { pathname } = window.location;
const isFromMobileApp = pathname.includes('/appreports') || pathname.includes('/app-rep');
const MobileHeader = ({ history, location }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [subContent, setSubContent] = useState();

  const changeRoute = item => {
    setIsCollapsed(false);
    history.push({
      pathname: item.pathname,
      state: item.name,
    });
  };

  const getKeys = () => {
    return [location.pathname];
  };
  const handleTopClick = menuitem => {
    if (menuitem.items && Object.values(menuitem.items).some(item => item.showInSideBar)) {
      setSubContent({ ...menuitem });
      return setShowSub(true);
    }
    return changeRoute(menuitem);
  };

  const topMenu = menuitem => (
    <Menu.Item key={menuitem.key} onClick={() => handleTopClick({ ...menuitem })}>
      <div className='mobile-menu-item'>
        <span>{menuitem.name}</span>
        {menuitem.items && Object.values(menuitem.items).some(item => item.showInSideBar) && (
          <Icon type='right' />
        )}
      </div>
    </Menu.Item>
  );

  const subMenu = menuitem =>
    menuitem
      .filter(item => isRoutPermitted(item.pathname) && item.showInSideBar)
      .map(item => (
        <Menu.Item key={item.key} onClick={() => changeRoute(item)}>
          <span>{item.name}</span>
        </Menu.Item>
      ));
  if (isFromMobileApp) return <div />;
  return (
    <div className='mobile-header'>
      <div className='logo-wrapper'>
        <Logo isIcon={false} />
        <AddBooking />
        <div className='burger' onClick={() => setIsCollapsed(!isCollapsed)}>
          <div className='burger-item' />
          <div className='burger-item' />
          <div className='burger-item' />
        </div>
      </div>
      {!showSub && (
        <Menu
          theme='light'
          selectedKeys={getKeys('select')}
          defaultOpenKeys={getKeys('open')}
          mode='inline'
          className={isCollapsed ? 'show' : 'hide'}
        >
          {Object.values(protectedRouts)
            .filter(menuitem => isRoutPermitted(menuitem.pathname))
            .map(menuitem => {
              return topMenu(menuitem);
            })}
          <Menu.Item>
            <Link to={'#'}>
              <LogoutButton className='logout-btn' ismobile='ismobile' />
            </Link>
          </Menu.Item>
        </Menu>
      )}
      {showSub && subContent.items && (
        <Menu
          theme='light'
          selectedKeys={getKeys('select')}
          defaultOpenKeys={getKeys('open')}
          mode='inline'
          className={isCollapsed ? 'show submenu' : 'hide'}
        >
          <Menu.Item onClick={() => setShowSub(false)}>
            <span>
              <Icon type='left' />
              {subContent.name}
            </span>
          </Menu.Item>
          {Object.values(subContent.items).some(item => item.showInSideBar) &&
            subContent.items &&
            subMenu(Object.values(subContent.items))}
        </Menu>
      )}
    </div>
  );
};

export default React.memo(withRouter(MobileHeader));
