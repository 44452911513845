import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

const RichTextEditor = styled(ReactQuill)`
  background-color: white;
  line-height: 20px;
  height: 150px;
  margin-bottom: 32px;
  .ql-container {
    height: 85%;
  }
`;

export default RichTextEditor;
