import {
  fetchContracts,
  fetchUserContracts,
  fetchOneContract,
  addContract,
} from 'actions/contracts';
import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getContracts = state => state.contracts;
export const getContractsState = createSelector(getContracts, data =>
  data.contracts ? data.contracts : []
);
export const getUserContractsState = createSelector(getContracts, data =>
  data.userContracts ? data.userContracts : []
);
export const getSelectedContract = createSelector(getContracts, data =>
  data.selectedContract ? data.selectedContract : {}
);
export const getContractsTotal = createSelector(getContractsState, data => (data ? data.total : 0));
export const getSuccessState = createSelector(getContracts, data => data.isSuccessful);
export const contractsValuesLoading = createLoadingSelector(fetchContracts.actionName)();
export const contractsValuesError = createErrorSelector(fetchContracts.actionName)();

export const userContractsValuesLoading = createLoadingSelector(fetchUserContracts.actionName)();
export const userContractsValuesError = createErrorSelector(fetchUserContracts.actionName)();

export const getAddContractLoading = createLoadingSelector(addContract.actionName)();
export const getAddContractError = createErrorSelector(addContract.actionName)();

export const getSelectedContractLoading = createLoadingSelector(fetchOneContract.actionName)();
export const getSelectedContractError = createErrorSelector(fetchOneContract.actionName)();
