export default {
  nameInput: {
    label: 'Name',
    fieldName: 'name',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input contact name!',
        },
      ],
    },
    elementProps: {},
  },
  emailInput: {
    label: 'Email',
    fieldName: 'email',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input your username or email!',
        },
        {
          type: 'email',
          message: 'Please input valid email!',
        },
      ],
    },
    elementProps: {},
  },
  countryInput: {
    label: 'Country',
    fieldName: 'country',
    decoratorOptions: {},
    elementProps: {},
  },
  addressInput: {
    label: 'Address',
    fieldName: 'address',
    decoratorOptions: {},
    elementProps: {},
  },
  cityInput: {
    label: 'City',
    fieldName: 'city',
    decoratorOptions: {},
    elementProps: {},
  },
  provinceInput: {
    label: 'Province',
    fieldName: 'province',
    decoratorOptions: {},
    elementProps: {},
  },
  postalInput: {
    label: 'Postal',
    fieldName: 'postal',
    decoratorOptions: {
      rules: [
        {
          pattern: /(^[0-9]{5}(?:-[0-9]{4})?$)|(^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$)/,
          message: 'Please input valid US or Canada postal code!',
        },
      ],
    },
    elementProps: {
      placeholder: 'e.g. 12345-6789, A1A 1A1',
    },
  },
  notesInput: {
    label: 'Notes',
    fieldName: 'notes',
    decoratorOptions: {},
    elementProps: {},
  },
  typeInput: {
    label: 'Type',
    fieldName: 'type',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please add contact type!',
        },
      ],
    },
    elementProps: {},
  },
  searchClientInput: {
    label: 'Find Client From CRM',
    fieldName: 'client',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please select a client!',
        },
      ],
    },
    elementProps: {},
  },
};
