export default {
  companyNameInput: {
    label: 'Company Name',
    fieldName: 'name',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input your username or email!',
        },
      ],
    },
    elementProps: {},
  },
  fullNameInput: {
    label: 'Full Name',
    fieldName: 'name',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input your name!',
        },
      ],
    },
    elementProps: {},
  },
  logoInput: {
    label: 'Logo',
    fieldName: 'logo',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please upload your logo!',
        },
      ],
    },
    elementProps: {},
  },
  coverInput: {
    label: 'Cover',
    fieldName: 'cover',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please upload your cover image!',
        },
      ],
    },
    elementProps: {},
  },
  roleInput: {
    label: 'Role',
    fieldName: 'role',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please user role!',
        },
      ],
    },
    elementProps: {},
  },
  emailInput: {
    label: 'Email',
    fieldName: 'email',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input your username or email!',
        },
        {
          type: 'email',
          message: 'Please input valid email!',
        },
      ],
    },
    elementProps: {},
  },
  passwordInput: {
    label: 'Password',
    fieldName: 'password',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input your password!',
        },
        {
          pattern: new RegExp('^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&)(_=+-/*])(?=.{6,})'),
          message:
            'Password must contain at least 6 characters, 1 capital letter, 1 number and 1 symbol',
        },
      ],
    },
    elementProps: {},
  },
  repeatPasswordInput: {
    label: 'Repeat Password',
    fieldName: 'repeatPassword',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input your password!',
        },
      ],
    },
    elementProps: {},
  },
  userFullName: {
    label: 'Full Name',
    fieldName: 'name',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input your full name',
        },
      ],
    },
    elementProps: {},
  },
  firstNameInput: {
    label: 'First Name',
    fieldName: 'firstName',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input your first name',
        },
      ],
    },
    elementProps: {},
  },

  country: {
    label: 'Country',
    fieldName: 'country',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input your country',
        },
      ],
    },
    elementProps: {},
  },
  provinces: {
    label: 'Province',
    fieldName: 'province',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input your province',
        },
      ],
    },
    elementProps: {},
  },

  states: {
    label: 'State',
    fieldName: 'province',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input your state',
        },
      ],
    },
    elementProps: {},
  },

  yearsOfInspector: {
    label: 'Years of inspector',
    fieldName: 'yearsOfInspector',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input years of inspector',
        },
      ],
    },
    elementProps: {},
  },

  lastNameInput: {
    label: 'Last Name',
    fieldName: 'lastName',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: 'Please input your last name',
        },
      ],
    },
    elementProps: {},
  },
  addressInput: {
    label: 'Address',
    fieldName: 'address',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },
  cityInput: {
    label: 'City',
    fieldName: 'city',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },
  stateInput: {
    label: 'State',
    fieldName: 'province',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },
  postalInput: {
    label: 'Postal Code',
    fieldName: 'postal',
    decoratorOptions: {
      rules: [
        {
          pattern: /(^[0-9]{5}(?:-[0-9]{4})?$)|(^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$)/,
          message: 'Invalid postal code format',
        },
      ],
    },
    elementProps: {
      placeholder: 'e.g. 12345-6789, A1A 1A1',
    },
  },
  phoneInput: {
    label: 'Phone',
    fieldName: 'phone',
    decoratorOptions: {
      rules: [
        {
          pattern: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
          message: 'Please input valid phone number',
        },
      ],
    },
  },
  userPhoneInput: {
    label: 'Phone',
    fieldName: 'phone',
    decoratorOptions: {
      rules: [
        {
          pattern: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
          message: 'Please input valid phone number',
        },
        {
          required: true,
          message: 'Please input phone number',
        },
      ],
    },
  },
  faxInput: {
    label: 'Fax',
    fieldName: 'fax',
    decoratorOptions: {
      rules: [
        {
          pattern: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
          message: 'Please input valid phone number',
        },
      ],
    },
  },
  contactEmail: {
    label: 'Contact Email',
    fieldName: 'contactEmail',
    decoratorOptions: {
      rules: [
        {
          type: 'email',
          message: 'Please input valid email!',
        },
      ],
    },
  },
  websiteInput: {
    label: 'Website',
    fieldName: 'website',
    decoratorOptions: {
      rules: [{ type: 'url' }],
    },
    elementProps: {},
  },
  numberOfSubUsers: {
    label: 'Number Of Sub Users',
    fieldName: 'numberOfSubUsers',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },
  CRMItems: {
    label: 'CRM Items',
    fieldName: 'CRMItems',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },
  numberOfReportTemplates: {
    label: 'Number Of Report Templates',
    fieldName: 'numberOfReportTemplates',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },

  summaryPageHeader: {
    label: 'Header text',
    fieldName: 'summaryPageHeader',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },
  summaryPageFooter: {
    label: 'Footer text',
    fieldName: 'summaryPageFooter',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },
};
