import styled from 'styled-components';

const MobileHeaderWrapper = styled.div`
  background-color: #f5f5f5 !important;
  .sidebar-column {
    height: 100%;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: 0;
    background-color: #f5f5f5 !important;
  }
  .ant-menu-item,
  .ant-menu-submenu {
    color: #3535dd;
    font-size: 14px;
    height: auto;
  }
  .ant-menu-item-selected {
    font-weight: 800;
  }
  .logout-btn {
    color: red !important;
    padding-left: 24px !important;
  }
  .burger {
    width: 20px;
    height: 18px;
    cursor: pointer;
    &-item {
      border-top: 2px solid black;
      height: 6px;
    }
  }
  .logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 30px;
  }
  .hide {
    height: 0;
    opacity: 0;
    transition: height 400ms 400ms, opacity 0ms 0ms;
  }
  .show {
    height: 100vh;
    opacity: 1;
    transition: height 0ms 0ms, opacity 600ms 0ms;
  }
  .submenu .ant-menu-item:not(:first-of-type) {
    color: black;
  }
  .mobile-header .ant-menu-item {
    border-bottom: 1px solid #e7e7e7;
  }
  .mobile-header .logout-btn {
    padding: 0 !important;
  }
  .mobile-menu-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;

/** @component */
export default MobileHeaderWrapper;
