import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import routes from 'constants/routes';
import asyncComponent from './asyncComponent';
import { isAuth, isNotAuth, isPermitted, isPayed } from './configs/auth';
import getPageContainer, { getPageComponents } from './getPageContainer';

const NotFoundPage = asyncComponent(() => import('./containers/NotFoundPage'));
const Checkout = asyncComponent(() => import('./containers/Authorization/Checkout'));

const protectedRouts = getPageComponents();

const Routes = () => (
  <Switch>
    {/* unprotected routes */}
    {Object.values(routes).map(route => (
      <Route
        exact
        path={route.pathname}
        key={route.pathname}
        component={isNotAuth(getPageContainer(route.component))}
      />
    ))}

    {/* protected routes */}
    {protectedRouts.map(route => {
      return (
        <Route
          exact
          key={route.pathname}
          path={route.absPathname || route.pathname}
          component={isAuth(isPermitted(isPayed(getPageContainer(route.component))))}
        />
      );
    })}
    <Route exact path='/checkout' component={isAuth(Checkout)} />

    {/* 404 */}
    <Route exact path={routes.notFound.pathname} component={NotFoundPage} />
    <Route component={NotFoundPage} />
  </Switch>
);

export default withRouter(Routes);
