import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchInspectionTypes,
  fetchOneInspectionType,
  addInspectionType,
  updateInspectionType,
  deleteInspectionType,
} from 'actions/inspection-types';
import {
  fetchAllInspectionTypesApi,
  fetchOneInspectionTypeApi,
  addInspectionTypeApi,
  updateInspectionTypeApi,
  deleteInspectionTypeApi,
} from 'services/inspection-types';
import fetchEntity from './fetch-entity';

const fetchInspectionTypesData = fetchEntity.bind(
  null,
  fetchInspectionTypes.actions,
  fetchAllInspectionTypesApi
);

const fetchInspectionTypeData = fetchEntity.bind(
  null,
  fetchOneInspectionType.actions,
  fetchOneInspectionTypeApi
);

const fetchAddInspectionType = fetchEntity.bind(
  null,
  addInspectionType.actions,
  addInspectionTypeApi
);

const fetchUpdateInspectionType = fetchEntity.bind(
  null,
  updateInspectionType.actions,
  updateInspectionTypeApi
);

const fetchDeleteInspectionType = fetchEntity.bind(
  null,
  deleteInspectionType.actions,
  deleteInspectionTypeApi
);

export function* loadFetchInspectionTypes({ params }) {
  yield call(fetchInspectionTypesData, { ...params });
}

export function* loadGetInspectionType({ params }) {
  yield put(fetchOneInspectionType.actions.failure({}, undefined));
  yield call(fetchInspectionTypeData, params);
}

export function* loadAddInspectionType({ params }) {
  yield call(fetchAddInspectionType, params);
}

export function* loadUpdateInspectionType({ params }) {
  yield call(fetchUpdateInspectionType, params);
}

export function* loadDeleteInspectionType({ params }) {
  yield call(fetchDeleteInspectionType, { ...params });
}

function* watchFetchInspectionTypes() {
  yield takeLatest(fetchInspectionTypes.actionName, loadFetchInspectionTypes);
}

function* watchAddInspectionType() {
  yield takeLatest(addInspectionType.actionName, loadAddInspectionType);
}

function* watchUpdateInspectionType() {
  yield takeLatest(updateInspectionType.actionName, loadUpdateInspectionType);
}

function* watchDeleteInspectionType() {
  yield takeLatest(deleteInspectionType.actionName, loadDeleteInspectionType);
}

function* watchGetInspectionType() {
  yield takeLatest(fetchOneInspectionType.actionName, loadGetInspectionType);
}

export function* loadInspectionTypesOnChange() {
  const inspectionTypes = yield select(state => state.inspectionTypes);
  const { total, skip, limit } = inspectionTypes;
  if (skip && skip >= total) {
    yield call(fetchInspectionTypesData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchInspectionTypesData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchInspectionTypesOnChange() {
  yield takeLatest(
    [
      deleteInspectionType.requestTypes.SUCCESS,
      addInspectionType.requestTypes.SUCCESS,
      updateInspectionType.requestTypes.SUCCESS,
    ],
    loadInspectionTypesOnChange
  );
}

export default {
  watchFetchInspectionTypes,
  watchAddInspectionType,
  watchGetInspectionType,
  watchUpdateInspectionType,
  watchInspectionTypesOnChange,
  watchDeleteInspectionType,
};
