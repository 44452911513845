import styled, { css } from 'styled-components';
import { Form } from 'antd';

const FormItem = styled(Form.Item)`
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 400 !important;
  padding: 0 !important;
  margin-bottom: 20px;
  .ant-form-item-label {
    padding: 0 0 3px 0 !important;
  }

  ${props =>
    props.display &&
    css`
      .ant-form-item-label > label {
        &:after {
          display: ${props.display};
        }
      }
    `}
  ${props =>
    props.width &&
    css`
      .ant-form-item-label {
        width: ${props.width};
      }
    `}
`;

FormItem.defaultProps = {};

/** @component */
export default FormItem;
