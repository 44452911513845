import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormItem, Input, Button, Select, TextArea, GoogleMapAutocomplete } from 'components';
import { DataSection } from 'components/Partials';
import { Col, Form, Row, Icon } from 'antd';
import { getContactsState } from 'selectors/contacts';
import { fetchContacts } from 'actions/contacts';
import { getShowClients } from 'selectors/booking-form';
import { addContactInfo, showClientList, showClientForm } from 'actions/booking-form';
import postalCodeFormatter from 'utils/postalCodeFormatter';
import getPostalCode from 'utils/getPostalCode';
import formItemConfigs from './formItemConfigs';
import PhoneNumbers from './PhoneNumbers';

const { Option } = Select;

const {
  nameInput,
  emailInput,
  addressInput,
  countryInput,
  cityInput,
  provinceInput,
  postalInput,
  notesInput,
  searchClientInput,
} = formItemConfigs;

const ContactsForm = ({
  form,
  handleSubmit,
  contact,
  clientList,
  setDisabled,
  isContactsPage = false,
  modalVisible,
}) => {
  const dispatch = useDispatch();
  const showClients = useSelector(getShowClients);
  const { getFieldDecorator } = form;
  const contacts = useSelector(getContactsState);

  const [email, setEmail] = useState('');
  const contactsEmail = contacts?.data;

  const crmEmails = contactsEmail?.find(el => el.email === email);

  useEffect(() => {
    if (crmEmails) {
      crmEmails ? setDisabled(true) : setDisabled(false);
    } else {
      crmEmails ? setDisabled(true) : setDisabled(false);
    }
  }, [crmEmails, setDisabled]);

  useEffect(() => {
    dispatch(fetchContacts.request({ getAll: true, '$sort[name]': 1 }));
  }, [dispatch]);

  const onPlaceSelected = async details => {
    const { description, terms } = details;
    if (details && details.place_id && terms) {
      try {
        const postcode = await getPostalCode(details.place_id);
        form.setFieldsValue({ [postalInput.fieldName]: postcode || '' });
      } catch (e) {
        console.error(e);
      }
      form.setFieldsValue({ [countryInput.fieldName]: terms[terms.length - 1].value });
      form.setFieldsValue({
        [provinceInput.fieldName]: terms[terms.length - 2] ? terms[terms.length - 2].value : '',
      });
      form.setFieldsValue({
        [cityInput.fieldName]: terms[terms.length - 3] ? terms[terms.length - 3].value : '',
      });
    }
    form.setFieldsValue({ [addressInput.fieldName]: description });
  };

  const handleOnChange = contactId => {
    const selectedContact = contacts.data.find(client => client.id === contactId);
    dispatch(addContactInfo.request(selectedContact));
  };

  return (
    <>
      {!isContactsPage && clientList && (
        <DataSection className='add-client' bottom={0} top={0} left={0} right={0} clearFix>
          <DataSection inline pullRight textRight bottom={0} top={0} left={0} right={0}>
            {showClients ? (
              <Button ghost type={'success'} onClick={() => dispatch(showClientForm.request())}>
                <Icon type='plus-circle' /> Create New Client
              </Button>
            ) : (
              <Button
                ghost
                type={'success'}
                onClick={() => {
                  dispatch(showClientList.request());
                  setDisabled(false);
                }}
              >
                <Icon type='plus-circle' /> From Client CRM
              </Button>
            )}
          </DataSection>
        </DataSection>
      )}
      {!isContactsPage && showClients && contacts.data && (
        <DataSection>
          <Form
            id='contactsForm'
            layout={'vertical'}
            labelAlign={'left'}
            hideRequiredMark
            onSubmit={handleSubmit}
          >
            <FormItem label={searchClientInput.label}>
              {getFieldDecorator(searchClientInput.fieldName, {
                ...searchClientInput.decoratorOptions,
                initialValue: contact ? contact.id : undefined,
              })(
                <Select
                  placeholder='Search client by name or email'
                  size={'large'}
                  style={{ width: '100%' }}
                  onChange={value => {
                    handleOnChange(value);
                  }}
                  showArrow={false}
                  showSearch
                  allowClear={!!contact}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {contacts.data.map(c => (
                    <Option value={c.id} key={c.createdAt} title={`${c.name} - ${c.email}`}>
                      {`${c.name} - ${c.email}`}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Form>
        </DataSection>
      )}
      {(isContactsPage || !showClients) && (
        <Form
          id='contactsForm'
          layout={'vertical'}
          labelAlign={'left'}
          hideRequiredMark
          onSubmit={handleSubmit}
        >
          <FormItem label={nameInput.label}>
            {getFieldDecorator(nameInput.fieldName, {
              ...nameInput.decoratorOptions,
              initialValue: contact ? contact.name : null,
            })(<Input size={'large'} autocomplete='new-password' />)}
          </FormItem>

          <FormItem label={emailInput.label}>
            {getFieldDecorator(emailInput.fieldName, {
              ...emailInput.decoratorOptions,
              initialValue: contact ? contact.email : null,
            })(
              <Input
                size={'large'}
                autocomplete='new-password'
                onChange={e => {
                  setEmail(e.target.value);
                }}
              />
            )}
          </FormItem>
          {crmEmails && (
            <span
              style={{
                fontSize: '14px',
                color: '#f5222d',
                position: 'relative',
                top: modalVisible ? '-35px' : '-15px',
              }}
            >
              Client with that email already exist in CRM
            </span>
          )}
          <FormItem label={addressInput.label}>
            {getFieldDecorator(addressInput.fieldName, {
              ...addressInput.decoratorOptions,
              initialValue: contact ? contact.address : null,
            })(
              <GoogleMapAutocomplete
                address={contact && contact.address}
                onPlaceSelected={onPlaceSelected}
              />
            )}
          </FormItem>
          <Row gutter={25}>
            <Col span={12}>
              <FormItem label={cityInput.label}>
                {getFieldDecorator(cityInput.fieldName, {
                  ...cityInput.decoratorOptions,
                  initialValue: contact ? contact.city : null,
                })(<Input size={'large'} autocomplete='new-password' />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={provinceInput.label}>
                {getFieldDecorator(provinceInput.fieldName, {
                  ...provinceInput.decoratorOptions,
                  initialValue: contact ? contact.province : null,
                })(<Input size={'large'} autocomplete='new-password' />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={25}>
            <Col span={12}>
              <FormItem label={postalInput.label}>
                {getFieldDecorator(postalInput.fieldName, {
                  ...postalInput.decoratorOptions,
                  normalize: postalCodeFormatter,
                  initialValue: contact ? contact.postal : null,
                })(
                  <Input size={'large'} {...postalInput.elementProps} autocomplete='new-password' />
                )}
              </FormItem>
            </Col>{' '}
            <Col span={12}>
              <FormItem label={countryInput.label}>
                {getFieldDecorator(countryInput.fieldName, {
                  ...countryInput.decoratorOptions,
                  initialValue: contact ? contact.country : null,
                })(<Input size={'large'} autocomplete='new-password' />)}
              </FormItem>
            </Col>
          </Row>
          <FormItem label={notesInput.label}>
            {getFieldDecorator(notesInput.fieldName, {
              ...notesInput.decoratorOptions,
              initialValue: contact ? contact.notes : null,
            })(<TextArea size={'large'} />)}
          </FormItem>
          <PhoneNumbers contact={contact} form={form} />
        </Form>
      )}
    </>
  );
};

export default React.memo(ContactsForm);
