import styled from 'styled-components';
import { Layout } from 'antd';

const sider = Layout.Sider;

const Sider = styled(sider)`
  background-color: #F5F5F5 !important;
  min-height: 100% !important;
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: 0;
    background-color: #F5F5F5 !important;
  }
  .ant-menu-item,
  .ant-menu-submenu {
    color: #3535dd;
    font-size: 14px;
    height: auto;
  }
  .ant-menu-item-selected {
    font-weight: 800;
  }
`;

/** @component */
export default Sider;
