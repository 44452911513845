import { makeRequestAction } from './index';

/**
 * Fetch limitations
 */
export const fetchLimitations = makeRequestAction('FETCH_LIMITATIONS', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const addLimitation = makeRequestAction('ADD_LIMITATION', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const updateLimitation = makeRequestAction('UPDATE_LIMITATION', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const fetchOneLimitation = makeRequestAction('GET_LIMITATION', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const deleteLimitation = makeRequestAction('DELETE_LIMITATION', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});
