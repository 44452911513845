import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import styled from 'styled-components';
import { withScriptjs } from 'react-google-maps';
import { compose, withProps } from 'recompose';
import { GOOGLE_MAPS_KEY } from 'configs/env-vars';

const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&libraries=places`;

const InputWrapper = styled('div')`
  position: relative;
  #-google-places-suggestions-container {
    position: absolute;
    z-index: 1050;
    width: 100%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background: #fff;
    border-radius: 4px;
    .google-places-autocomplete__suggestion {
      padding: 5px 12px;
      color: rgba(0, 0, 0, 0.65);
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      transition: background 0.3s ease;
      position: relative;
      display: block;
      overflow: hidden;
    }
  }
  input {
    border: 1px solid #e5e5e5 !important;
    border-radius: 5px !important;
    background-color: #f1f1f1 !important;
    padding: 6px 11px;
    font-size: 16px;
    width: 100%;
    &:focus {
      background-color: #fff !important;
    }
  }
`;
const GoogleMapAutocompleteComponent = compose(
  withProps({
    googleMapURL: mapUrl,
    loadingElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs
)(({ onPlaceSelected, address }) => (
  <InputWrapper>
    <GooglePlacesAutocomplete onSelect={onPlaceSelected} initialValue={address} />
  </InputWrapper>
));

export default React.memo(GoogleMapAutocompleteComponent);
