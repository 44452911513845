import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchLimitations,
  fetchOneLimitation,
  addLimitation,
  updateLimitation,
  deleteLimitation,
} from 'actions/limitations';
import {
  fetchAllLimitationsApi,
  fetchOneLimitationApi,
  addLimitationApi,
  updateLimitationApi,
  deleteLimitationApi,
} from 'services/limitations';
import fetchEntity from './fetch-entity';

const fetchLimitationsData = fetchEntity.bind(
  null,
  fetchLimitations.actions,
  fetchAllLimitationsApi
);

const fetchLimitationData = fetchEntity.bind(
  null,
  fetchOneLimitation.actions,
  fetchOneLimitationApi
);

const fetchAddLimitation = fetchEntity.bind(null, addLimitation.actions, addLimitationApi);

const fetchUpdateLimitation = fetchEntity.bind(null, updateLimitation.actions, updateLimitationApi);

const fetchDeleteLimitation = fetchEntity.bind(null, deleteLimitation.actions, deleteLimitationApi);

export function* loadFetchLimitations({ params }) {
  yield call(fetchLimitationsData, { ...params });
}

export function* loadGetLimitation({ params }) {
  yield put(fetchOneLimitation.actions.failure({}, undefined));
  yield call(fetchLimitationData, params);
}

export function* loadAddLimitation({ params }) {
  yield call(fetchAddLimitation, params);
}

export function* loadUpdateLimitation({ params }) {
  yield call(fetchUpdateLimitation, params);
}

export function* loadDeleteLimitation({ params }) {
  yield call(fetchDeleteLimitation, { ...params });
}

function* watchFetchLimitations() {
  yield takeLatest(fetchLimitations.actionName, loadFetchLimitations);
}

function* watchAddLimitation() {
  yield takeLatest(addLimitation.actionName, loadAddLimitation);
}

function* watchUpdateLimitation() {
  yield takeLatest(updateLimitation.actionName, loadUpdateLimitation);
}

function* watchDeleteLimitation() {
  yield takeLatest(deleteLimitation.actionName, loadDeleteLimitation);
}

function* watchGetLimitation() {
  yield takeLatest(fetchOneLimitation.actionName, loadGetLimitation);
}

export function* loadLimitationsOnChange() {
  const limitations = yield select(state => state.limitations);
  const { total, skip, limit } = limitations;
  if (skip && skip >= total) {
    yield call(fetchLimitationsData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchLimitationsData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchLimitationsOnChange() {
  yield takeLatest(
    [
      deleteLimitation.requestTypes.SUCCESS,
      addLimitation.requestTypes.SUCCESS,
      updateLimitation.requestTypes.SUCCESS,
    ],
    loadLimitationsOnChange
  );
}

export default {
  watchFetchLimitations,
  watchAddLimitation,
  watchGetLimitation,
  watchUpdateLimitation,
  watchLimitationsOnChange,
  watchDeleteLimitation,
};
