import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchLimitationsUsers,
  fetchOneLimitationsUser,
  addLimitationsUser,
  updateLimitationsUser,
  deleteLimitationsUser,
} from 'actions/limitations-users';
import {
  fetchAllLimitationsUsersApi,
  fetchOneLimitationsUserApi,
  addLimitationsUserApi,
  updateLimitationsUserApi,
  deleteLimitationsUserApi,
} from 'services/limitations-users';
import fetchEntity from './fetch-entity';

const fetchLimitationsUsersData = fetchEntity.bind(null, fetchLimitationsUsers.actions, fetchAllLimitationsUsersApi);

const fetchLimitationsUserData = fetchEntity.bind(null, fetchOneLimitationsUser.actions, fetchOneLimitationsUserApi);

const fetchAddLimitationsUser = fetchEntity.bind(null, addLimitationsUser.actions, addLimitationsUserApi);

const fetchUpdateLimitationsUser = fetchEntity.bind(null, updateLimitationsUser.actions, updateLimitationsUserApi);

const fetchDeleteLimitationsUser = fetchEntity.bind(null, deleteLimitationsUser.actions, deleteLimitationsUserApi);

export function* loadFetchLimitationsUsers({ params }) {
  yield call(fetchLimitationsUsersData, { ...params });
}

export function* loadGetLimitationsUser({ params }) {
  yield put(fetchOneLimitationsUser.actions.failure({}, undefined));
  yield call(fetchLimitationsUserData, params);
}

export function* loadAddLimitationsUser({ params }) {
  yield call(fetchAddLimitationsUser, params);
}

export function* loadUpdateLimitationsUser({ params }) {
  yield call(fetchUpdateLimitationsUser, params);
}

export function* loadDeleteLimitationsUser({ params }) {
  yield call(fetchDeleteLimitationsUser, { ...params });
}

function* watchFetchLimitationsUsers() {
  yield takeLatest(fetchLimitationsUsers.actionName, loadFetchLimitationsUsers);
}

function* watchAddLimitationsUser() {
  yield takeLatest(addLimitationsUser.actionName, loadAddLimitationsUser);
}

function* watchUpdateLimitationsUser() {
  yield takeLatest(updateLimitationsUser.actionName, loadUpdateLimitationsUser);
}

function* watchDeleteLimitationsUser() {
  yield takeLatest(deleteLimitationsUser.actionName, loadDeleteLimitationsUser);
}

function* watchGetLimitationsUser() {
  yield takeLatest(fetchOneLimitationsUser.actionName, loadGetLimitationsUser);
}

export function* loadLimitationsUsersOnChange({ params }) {
  const limitationsUsers = yield select(state => state.limitationsUsers);
  const { total, skip, limit } = limitationsUsers;
  if (skip && skip >= total) {
    yield call(fetchLimitationsUsersData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchLimitationsUsersData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchLimitationsUsersOnChange() {
  yield takeLatest([deleteLimitationsUser.requestTypes.SUCCESS], loadLimitationsUsersOnChange);
}

export default {
  watchFetchLimitationsUsers,
  watchAddLimitationsUser,
  watchGetLimitationsUser,
  watchUpdateLimitationsUser,
  watchLimitationsUsersOnChange,
  watchDeleteLimitationsUser,
};
