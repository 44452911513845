import {
  fetchInvoices,
  fetchOneInvoice,
  addInvoice,
  updateInvoice,
  deleteInvoice,
} from 'actions/invoices';

const invoices = () => {
  const initialState = {
    invoices: null,
    selectedInvoice: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchInvoices.requestTypes.SUCCESS:
        return {
          selectedInvoice: null,
          invoices: response,
        };
      case addInvoice.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addInvoice.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addInvoice.requestTypes.SUCCESS:
        return {
          ...state,
          selectedInvoice: response,
          isSuccessful: true,
        };
      case updateInvoice.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateInvoice.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateInvoice.requestTypes.SUCCESS:
        return {
          ...state,
          selectedInvoice: response,
          isSuccessful: true,
        };
      case fetchOneInvoice.requestTypes.SUCCESS:
        return {
          ...state,
          selectedInvoice: response,
        };
      case deleteInvoice.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default invoices();
