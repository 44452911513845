import { all, fork } from 'redux-saga/effects';

import users from './users';
/* PLOP_INJECT_IMPORT */
import dwellingsToUsers from './dwellings-to-users';
import inspetionTypesToUsers from './inspetion-types-to-users';
import buildingTypesToUsers from './building-types-to-users';
import contractsToReports from './contracts-to-reports';
import conditionsToUsers from './conditions-to-users';
import limitationsUsers from './limitations-users';
import reportsHistory from './reports-history';
import invoices from './invoices';
import services from './services';
import conditions from './conditions';
import limitations from './limitations';
import brokers from './brokers';
import packages from './packages';
import payments from './payments';
import profiles from './profiles';
import bookings from './bookings';
import inspectionTypes from './inspection-types';
import dwellings from './dwellings';
import buildingTypes from './building-types';
import templates from './templates';
import contacts from './contacts';
import reports from './reports';
import authManagement from './auth-management';
import contracts from './contracts';
import guestReport from './guest-report';
import guestContract from './guest-contract';
import reportEmail from './report-email';
import coverLetters from './cover-letters';
import advice from './advice';

const combinedSagas = {
  ...users,
  ...packages,
  /* PLOP_INJECT_EXPORT */
  ...dwellingsToUsers,
  ...inspetionTypesToUsers,
  ...buildingTypesToUsers,
  ...contractsToReports,
  ...conditionsToUsers,
  ...limitationsUsers,
  ...reportsHistory,
  ...invoices,
  ...services,
  ...conditions,
  ...limitations,
  ...brokers,
  ...payments,
  ...profiles,
  ...bookings,
  ...inspectionTypes,
  ...dwellings,
  ...buildingTypes,
  ...templates,
  ...contacts,
  ...reports,
  ...authManagement,
  ...contracts,
  ...guestReport,
  ...guestContract,
  ...reportEmail,
  ...coverLetters,
  ...advice,
};

export default function* rootSaga() {
  yield all(Object.values(combinedSagas).map(fork));
}
