import {
  fetchReports,
  fetchOneReport,
  addReport,
  updateReport,
  setRecommendation,
  deleteRecommendation,
} from 'actions/reports';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getReports = state => state.reports;
export const getReportsState = createSelector(getReports, data =>
  data.reports ? data.reports : []
);
export const getReportsTotal = createSelector(getReportsState, data => (data ? data.total : 0));

export const getSuccessState = createSelector(getReports, data => data.isSuccessful);
export const getSelectedReport = createSelector(getReports, data => data.selectedReport);

export const reportsValuesLoading = createLoadingSelector(fetchReports.actionName)();
export const reportsValuesError = createErrorSelector(fetchReports.actionName)();

export const updateReportLoading = createLoadingSelector(updateReport.actionName)();
export const updateReportError = createErrorSelector(updateReport.actionName)();

export const getAddReportLoading = createLoadingSelector(addReport.actionName)();
export const getAddReportError = createErrorSelector(addReport.actionName)();

export const getSelectedReportLoading = createLoadingSelector(fetchOneReport.actionName)();
export const getSelectedReportError = createErrorSelector(fetchOneReport.actionName)();

export const getSetRecommendationLoading = createLoadingSelector(setRecommendation.actionName)();
export const getSetRecommendationError = createErrorSelector(setRecommendation.actionName)();

export const getDeleteRecommendationLoading = createLoadingSelector(
  deleteRecommendation.actionName
)();
export const getDeleteRecommendationError = createErrorSelector(deleteRecommendation.actionName)();

export const getIsSidebarClosed = createSelector(getReports, data => data.isSidebarClosed);
