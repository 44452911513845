import { createSelector } from 'reselect';

export const getBookingState = state => state.bookingForm;
export const getCurrent = createSelector(getBookingState, data => data.slide);
export const getIsSubmitted = createSelector(getBookingState, data => data.isSubmitted);
export const getShowClients = createSelector(getBookingState, data => data.showClients);
export const getShowBrokers = createSelector(getBookingState, data => data.showBrokers);
export const getIsDone = createSelector(getBookingState, data => data.isDone);
export const getBooking = createSelector(getBookingState, data => data.booking);
export const getContact = createSelector(getBookingState, data => data.contact);
export const getReport = createSelector(getBookingState, data => data.report);
export const getBroker = createSelector(getBookingState, data => data.broker);
export const getInvoice = createSelector(getBookingState, data => data.invoice);
export const getClickedStep = createSelector(getBookingState, data => data.clickedStep);
