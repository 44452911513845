import {
  fetchBrokers,
  fetchOneBroker,
  addBroker,
  updateBroker,
  deleteBroker,
} from 'actions/brokers';

const brokers = () => {
  const initialState = {
    brokers: null,
    selectedBroker: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case fetchBrokers.requestTypes.SUCCESS:
        return {
          ...state,
          brokers: response,
          isSuccessful: false,
        };
      case addBroker.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addBroker.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case addBroker.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBroker: response,
          isSuccessful: true,
        };
      case updateBroker.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateBroker.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateBroker.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBroker: response,
          isSuccessful: true,
        };
      case fetchOneBroker.requestTypes.SUCCESS:
        return {
          ...state,
          selectedBroker: response,
        };
      case deleteBroker.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      default:
        return state;
    }
  };
};

export default brokers();
