import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchProfiles,
  fetchOneProfile,
  addProfile,
  updateProfile,
  deleteProfile,
} from 'actions/profiles';
import {
  fetchAllProfilesApi,
  fetchOneProfileApi,
  addProfileApi,
  updateProfileApi,
  deleteProfileApi,
} from 'services/profiles';
import fetchEntity from './fetch-entity';

const fetchProfilesData = fetchEntity.bind(null, fetchProfiles.actions, fetchAllProfilesApi);

const fetchProfileData = fetchEntity.bind(null, fetchOneProfile.actions, fetchOneProfileApi);

const fetchAddProfile = fetchEntity.bind(null, addProfile.actions, addProfileApi);

const fetchUpdateProfile = fetchEntity.bind(null, updateProfile.actions, updateProfileApi);

const fetchDeleteProfile = fetchEntity.bind(null, deleteProfile.actions, deleteProfileApi);

export function* loadFetchProfiles({ params }) {
  yield call(fetchProfilesData, { ...params });
}

export function* loadGetProfile({ params }) {
  yield put(fetchOneProfile.actions.failure({}, undefined));
  yield call(fetchProfileData, params);
}

export function* loadAddProfile({ params }) {
  yield call(fetchAddProfile, params);
}

export function* loadUpdateProfile({ params }) {
  yield call(fetchUpdateProfile, params);
}

export function* loadDeleteProfile({ params }) {
  yield call(fetchDeleteProfile, { ...params });
}

function* watchFetchProfiles() {
  yield takeLatest(fetchProfiles.actionName, loadFetchProfiles);
}

function* watchAddProfile() {
  yield takeLatest(addProfile.actionName, loadAddProfile);
}

function* watchUpdateProfile() {
  yield takeLatest(updateProfile.actionName, loadUpdateProfile);
}

function* watchDeleteProfile() {
  yield takeLatest(deleteProfile.actionName, loadDeleteProfile);
}

function* watchGetProfile() {
  yield takeLatest(fetchOneProfile.actionName, loadGetProfile);
}

export function* loadProfilesOnChange() {
  const profiles = yield select(state => state.profiles);
  const { total, skip, limit } = profiles;
  if (skip && skip >= total) {
    yield call(fetchProfilesData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[createdAt]': -1,
    });
  } else {
    yield call(fetchProfilesData, { $skip: skip, '$sort[createdAt]': -1 });
  }
}

function* watchProfilesOnChange() {
  yield takeLatest([deleteProfile.requestTypes.SUCCESS], loadProfilesOnChange);
}

export default {
  watchFetchProfiles,
  watchAddProfile,
  watchGetProfile,
  watchUpdateProfile,
  watchProfilesOnChange,
  watchDeleteProfile,
};
