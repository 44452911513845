import styled from 'styled-components';
import { Menu } from 'antd';

const Sidebar = styled(Menu)`
  border: none !important;
  .ant-menu-item {
    font-weight: bold;
    &::after {
      border: none !important;
    }
  }
  .ant-menu-item-selected {
    background: none !important;
    color: #3535dd !important;
    border: none !important;
    outline: none !important;
  }
`;

/** @component */
export default Sidebar;
