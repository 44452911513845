import styled from 'styled-components';
import { Drawer as drawer } from 'antd';

const Drawer = styled(drawer)`
  .ant-drawer-mask {
    background-color: rgba(255, 255, 255, 0.7) !important;
  }
`;

/** @component */
export default Drawer;
