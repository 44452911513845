import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchPackages,
  fetchOnePackage,
  addPackage,
  updatePackage,
  deletePackage,
} from 'actions/packages';
import {
  fetchAllPackagesApi,
  fetchOnePackageApi,
  addPackageApi,
  updatePackageApi,
  deletePackageApi,
} from 'services/packages';
import fetchEntity from './fetch-entity';

const fetchPackagesData = fetchEntity.bind(null, fetchPackages.actions, fetchAllPackagesApi);

const fetchPackageData = fetchEntity.bind(null, fetchOnePackage.actions, fetchOnePackageApi);

const fetchAddPackage = fetchEntity.bind(null, addPackage.actions, addPackageApi);

const fetchUpdatePackage = fetchEntity.bind(null, updatePackage.actions, updatePackageApi);

const fetchDeletePackage = fetchEntity.bind(null, deletePackage.actions, deletePackageApi);

export function* loadFetchPackages({ params }) {
  yield call(fetchPackagesData, { ...params });
}

export function* loadGetPackage({ params }) {
  yield put(fetchOnePackage.actions.failure({}, undefined));
  yield call(fetchPackageData, params);
}

export function* loadAddPackage({ params }) {
  yield call(fetchAddPackage, params);
}

export function* loadUpdatePackage({ params }) {
  yield call(fetchUpdatePackage, params);
}

export function* loadDeletePackage({ params }) {
  yield call(fetchDeletePackage, { ...params });
}

function* watchFetchPackages() {
  yield takeLatest(fetchPackages.actionName, loadFetchPackages);
}

function* watchAddPackage() {
  yield takeLatest(addPackage.actionName, loadAddPackage);
}

function* watchUpdatePackage() {
  yield takeLatest(updatePackage.actionName, loadUpdatePackage);
}

function* watchDeletePackage() {
  yield takeLatest(deletePackage.actionName, loadDeletePackage);
}

function* watchGetPackage() {
  yield takeLatest(fetchOnePackage.actionName, loadGetPackage);
}

export function* loadPackagesOnChange() {
  const packages = yield select(state => state.packages);
  const { total, skip, limit } = packages;
  if (skip && skip >= total) {
    yield call(fetchPackagesData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchPackagesData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchPackagesOnChange() {
  yield takeLatest(
    [
      deletePackage.requestTypes.SUCCESS,
      addPackage.requestTypes.SUCCESS,
      updatePackage.requestTypes.SUCCESS,
    ],
    loadPackagesOnChange
  );
}

export default {
  watchFetchPackages,
  watchAddPackage,
  watchGetPackage,
  watchUpdatePackage,
  watchPackagesOnChange,
  watchDeletePackage,
};
