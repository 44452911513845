import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchInvoices,
  fetchOneInvoice,
  addInvoice,
  updateInvoice,
  deleteInvoice,
} from 'actions/invoices';
import {
  fetchAllInvoicesApi,
  fetchOneInvoiceApi,
  addInvoiceApi,
  updateInvoiceApi,
  deleteInvoiceApi,
} from 'services/invoices';
import fetchEntity from './fetch-entity';

const fetchInvoicesData = fetchEntity.bind(null, fetchInvoices.actions, fetchAllInvoicesApi);

const fetchInvoiceData = fetchEntity.bind(null, fetchOneInvoice.actions, fetchOneInvoiceApi);

const fetchAddInvoice = fetchEntity.bind(null, addInvoice.actions, addInvoiceApi);

const fetchUpdateInvoice = fetchEntity.bind(null, updateInvoice.actions, updateInvoiceApi);

const fetchDeleteInvoice = fetchEntity.bind(null, deleteInvoice.actions, deleteInvoiceApi);

export function* loadFetchInvoices({ params }) {
  yield call(fetchInvoicesData, { ...params });
}

export function* loadGetInvoice({ params }) {
  yield put(fetchOneInvoice.actions.failure({}, undefined));
  yield call(fetchInvoiceData, params);
}

export function* loadAddInvoice({ params }) {
  yield call(fetchAddInvoice, params);
}

export function* loadUpdateInvoice({ params }) {
  yield call(fetchUpdateInvoice, params);
}

export function* loadDeleteInvoice({ params }) {
  yield call(fetchDeleteInvoice, { ...params });
}

function* watchFetchInvoices() {
  yield takeLatest(fetchInvoices.actionName, loadFetchInvoices);
}

function* watchAddInvoice() {
  yield takeLatest(addInvoice.actionName, loadAddInvoice);
}

function* watchUpdateInvoice() {
  yield takeLatest(updateInvoice.actionName, loadUpdateInvoice);
}

function* watchDeleteInvoice() {
  yield takeLatest(deleteInvoice.actionName, loadDeleteInvoice);
}

function* watchGetInvoice() {
  yield takeLatest(fetchOneInvoice.actionName, loadGetInvoice);
}

export function* loadInvoicesOnChange() {
  const invoices = yield select(state => state.invoices);
  const { total, skip, limit } = invoices;
  if (skip && skip >= total) {
    yield call(fetchInvoicesData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchInvoicesData, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchInvoicesOnChange() {
  yield takeLatest([deleteInvoice.requestTypes.SUCCESS], loadInvoicesOnChange);
}

export default {
  watchFetchInvoices,
  watchAddInvoice,
  watchGetInvoice,
  watchUpdateInvoice,
  watchInvoicesOnChange,
  watchDeleteInvoice,
};
